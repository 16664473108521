import React, { useState, useRef, useEffect } from "react";
import { Button, Card, DropdownDivider, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormSelectV2 } from "../../includes/FormCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faFile, faImage, faTrash, faVideo } from "@fortawesome/pro-light-svg-icons";

const ButtonPhoneNumber = ({ label = "", phone_number = "", back = 0, onChangeLabel = () => null, onChangePhoneNumber = () => null }) => {
  const buttonRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const style = {
    border: "none",
    outline: "none",
    whiteSpace: "pre-line",
    padding: "20px",
    minHeight: 50,
  };

  useEffect(() => {
    if (buttonRef.current && Number(back) > 0) {
      buttonRef.current.textContent = label;
    }

    if (phoneNumberRef.current && Number(back) > 0) {
      phoneNumberRef.current.textContent = phone_number;
    }
  }, [back, buttonRef.current, phoneNumberRef.current]);

  const handleKeyDownLabel = (e) => {
    const formContent = buttonRef.current.textContent;
    if ((formContent.length >= 25 && e.key !== "Backspace" && e.key !== "Delete") || (formContent.length === 0 && e.key === " ")) {
      e.preventDefault();
    }
  };

  const handleInputLabel = (e) => {
    const currentContent = e.currentTarget.innerHTML.trim();
    const formContent = e.currentTarget.textContent;
    if (currentContent === "<br>") e.currentTarget.innerHTML = "";
    onChangeLabel(formContent);
  };

  const handleKeyDownPhoneNumber = (e) => {
    const formContent = phoneNumberRef.current.textContent;
    if (formContent.length === 0 && e.key === " ") {
      e.preventDefault();
    }
  };

  const handleInputPhoneNumber = (e) => {
    const currentContent = e.currentTarget.innerHTML.trim();
    const formContent = e.currentTarget.textContent;
    if (currentContent === "<br>") e.currentTarget.innerHTML = "";
    onChangePhoneNumber(formContent);
  };

  return (
    <Card.Body className="p-0">
      <div className="position-relative w-100" style={{ borderBottom: "1px solid #ddd" }}>
        <div
          ref={buttonRef}
          contentEditable="true"
          className="editable-div button-block w-100"
          style={{ ...style, marginBottom: 10 }}
          onKeyDown={handleKeyDownLabel}
          onInput={handleInputLabel}
        />
        <div className="op-primary-color text-light" style={{ position: "absolute", bottom: 0, right: 0, padding: "2px 10px", fontSize: 10 }}>
          {label.length} / 25 characters
        </div>
      </div>

      <div
        ref={phoneNumberRef}
        contentEditable="true"
        className="editable-div button-phone-number w-100"
        style={style}
        onKeyDown={handleKeyDownPhoneNumber}
        onInput={handleInputPhoneNumber}
      />
    </Card.Body>
  );
};

const ButtonQuickReply = ({ label = "", back = 0, onChangeLabel = () => null }) => {
  const buttonRef = useRef(null);
  const style = {
    border: "none",
    outline: "none",
    whiteSpace: "pre-line",
    padding: "20px",
    minHeight: 50,
  };

  useEffect(() => {
    if (buttonRef.current && Number(back) > 0) {
      buttonRef.current.textContent = label;
    }
  }, [back, buttonRef.current]);

  const handleKeyDownLabel = (e) => {
    const formContent = buttonRef.current.textContent;
    if ((formContent.length >= 25 && e.key !== "Backspace" && e.key !== "Delete") || (formContent.length === 0 && e.key === " ")) {
      e.preventDefault();
    }
  };

  const handleInputLabel = (e) => {
    const currentContent = e.currentTarget.innerHTML.trim();
    const formContent = e.currentTarget.textContent;
    if (currentContent === "<br>") e.currentTarget.innerHTML = "";
    onChangeLabel(formContent);
  };

  return (
    <Card.Body className="p-0 position-relative w-100">
      <div
        ref={buttonRef}
        contentEditable="true"
        className="editable-div button-block w-100"
        style={{ ...style, marginBottom: 10 }}
        onKeyDown={handleKeyDownLabel}
        onInput={handleInputLabel}
      />
      <div className="op-primary-color text-light" style={{ position: "absolute", bottom: 0, right: 0, padding: "2px 10px", fontSize: 10 }}>
        {label.length} / 25 characters
      </div>
    </Card.Body>
  );
};

const ButtonURL = ({
  label = "",
  url = "",
  back = 0,
  params = false,
  onChangeParams = () => null,
  onChangeLabel = () => null,
  onChangeURL = () => null,
}) => {
  const buttonRef = useRef(null);
  const urlRef = useRef(null);
  const [error, setError] = useState(false);
  const style = {
    border: "none",
    outline: "none",
    whiteSpace: "pre-line",
    padding: "20px",
    minHeight: 50,
  };

  useEffect(() => {
    if (buttonRef.current && Number(back) > 0) {
      buttonRef.current.textContent = label;
    }

    if (urlRef.current && Number(back) > 0) {
      urlRef.current.textContent = url;
    }
  }, [back, buttonRef.current, urlRef.current]);

  const handleKeyDownLabel = (e) => {
    const formContent = buttonRef.current.textContent;
    if ((formContent.length >= 25 && e.key !== "Backspace" && e.key !== "Delete") || (formContent.length === 0 && e.key === " ")) {
      e.preventDefault();
    }
  };

  const handleInputLabel = (e) => {
    const currentContent = e.currentTarget.innerHTML.trim();
    if (currentContent === "<br>") e.currentTarget.innerHTML = "";
    const formContent = e.currentTarget.textContent;
    onChangeLabel(formContent);
  };

  const handleKeyDownURL = (e) => {
    if (e.key === "{" || e.key === "}" || e.key === "Enter" || e.key === " ") return e.preventDefault();
  };

  const handleInputURL = (e) => {
    const currentContent = e.currentTarget.innerHTML.trim();
    const formContent = e.currentTarget.textContent;
    const variableCount = (formContent.match(/{{\d+}}/g) || []).length;
    const pattern = /^https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;
    if (currentContent === "<br>") e.currentTarget.innerHTML = "";
    setError(pattern.test(formContent) ? false : true);
    onChangeParams(variableCount.length > 0);
    onChangeURL(formContent);
  };

  const handleVariable = () => {
    const newVariable = `{{${1}}}`;
    urlRef.current.textContent += newVariable;
    onChangeURL(urlRef.current.textContent);
    onChangeParams(true);
  };

  return (
    <Card.Body className="p-0 position-relative w-100">
      <div className="position-relative w-100" style={{ borderBottom: "1px solid #ddd" }}>
        <div
          ref={buttonRef}
          contentEditable="true"
          className="editable-div button-block w-100"
          style={{ ...style, marginBottom: 10 }}
          onKeyDown={handleKeyDownLabel}
          onInput={handleInputLabel}
        />
        <div className="op-primary-color text-light" style={{ position: "absolute", bottom: 0, right: 0, padding: "2px 10px", fontSize: 10 }}>
          {label.length} / 25 characters
        </div>
      </div>

      <div className="w-100">
        <div className="d-flex align-items-center w-100" style={{ marginBottom: 20 }}>
          <div
            ref={urlRef}
            contentEditable="true"
            className="editable-div button-url w-100"
            style={style}
            onKeyDown={handleKeyDownURL}
            onInput={handleInputURL}
          />
          <Button
            variant="light"
            disabled={url.length === 0 || error || params}
            className="border shadow-sm me-2"
            style={{ width: 130 }}
            onClick={handleVariable}
          >
            Add Variable
          </Button>
        </div>
        <div className="d-flex align-items-center" style={{ position: "absolute", bottom: 0, right: 0, fontSize: 10 }}>
          {error && (
            <div className="bg-danger text-light me-1" style={{ padding: "2px 10px" }}>
              Please enter a valid URL.
            </div>
          )}
          <div className="op-primary-color text-light" style={{ padding: "2px 10px" }}>
            {params ? 1 : 0} / 1 variables (must be at the end of text)
          </div>
        </div>
      </div>
    </Card.Body>
  );
};

const ButtonCopyCode = () => {
  const style = {
    border: "none",
    outline: "none",
    whiteSpace: "pre-line",
    padding: "20px",
    minHeight: 50,
  };

  return (
    <Card.Body className="p-0">
      <div className="w-100" style={style}>
        Copy Code{" "}
        <OverlayTrigger
          className="cursor-pointer"
          placement="right"
          overlay={
            <Tooltip id="tooltip-top">
              The button label is not editable. It is provided by Meta and will be automatically translated into the template’s language.
            </Tooltip>
          }
        >
          <FontAwesomeIcon icon={faCircleQuestion} size="lg" className="ms-2 mt-1 cursor-pointer" />
        </OverlayTrigger>
      </div>
    </Card.Body>
  );
};

const ButtonsBlock = ({ buttons = [], back = 0, onChangeButtons = () => null }) => {
  const [buttonType] = useState([
    { label: "Phone Number", value: "phone_number" },
    { label: "Quick Reply", value: "quick_reply" },
    { label: "URL", value: "url" },
    // { label: "Copy Code", value: "copy_code" },
  ]);

  const handleAddButton = () => {
    if (buttons.length >= 10) return;
    const newButtons = [...buttons, { type: "phone_number", label: "", phone_number: "", url: "", params: false, params_value: "" }];
    onChangeButtons(newButtons);
  };

  const handleChangeButtonType = (record, index) => {
    const newButtons = [...buttons];
    newButtons[index].type = record.value;
    newButtons[index].label = "";
    newButtons[index].phone_number = "";
    newButtons[index].url = "";
    newButtons[index].params = false;
    onChangeButtons(newButtons);
  };

  const handleDeleteButton = (index) => {
    const newButtons = [...buttons];
    newButtons.splice(index, 1);
    onChangeButtons(newButtons);
  };

  const handleChangeLabel = (value, index) => {
    const newButtons = [...buttons];
    newButtons[index].label = value;
    onChangeButtons(newButtons);
  };

  const handleChangePhoneNumber = (value, index) => {
    const newButtons = [...buttons];
    newButtons[index].phone_number = value;
    onChangeButtons(newButtons);
  };

  const handleChangeURL = (value, index) => {
    const newButtons = [...buttons];
    newButtons[index].url = value;
    onChangeButtons(newButtons);
  };

  const handleChangeParams = (value, index) => {
    const newButtons = [...buttons];
    newButtons[index].params = value;
    onChangeButtons(newButtons);
  };

  return (
    <Card>
      <Card.Header className="d-flex align-items-center">
        <div className="op-primary-color text-light rounded me-3" style={{ padding: "6px 10px" }}>
          BUTTONS
        </div>
        <Button variant="light" className="border shadow-sm" onClick={handleAddButton}>
          Add Button
        </Button>
      </Card.Header>
      <Card.Body>
        {buttons.length > 0 ? (
          buttons.map((record, index) => (
            <Card key={index} className="w-100 mb-3">
              <Card.Header className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="op-primary-color text-light rounded me-3" style={{ padding: "6px 10px" }}>
                    BUTTON
                  </div>
                  <FormSelectV2
                    value={buttonType.find((item) => item.value === record.type)}
                    options={buttonType}
                    onChange={(e) => handleChangeButtonType(e, index)}
                    width="200px"
                  />
                </div>
                <Button variant="light" className="border shadow-sm" onClick={() => handleDeleteButton(index)}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Card.Header>
              {record.type === "phone_number" ? (
                <ButtonPhoneNumber
                  back={back}
                  label={record.label}
                  phone_number={record.phone_number}
                  onChangeLabel={(value) => handleChangeLabel(value, index)}
                  onChangePhoneNumber={(value) => handleChangePhoneNumber(value, index)}
                />
              ) : record.type === "quick_reply" ? (
                <ButtonQuickReply back={back} label={record.label} onChangeLabel={(value) => handleChangeLabel(value, index)} />
              ) : record.type === "url" ? (
                <ButtonURL
                  back={back}
                  label={record.label}
                  url={record.url}
                  params={record.params}
                  onChangeParams={(value) => handleChangeParams(value, index)}
                  onChangeLabel={(value) => handleChangeLabel(value, index)}
                  onChangeURL={(value) => handleChangeURL(value, index)}
                />
              ) : record.type === "copy_code" ? (
                <ButtonCopyCode />
              ) : null}
            </Card>
          ))
        ) : (
          <div style={{ height: 30 }} />
        )}

        <div className="op-primary-color text-light" style={{ position: "absolute", bottom: 0, right: 0, padding: "2px 10px", fontSize: 10 }}>
          {buttons.length} / 10 buttons
        </div>
      </Card.Body>
    </Card>
  );
};

export default ButtonsBlock;
