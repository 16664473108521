import { useState, useMemo } from "react";
import { InputGroup, Form, Container, ListGroup, Card, Row, Col, Spinner, Button, Stack } from "react-bootstrap";
import { faImage, faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { FormSelectV2 } from "../../includes/FormCustom";
import { FilePond, registerPlugin } from "react-filepond";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { faFilePdf } from "@fortawesome/pro-duotone-svg-icons";
import moment from "moment";
import axios from "../../api/axios";
import WSBackground from "../../assets/ws_background.png";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import FilePondPluginMediaPreview from "filepond-plugin-media-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import "filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css";
import "../../lib/scss/op-step.scss";
import { faArrowUpRightFromSquare, faBars, faPhoneArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { faImage as faImageSolid, faFile as faFileSolid, faVideo as faVideoSolid } from "@fortawesome/pro-solid-svg-icons";
import { useAuth } from "../../auth/AuthContext";

const ChooseTemplate = ({
  show,
  whatsapps,
  whatsapp,
  templateList = [],
  templateData = { label: "", value: "" },
  headerTypeValue,
  headerFilesValue,
  onChangeWhatsapp = () => null,
  onNext = () => null,
  onPrevious = () => null,
}) => {
  registerPlugin(FilePondPluginImagePreview);
  registerPlugin(FilePondPluginPdfPreview);
  registerPlugin(FilePondPluginFileValidateType);
  registerPlugin(FilePondPluginMediaPreview);
  const { session } = useAuth();
  const [search, setSearch] = useState("");
  const [templates, setTemplates] = useState(templateList);
  const [template, setTemplate] = useState(templateData);
  const [loading, setLoading] = useState(false);
  const [headerType, setHeaderType] = useState(headerTypeValue);
  const [headerFiles, setHeaderFiles] = useState(headerFilesValue);
  const [headerTypeImage] = useState([
    { value: "upload", label: "Upload an image" },
    { value: "link", label: "Insert a Link" },
  ]);

  const [headerTypeVideo] = useState([
    { value: "upload", label: "Upload a video" },
    { value: "link", label: "Insert a Link" },
  ]);

  const [headerTypeDocument] = useState([
    { value: "upload", label: "Upload a document" },
    { value: "link", label: "Insert a Link" },
  ]);

  // Get function --------------------------------------------

  const getTemplates = async (chatInstanceId) => {
    setTemplate({ label: "", value: "" });
    if (chatInstanceId === "") {
      setLoading(false);
      setTemplates([]);
      return;
    }

    try {
      const response = await axios.get("ws/ws_waba_interactive.php", {
        params: {
          task: "GetWsCampaignTemplateListV2",
          chat_instance_id: chatInstanceId,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        const dataList = data.record
          .filter((record) => record.new_waba_status === "approved")
          .map((record) => {
            const body_record = setDecodeJson(record.whatsapp_body_component);
            const header_record = setDecodeJson(record.whatsapp_header_component);
            const footer_record = setDecodeJson(record.whatsapp_footer_component);
            const buttons_record = setDecodeJson(record.whatsapp_buttons_component);

            body_record.text_preview = body_record.text;
            body_record.params_value = Array.from({ length: Number(body_record.params) }, () => "");

            if (record.whatsapp_header_block === 1) {
              header_record.params_value = "";
              header_record.text_preview = "";
            }

            if (record.whatsapp_buttons_block === 1) {
              buttons_record.buttons = buttons_record.buttons.map((button, index) => ({
                ...button,
                index: index,
                params_value: "",
                url_preview: button.type === "URL" ? button.url : "",
              }));
            }

            return {
              label: record.whatsapp_template_name,
              value: record.whatsapp_template_op_id,
              header: record.whatsapp_header_block,
              header_record: header_record,
              body_record: body_record,
              footer: record.whatsapp_footer_block,
              footer_record: footer_record,
              buttons: record.whatsapp_buttons_block,
              buttons_record: buttons_record,
            };
          });

        setTemplates(dataList);
      } else {
        setTemplates([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // onchange function ----------------------------------------

  const onchangeWhatsapp = (e) => {
    setLoading(true);
    onChangeWhatsapp(e);
    getTemplates(e.value);
  };

  // Set function ---------------------------------------------

  const setFormatMessages = (input) => {
    const regBold = /\*(.*?)\*/g;
    const regItalic = /_(.*?)_/g;
    const regStrikethrough = /~(.*?)~/g;
    const regCode = /```(.*?)```/g;
    // const regBreakLine = /\n/g;

    input = input.replace(/</g, "&lt;").replace(/>/g, "&gt;");

    let formattedText = input
      .replace(regBold, "<strong>$1</strong>")
      .replace(regItalic, "<em>$1</em>")
      .replace(regStrikethrough, "<s>$1</s>")
      .replace(regCode, "<code>$1</code>");
    return formattedText;
  };

  const setDecodeJson = (input) => {
    let result = null;
    try {
      result = JSON.parse(input);
    } catch (error) {
      result = { type: "", text: "", format: "", buttons: [], params: 0, example: null };
    }

    return result;
  };

  const setVariableVisibility = (values) => {
    if (Number(values.header) === 1) {
      const header_record = values.header_record;
      if (["image", "video", "document"].includes(header_record.format.toLowerCase())) return true;
      if (header_record.format.toLowerCase() === "text" && header_record.params > 0) return true;
    }

    const body_record = values.body_record;
    if (body_record.params > 0) return true;

    if (Number(values.buttons) === 1) {
      const buttons_record = values.buttons_record;
      if (buttons_record.buttons.length > 0) {
        buttons_record.buttons.forEach((button) => {
          if (button.params > 0) return true;
        });
      }
    }

    return false;
  };

  const setVariableButtonVisibility = (values) => {
    const buttons_record = values.buttons_record;
    if (values.buttons === 1) {
      const buttons = buttons_record.buttons.filter((button) => button.type.toLowerCase() === "url" && button.params === true);
      if (buttons.length > 0) return true;
    }

    return false;
  };

  const setVariableHeaderVisibility = (values) => {
    const header_record = values.header_record;
    if (["image", "video", "document"].includes(header_record.format.toLowerCase())) return true;
    if (header_record.format.toLowerCase() === "text" && header_record.params > 0) return true;
    return false;
  };

  const setButtonLabel = (length, button, index) => {
    if (length > 3 && index > 1) {
      return (
        <>
          <FontAwesomeIcon icon={faBars} size="lg" className="me-2" color="#007bff" />{" "}
          <b style={{ color: "#007bff", fontSize: 13 }}>See all options</b>
        </>
      );
    }

    const buttonIcons = {
      phone_number: <FontAwesomeIcon icon={faPhoneArrowRight} size="lg" className="me-2" color="#007bff" />,
      url: <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="lg" className="me-2" color="#007bff" />,
      quick_reply: <b style={{ color: "#007bff", fontSize: 13 }}>{button.text}</b>,
    };

    return buttonIcons[button.type.toLowerCase()] ? (
      <div className="d-flex align-items-center">
        {buttonIcons[button.type.toLowerCase()]}{" "}
        {button.type.toLowerCase() !== "quick_reply" ? <b style={{ color: "#007bff", fontSize: 13 }}>{button.text}</b> : null}
      </div>
    ) : null;
  };

  const setButtonWidth = (values, index) => {
    if (values.buttons.length === 1) {
      return 12;
    } else if (values.buttons.length === 2) {
      return 12;
    } else if (values.buttons.length > 2) {
      return [0, 1].includes(index) ? 6 : 12;
    }
    return 12;
  };

  const setAcceptedFileTypes = (format) => {
    const acceptedFileTypes = {
      image: ["image/*"],
      video: ["video/mp4"],
      document: ["application/pdf"],
    };
    return acceptedFileTypes[format] || [];
  };

  const setFileSize = (size) => {
    const fileSizeInKB = size / 1024;
    const fileSizeInMB = size / (1024 * 1024);
    return fileSizeInKB < 1024 ? `${fileSizeInKB.toFixed(2)} KB` : `${fileSizeInMB.toFixed(2)} MB`;
  };

  // Usememo --------------------------------------------------

  const template_options = useMemo(() => templates.filter((item) => item.label.toLowerCase().includes(search.toLowerCase())), [search, templates]);

  // Styles ---------------------------------------------------

  const styles = useMemo(
    () => ({
      sidebar: {
        width: 350,
      },
      sidebar_header: {
        height: 50,
        borderBottom: "1px solid #ddd",
      },
      content: {
        backgroundColor: "#eeeeee",
        width: "calc(100% - 350px)",
      },
      form_control_icon: {
        backgroundColor: "#fff",
        paddingRight: 2,
        border: "none",
      },
      form_control: {
        borderLeft: "none",
        outline: "none",
        paddingRight: 0,
        boxShadow: "none",
        border: "none",
      },
      list_divider: {
        margin: "0 15px",
        borderTop: ".5px solid #eee",
      },
    }),
    []
  );

  if (!show) return null;

  return (
    <Container fluid className="d-flex p-0" style={{ height: "calc(100vh - 190px)" }}>
      <section className="border-end" style={styles.sidebar}>
        <div className="px-2 d-flex align-items-center" style={{ ...styles.sidebar_header, backgroundColor: "#f5f5f5" }}>
          <Form.Group className="w-100">
            <FormSelectV2
              placeholder="Choose Whatsapp Number"
              options={whatsapps}
              value={whatsapp.value ? whatsapp : null}
              onChange={onchangeWhatsapp}
              isClearable
              isSearchable
              shadow={false}
              border={false}
              background="#f5f5f5"
            />
          </Form.Group>
        </div>

        <div
          className="px-2 d-flex align-items-center justify-content-center"
          style={{
            ...styles.sidebar_header,
            backgroundColor: !whatsapp.value ? "#eee" : "#f5f5f5",
            pointerEvents: !whatsapp.value ? "none" : "auto",
          }}
        >
          <div className="w-100">
            <InputGroup>
              <InputGroup.Text style={{ ...styles.form_control_icon, backgroundColor: !whatsapp.value ? "#eee" : "#f5f5f5" }}>
                <FontAwesomeIcon icon={faMagnifyingGlass} rotation={90} />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search template"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ ...styles.form_control, backgroundColor: !whatsapp.value ? "#eee" : "#f5f5f5" }}
              />
            </InputGroup>
          </div>
        </div>

        {loading ? (
          <div className="d-flex justify-content-center w-100 mt-3" style={{ zIndex: 2 }}>
            <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        ) : whatsapp.value === "" ? (
          <div className="text-center mt-3">Please choose Whatsapp number first</div>
        ) : (
          <div className="op-scroll w-100 overflow-auto" style={{ height: "calc(100vh - 240px)" }}>
            {template_options.length > 0 ? (
              <ListGroup>
                {template_options.map((record, index) => (
                  <div key={index}>
                    <ListGroup.Item
                      action
                      active={template.value === record.value}
                      className="cursor-pointer rounded-0 d-flex align-items-center justify-content-between w-100"
                      style={{ padding: "15px 20px", border: "none" }}
                      onClick={() => {
                        if (record.value === template.value) return;
                        setHeaderFiles([]);
                        setHeaderType("upload");

                        const record_template = record;
                        record_template.body_record.text_preview = record_template.body_record.text;
                        record_template.body_record.params_value = Array.from({ length: Number(record_template.body_record.params) }, () => "");

                        if (record_template.header === 1) {
                          record_template.header_record.params_value = "";
                          record_template.header_record.text_preview = "";
                        }

                        if (record_template.buttons === 1) {
                          record_template.buttons_record.buttons = record_template.buttons_record.buttons.map((button, index) => ({
                            ...button,
                            index: index,
                            params_value: "",
                            url_preview: button.type === "URL" ? button.url : "",
                          }));
                        }

                        setTemplate(record_template);
                      }}
                    >
                      <span style={{ wordWrap: "break-word", width: 280 }}>{record.label}</span>
                      <FontAwesomeIcon icon={faChevronRight} color="#999" size="sm" />
                    </ListGroup.Item>
                    <div style={styles.list_divider}></div>
                  </div>
                ))}
              </ListGroup>
            ) : (
              <div className="text-center mt-3">No template found</div>
            )}
          </div>
        )}
      </section>
      <section className="h-100 p-3 overflow-y-auto" style={{ ...styles.content, height: "calc(100vh - 180px)" }}>
        {template?.value ? (
          <Row>
            <Col sm={7}>
              <Card className="shadow-sm h-100">
                <Card.Header>
                  <Card.Title as="h6" className="my-2" style={{ fontSize: 14 }}>
                    Possible Values
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {!setVariableVisibility(template) && (
                    <div className="h-100 mt-5 text-center">This template haven't used any variable placeholders or image header in your text</div>
                  )}

                  {Number(template.header) === 1 && setVariableHeaderVisibility(template) && (
                    <Card className="mb-3">
                      <Card.Header className="d-flex">
                        <Button variant="" className="op-primary-color text-light me-2" style={{ pointerEvents: "none" }}>
                          HEADER
                        </Button>

                        {template.header_record.format.toLowerCase() === "image" ? (
                          <FormSelectV2
                            value={headerTypeImage.find((record) => record.value === headerType)}
                            width="200px"
                            onChange={(e) => setHeaderType(e.value)}
                            options={headerTypeImage}
                          />
                        ) : template.header_record.format.toLowerCase() === "video" ? (
                          <FormSelectV2
                            value={headerTypeVideo.find((record) => record.value === headerType)}
                            width="200px"
                            onChange={(e) => setHeaderType(e.value)}
                            options={headerTypeVideo}
                          />
                        ) : template.header_record.format.toLowerCase() === "document" ? (
                          <FormSelectV2
                            value={headerTypeDocument.find((record) => record.value === headerType)}
                            width="200px"
                            onChange={(e) => setHeaderType(e.value)}
                            options={headerTypeDocument}
                          />
                        ) : null}
                      </Card.Header>
                      <Card.Body className="p-0 whastapp-campaign">
                        {["image", "video", "document"].includes(template.header_record.format.toLowerCase()) ? (
                          <>
                            {headerType === "upload" ? (
                              <FilePond
                                className="op-filepond-light pt-2"
                                allowMultiple={false}
                                allowVideoPreview
                                allowImagePreview
                                allowPdfPreview
                                acceptedFileTypes={setAcceptedFileTypes(template.header_record.format.toLowerCase())}
                                files={headerFiles}
                                maxFiles={1}
                                credits={false}
                                instantUpload={false}
                                onremovefile={() => {
                                  const current_template = { ...template };
                                  current_template.header_record.params_value = "";
                                  setTemplate(current_template);
                                  setHeaderFiles([]);
                                }}
                                onupdatefiles={(fileItems) => {
                                  setHeaderFiles(fileItems.map((fileItem) => fileItem.file));
                                }}
                                server={{
                                  process: (fieldName, file, metadata, load, error, progress, abort) => {
                                    const formData = new FormData();
                                    const reader = new FileReader();

                                    reader.onload = (e) => {
                                      formData.append("fileToUpload", e.target.result);
                                      formData.append("utoken", session.user_ac_token);
                                      formData.append("ctoken", session.company_token);
                                      formData.append("task", "getMediaURL");
                                      formData.append("mime_type", file.type);
                                      formData.append("file_name", file.name.split(".")[0]);
                                      axios.post("ws/ws_conversation.php", formData).then((response) => {
                                        const data = response.data;
                                        if (Number(data.status) === 0) {
                                          data.media_url = data.media_url.replaceAll("cloud_dev", session.hostUrlType);
                                          const current_template = { ...template };
                                          current_template.header_record.params_value = data.media_url;
                                          setTemplate(current_template);
                                          load(data);
                                        }
                                      });
                                    };
                                    reader.readAsDataURL(file);
                                  },
                                }}
                              />
                            ) : (
                              <div className="py-2">
                                <Form.Control
                                  size="lg"
                                  type="text"
                                  value={template.header_record.params_value}
                                  placeholder={`State your header link here`}
                                  style={styles.form_control}
                                  onChange={(e) => {
                                    const current_template = { ...template };
                                    current_template.header_record.params_value = e.target.value;
                                    setTemplate(current_template);
                                  }}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="py-2">
                            <Form.Control
                              size="lg"
                              type="text"
                              value={template.header_record.params_value}
                              placeholder={`State your header variable value here. Exp: ${template.header_record.example.header_text[0]}`}
                              style={styles.form_control}
                              onChange={(e) => {
                                const current_template = { ...template };
                                current_template.params_value = e.target.value;
                                current_template.text_preview = current_template.text.replace(/{{\d+}}/, e.target.value);
                                setTemplate(current_template);
                              }}
                            />
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  )}

                  {template.body_record.params > 0 && (
                    <Card className="mb-3">
                      <Card.Header className="d-flex align-items-center justify-content-between">
                        <Button variant="" className="op-primary-color text-light me-2" style={{ pointerEvents: "none" }}>
                          BODY
                        </Button>
                        <span>{template.body_record.params} variables</span>
                      </Card.Header>
                      <Card.Body>
                        {template.body_record.params_value.map((record, index) => (
                          <Card key={index} className="mb-2">
                            <Card.Body className="p-0">
                              <div className="d-flex align-items-center justify-content-center whatsapp-campaign" style={{ height: 50 }}>
                                <Form.Control
                                  size="lg"
                                  value={record}
                                  style={styles.form_control}
                                  placeholder={`State your variable {{${index + 1}}} value here. Exp: ${
                                    template.body_record.example.body_text[0][index]
                                  }`}
                                  onChange={(e) => {
                                    const updatedTemplate = { ...template };
                                    updatedTemplate.body_record.params_value[index] = e.target.value;
                                    updatedTemplate.body_record.text_preview = updatedTemplate.body_record.params_value
                                      .map((value, idx) => (value ? value : `{{${idx + 1}}}`))
                                      .reduce((text, value, idx) => text.replace(`{{${idx + 1}}}`, value), updatedTemplate.body_record.text);

                                    setTemplate(updatedTemplate);
                                  }}
                                />
                              </div>
                            </Card.Body>
                          </Card>
                        ))}
                      </Card.Body>
                    </Card>
                  )}

                  {Number(template.buttons) === 1 && setVariableButtonVisibility(template) && (
                    <Card>
                      <Card.Header>
                        <Button variant="" className="op-primary-color text-light me-2" style={{ pointerEvents: "none" }}>
                          BUTTONS
                        </Button>
                      </Card.Header>
                      <Card.Body>
                        {template.buttons_record.buttons
                          .filter((button) => button.type.toLowerCase() === "url" && button.params === true)
                          .map((button, index) => (
                            <Card key={index} className="mb-2">
                              <Card.Body className="p-0">
                                <div className="d-flex align-items-center justify-content-center whatsapp-campaign" style={{ height: 50 }}>
                                  <Form.Control
                                    size="lg"
                                    className="border-0 rounded-0"
                                    value={button.params_value}
                                    placeholder={`State your URL button variable value here. Exp: ${button.example[0]}`}
                                    style={styles.form_control}
                                    onChange={(e) => {
                                      const current_template = { ...template };
                                      current_template.buttons_record.buttons[button.index].params_value = e.target.value;
                                      current_template.buttons_record.buttons[button.index].url_preview = current_template.buttons_record.buttons[
                                        button.index
                                      ].url.replace(/{{\d+}}/, e.target.value);
                                      setTemplate(current_template);
                                    }}
                                  />
                                </div>
                              </Card.Body>
                            </Card>
                          ))}
                      </Card.Body>
                    </Card>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col sm={5}>
              <Card className="shadow-sm h-100">
                <Card.Body className="w-100 px-4 py-4">
                  <div
                    className="rounded position-relative p-2 h-100"
                    style={{ background: `url(${WSBackground}) left top repeat rgb(236, 229, 221)`, minHeight: 400 }}
                  >
                    <div className="ws-bubble">
                      {template.header === 1 && (
                        <div className="w-100">
                          {["image", "document", "video"].includes(template.header_record.format.toLowerCase()) ? (
                            <div className="w-100">
                              {template.header_record.params_value ? (
                                <div className="w-100">
                                  {template.header_record.format.toLowerCase() === "image" ? (
                                    <img src={template.header_record.params_value} alt="header" className="w-100" />
                                  ) : template.header_record.format.toLowerCase() === "document" ? (
                                    <div className="my-2">
                                      <Card className="w-100" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                                        <Card.Body className="p-0">
                                          <div className="w-100" style={{ height: 150, overflowY: "clip" }}>
                                            <iframe
                                              className="w-100"
                                              style={{ height: 9999 }}
                                              src={template.header_record.params_value + "#toolbar=0&navpanes=0"}
                                            ></iframe>
                                          </div>
                                        </Card.Body>
                                        <Card.Header className="d-flex" style={{ backgroundColor: "#fff" }}>
                                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30 }}>
                                            <FontAwesomeIcon icon={faFilePdf} size="xl" />
                                          </div>
                                          <div>
                                            <div>{template.header_record.params_value.split("/").pop()}</div>
                                            <div className="text-muted" style={{ fontSize: 10 }}>
                                              {setFileSize(headerFiles[0].size)}, Chrome PDF Document
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <Card.Body className="d-flex">
                                          <div className="rounded border p-2 w-50 me-2 text-center" style={{ backgroundColor: "#f5f5f5" }}>
                                            Open
                                          </div>
                                          <div className="rounded border p-2 w-50 text-center" style={{ backgroundColor: "#f5f5f5" }}>
                                            Save as...
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>
                                  ) : template.header_record.format.toLowerCase() === "video" ? (
                                    <video width="100%" controls>
                                      <source src={template.header_record.params_value} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                  ) : null}
                                </div>
                              ) : (
                                <div className="ws-bubble-header">
                                  <FontAwesomeIcon
                                    icon={
                                      template.header_record.format.toLowerCase() === "image"
                                        ? faImageSolid
                                        : template.header_record.format.toLowerCase() === "video"
                                        ? faVideoSolid
                                        : faFileSolid
                                    }
                                    color="#fff"
                                    size="10x"
                                  />
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>
                      )}

                      <p
                        className="ws-bubble-message"
                        style={{ marginBottom: 0, fontSize: 12 }}
                        dangerouslySetInnerHTML={{ __html: setFormatMessages(template.body_record.text_preview) }}
                      />

                      {template.footer === 1 && (
                        <p
                          className="ws-bubble-message"
                          style={{ color: "rgba(153,153,153,1)", fontSize: 11, marginBottom: 0 }}
                          dangerouslySetInnerHTML={{ __html: setFormatMessages(template.footer_record.text) }}
                        />
                      )}
                      <div className="ws-bubble-time">{moment().format("HH:mm A")}</div>
                    </div>
                    {template.buttons === 1 && (
                      <Row className="g-2" style={{ marginLeft: 10, marginRight: 10, marginTop: 2 }}>
                        {template.buttons_record.buttons.map((button, index) => {
                          if (index > 2) return null;
                          return (
                            <Col key={index} sm={setButtonWidth(template.buttons_record, index)}>
                              <div
                                className="h-100 w-100 p-2 bg-light shadow-sm rounded cursor-pointer d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  if (button.type.toLowerCase() === "url") {
                                    window.open(button.url_preview, "_blank");
                                  }
                                }}
                              >
                                {setButtonLabel(template.buttons_record.buttons.length, button, index)}
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <div className="text-center w-100 h-100 mt-5">
            You haven't choose any Whatsapp template yet. Please select a Whatsapp template on the left side
          </div>
        )}

        <div className="offcanvas-footer" style={{ height: 60, zIndex: 100, left: 350, width: "calc(100% - 350px)" }}>
          <Stack direction="horizontal" gap={2}>
            <button className="btn btn-dark" onClick={() => onPrevious(templates, template, headerType, headerFiles)}>
              Previous
            </button>
            <button
              className="btn op-button op-primary-color text-light ms-auto"
              onClick={() => onNext(templates, template, headerType, headerFiles)}
            >
              Next
            </button>
          </Stack>
        </div>
      </section>
    </Container>
  );
};

export default ChooseTemplate;
