import { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { Alert, Button, Col, Container, Form, Modal, OverlayTrigger, Placeholder, Row, Spinner, Stack, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy, faPencil, faRotateRight, faTrash } from "@fortawesome/pro-light-svg-icons";
import { faArrowUpRightFromSquare, faBars, faPhoneArrowRight, faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { faImage as faImageSolid, faFile as faFileSolid, faVideo as faVideoSolid } from "@fortawesome/pro-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { FormSelect } from "../includes/FormCustom";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import * as formik from "formik";
import WSBackground from "../assets/ws_background.png";
import "../lib/scss/op-ws-campaign-settings.scss";
import moment from "moment";
import { immediateToast } from "izitoast-react";
import Swal from "sweetalert2/dist/sweetalert2";
import HeaderBlock from "./waba/HeaderBlock";
import BodyBlock from "./waba/BodyBlock";
import FooterBlock from "./waba/FooterBlock";
import ButtonsBlock from "./waba/ButtonsBlock";

const SettingsWsCampaignTemplate360 = () => {
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [gridRecord, setGridRecord] = useState([]);
  const [chatInstanceList, setChatInstanceList] = useState([]);
  const [chatInstanceData, setChatInstanceData] = useState({ label: "", value: "" });
  const [chatInstanceShow, setChatInstanceShow] = useState(false);
  const [isAddNewTemplate, setIsAddNewTemplate] = useState(false);
  const [isEditTemplate, setIsEditTemplate] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [templateCategory] = useState([
    { label: "Authentication", value: "authentication" },
    { label: "Marketing", value: "marketing" },
    { label: "Utility", value: "utility" },
  ]);

  const [templateTypeList] = useState([
    { label: "Standard (text only)", value: "text" },
    { label: "Media & Interactive", value: "media" },
  ]);

  const [formValues, setFormValues] = useState({
    template_name: "",
    template_type: "text",
    template_category: "marketing",
    message_content: "",
    message_preview: "",
    media: "",
    chat_instance_id: "",
    params: 0,
    character_count: 0,
    warning: false,
    warning_message: [],
    section: 1,
    params_example: [],
    params_header_example: "",
    header_block: false,
    header_block_type: "text",
    header_block_message: "",
    header_block_params: "",
    header_block_params_count: 0,
    footer_block: false,
    footer_block_message: "",
    button_block: false,
    button_block_arr: [],
    button_preview: [],
    header_preview: "",
    reset: 0,
    back: 0,
  });

  const [formValuesEdit, setFormValuesEdit] = useState({
    waba_id: "",
    template_name: "",
    template_type: "text",
    template_category: "marketing",
    message_content: "",
    message_preview: "",
    media: "",
    chat_instance_id: "",
    params: 0,
    character_count: 0,
    warning: false,
    warning_message: [],
    section: 1,
    params_example: [],
    params_header_example: "",
    header_block: false,
    header_block_type: "text",
    header_block_message: "",
    header_block_params: "",
    header_block_params_count: 0,
    footer_block: false,
    footer_block_message: "",
    button_block: false,
    button_block_arr: [],
    button_preview: [],
    header_preview: "",
    reset: 0,
    back: 0,
  });

  const [imgError, setImgError] = useState(false);

  // GET FUNCTION ==============================================================

  const getChatInstance = async () => {
    try {
      const response = await axios.get(`ws/ws_ai_campaign.php`, {
        params: {
          task: "getAudienceOptions",
          type: "chat_instance",
          company_id: session.company_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordData = data.record.map((item) => ({
          ...item,
          label: `${item.instance_title} - ${item.user_number}`,
          value: item.chat_instance_id,
        }));
        setChatInstanceList(recordData);
        setChatInstanceData(recordData[0]);
        await getWsCampaignTemplateList(data.record[0].chat_instance_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getWsCampaignTemplateList = async (chatInstanceId) => {
    try {
      const response = await axios.get(`ws/ws_waba_interactive.php`, {
        params: {
          task: "GetWsCampaignTemplateListV2",
          chat_instance_id: chatInstanceId,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      setGridRecord(data.status === 0 && data.record.length > 0 ? data.record : []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsAddNewTemplate(false);
      setChatInstanceShow(true);
      setLoading2(false);
      setLoadingBtn(false);
    }
  };

  // SEND FUNCTION =============================================================

  const sendBackToEditor = (values) => {
    values.section = 1;
    values.message_preview = "";
    values.header_preview = "";
    values.button_preview = [];
    values.params_example = Array.from({ length: values.params }, () => "");
    values.params_header_example = "";
    values.header_block_params = "";
    values.back = Math.random();
    values.reset = 0;
    setFormValues({ ...values });
  };

  const sendValidateNewTemplate = async (values) => {
    values.section = 2;
    values.message_preview = values.message_content;
    values.header_preview = values.header_block_message;
    values.button_preview = values.button_block_arr;
    values.back = 0;
    setFormValues({ ...values });
  };

  const sendBackToEditorEdit = (values) => {
    values.section = 1;
    values.message_preview = "";
    values.header_preview = "";
    values.button_preview = [];
    values.params_example = Array.from({ length: values.params }, () => "");
    values.params_header_example = "";
    values.header_block_params = "";
    values.back = Math.random();
    values.reset = 0;
    setFormValuesEdit({ ...values });
  };

  const sendValidateNewTemplateEdit = async (values) => {
    values.section = 2;
    values.message_preview = values.message_content;
    values.header_preview = values.header_block_message;
    values.button_preview = values.button_block_arr;
    values.back = 0;
    setFormValuesEdit({ ...values });
  };

  const sendAddWsCampaignTemplate = async (values) => {
    if (loadingBtn) return;
    setLoadingBtn(true);
    const components = [];
    const bodyBlock = {
      type: "BODY",
      text: values.message_content,
    };

    if (values.params > 0) {
      bodyBlock.example = {
        body_text: [values.params_example],
      };
    }

    components.push(bodyBlock);

    if (values.template_type === "media") {
      if (values.header_block) {
        const headerBlock = {
          type: "HEADER",
          format: values.header_block_type.toUpperCase(),
        };
        if (values.header_block_type === "text") {
          headerBlock.text = values.header_block_message;
          headerBlock.example = {
            header_text: [values.header_block_params],
          };
        } else {
          headerBlock.example = {
            header_handle: [values.params_header_example],
          };
        }
        components.push(headerBlock);
      }

      if (values.footer_block) {
        components.push({
          type: "FOOTER",
          text: values.footer_block_message,
        });
      }

      if (values.button_block) {
        const buttonsBlock = {
          type: "BUTTONS",
          buttons: [],
        };

        for (let i = 0; i < values.button_preview.length; i++) {
          const button = values.button_preview[i];
          if (button.type === "phone_number") {
            buttonsBlock.buttons.push({
              type: button.type.toUpperCase(),
              text: button.label,
              phone_number: button.phone_number,
            });
          } else if (button.type === "url") {
            if (button.params) {
              buttonsBlock.buttons.push({
                type: button.type.toUpperCase(),
                text: button.label,
                url: values.button_block_arr[i].url,
                example: [button.params_value],
              });
            } else {
              buttonsBlock.buttons.push({
                type: button.type.toUpperCase(),
                text: button.label,
                url: button.url,
              });
            }
          } else if (button.type === "quick_reply") {
            buttonsBlock.buttons.push({
              type: button.type.toUpperCase(),
              text: button.label,
            });
          } else {
            buttonsBlock.buttons.push({
              type: button.type.toUpperCase(),
              example: button.params_value,
            });
          }
        }

        components.push(buttonsBlock);
      }
    }

    try {
      const formData = new FormData();
      formData.append("task", "CreateWsCampaignTemplateV2");
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);
      formData.append("chat_instance_id", values.chat_instance_id);
      formData.append("name", values.template_name);
      formData.append("category", values.template_category);
      formData.append("type", values.template_type);
      formData.append("language", "en");
      formData.append("allow_category_change", "true");
      for (let i = 0; i < components.length; i++) {
        formData.append(`components[${i}]`, JSON.stringify(components[i]));
      }

      const response = await axios.post(`ws/ws_waba_interactive.php`, formData);
      const data = response.data;
      if (data.status === 0) {
        setChatInstanceData(chatInstanceList.find((record) => Number(record.value) === Number(values.chat_instance_id)));
        await getWsCampaignTemplateList(values.chat_instance_id);
        immediateToast("success", { title: "Success", message: "Template has been created", timer: 10000 });
      } else {
        immediateToast("warning", { title: "Unfortunately", message: data.error, timer: 10000 });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBtn(false);
      setIsAddNewTemplate(false);
      setFormValues({
        template_name: "",
        template_type: "text",
        template_category: "marketing",
        message_content: "",
        message_preview: "",
        media: "",
        chat_instance_id: "",
        params: 0,
        character_count: 0,
        warning: false,
        warning_message: [],
        section: 1,
        params_example: [],
        params_header_example: "",
        header_block: false,
        header_block_type: "text",
        header_block_message: "",
        header_block_params: "",
        header_block_params_count: 0,
        footer_block: false,
        footer_block_message: "",
        button_block: false,
        button_block_arr: [],
        button_preview: [],
        header_preview: "",
        reset: 0,
        back: 0,
      });
    }
  };

  const sendUpdateWsCampaignTemplate = async (values) => {
    if (loadingBtn) return;
    setLoadingBtn(true);
    const components = [];
    const bodyBlock = {
      type: "BODY",
      text: values.message_content,
    };

    if (values.params > 0) {
      bodyBlock.example = {
        body_text: [values.params_example],
      };
    }

    components.push(bodyBlock);

    if (values.template_type === "media") {
      if (values.header_block) {
        const headerBlock = {
          type: "HEADER",
          format: values.header_block_type.toUpperCase(),
        };
        if (values.header_block_type === "text") {
          headerBlock.text = values.header_block_message;
          headerBlock.example = {
            header_text: [values.header_block_params],
          };
        } else {
          headerBlock.example = {
            header_handle: [values.params_header_example],
          };
        }
        components.push(headerBlock);
      }

      if (values.footer_block) {
        components.push({
          type: "FOOTER",
          text: values.footer_block_message,
        });
      }

      if (values.button_block) {
        const buttonsBlock = {
          type: "BUTTONS",
          buttons: [],
        };

        for (let i = 0; i < values.button_preview.length; i++) {
          const button = values.button_preview[i];
          if (button.type === "phone_number") {
            buttonsBlock.buttons.push({
              type: button.type.toUpperCase(),
              text: button.label,
              phone_number: button.phone_number,
            });
          } else if (button.type === "url") {
            if (button.params) {
              buttonsBlock.buttons.push({
                type: button.type.toUpperCase(),
                text: button.label,
                url: values.button_block_arr[i].url,
                example: [button.params_value],
              });
            } else {
              buttonsBlock.buttons.push({
                type: button.type.toUpperCase(),
                text: button.label,
                url: button.url,
              });
            }
          } else if (button.type === "quick_reply") {
            buttonsBlock.buttons.push({
              type: button.type.toUpperCase(),
              text: button.label,
            });
          } else {
            buttonsBlock.buttons.push({
              type: button.type.toUpperCase(),
              example: button.params_value,
            });
          }
        }

        components.push(buttonsBlock);
      }
    }

    try {
      const formData = new FormData();
      formData.append("task", "CreateWsCampaignTemplateV2");
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);
      formData.append("chat_instance_id", values.chat_instance_id);
      formData.append("name", values.template_name);
      formData.append("category", values.template_category);
      formData.append("type", values.template_type);
      formData.append("language", "en_US");
      formData.append("allow_category_change", "true");
      for (let i = 0; i < components.length; i++) {
        formData.append(`components[${i}]`, JSON.stringify(components[i]));
      }

      const response = await axios.post(`ws/ws_waba_interactive.php`, formData);
      const data = response.data;
      if (data.status === 0) {
        setChatInstanceData(chatInstanceList.find((record) => Number(record.value) === Number(values.chat_instance_id)));
        await getWsCampaignTemplateList(values.chat_instance_id);
        immediateToast("success", { title: "Success", message: "Template has been updated", timer: 10000 });
      } else {
        immediateToast("warning", { title: "Unfortunately", message: data.error, timer: 10000 });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBtn(false);
      setIsEditTemplate(false);
      setFormValuesEdit({
        waba_id: "",
        template_name: "",
        template_type: "text",
        template_category: "marketing",
        message_content: "",
        message_preview: "",
        media: "",
        chat_instance_id: "",
        params: 0,
        character_count: 0,
        warning: false,
        warning_message: [],
        section: 1,
        params_example: [],
        params_header_example: "",
        header_block: false,
        header_block_type: "text",
        header_block_message: "",
        header_block_params: "",
        header_block_params_count: 0,
        footer_block: false,
        footer_block_message: "",
        button_block: false,
        button_block_arr: [],
        button_preview: [],
        header_preview: "",
        reset: 0,
        back: 0,
      });
    }
  };

  const sendDeleteWsCampaignTemplate = async (values) => {
    Swal.fire({
      html: `
      <div>
        <p style="text-align: left; font-size: 14px;">Are you sure you want to delete all language templates with the name <strong>${values.whatsapp_template_name}</strong>?</p>
        <p style="text-align: left; font-size: 14px;">Please note:</p>
        <ul>
            <li style="text-align: left; font-size: 13px; margin-bottom: 10px;">If that message template name exists in multiple languages, all languages will be deleted.</li>
            <li style="text-align: left; font-size: 13px; margin-bottom: 10px;">Messages that have been sent but not delivered will attempt to be delivered for 30 days.</li>
            <li style="text-align: left; font-size: 13px; margin-bottom: 10px;">If a message from an approved message template is sent 30 days after deletion, you will receive a "Structure Unavailable" error and the customer will not receive the message.</li>
            <li style="text-align: left; font-size: 13px; margin-bottom: 10px;">Once deleted, the name of an approved template cannot be used again for 30 days. Use a different name to create future templates.</li>
          </ul>
      </div>`,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#fff",
      cancelButtonTextColor: "#000",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete all",
      reverseButtons: true,
      buttonsStyling: true,
      allowOutsideClick: false,
      customClass: { cancelButton: "op-ws-campaign-settings-modal-cancel-button" },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const backupRecord = [...gridRecord];
        setGridRecord((prevState) => prevState.filter((record) => record.whatsapp_template_op_id !== values.whatsapp_template_op_id));
        try {
          const formData = new FormData();
          formData.append("task", "DeleteWsCampaignTemplate");
          formData.append("utoken", session.user_ac_token);
          formData.append("ctoken", session.company_token);
          formData.append("chat_instance_id", values.chat_instance_id);
          formData.append("whatsapp_template_op_id", values.whatsapp_template_op_id);
          formData.append("template_name", values.whatsapp_template_name);
          const response = await axios.post(`ws/ws_waba_interactive.php`, formData);
          const data = response.data;
          if (data.status === 0) {
            immediateToast("success", { title: "Success", message: "Template has been deleted", timer: 10000 });
          } else {
            setGridRecord(backupRecord);
            immediateToast("warning", { title: "Unfortunately", message: data.error, timer: 10000 });
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  // ONCHANGE FUNCTION ==========================================================

  const onchangeChatInstance = async (e) => {
    setLoading2(true);
    setChatInstanceData(e);
    await getWsCampaignTemplateList(e.value, false);
  };

  // OPEN TEMPLATE =========================================================

  const openEditTemplate = (values) => {
    setLoadingBtn(false);
    setImgError(false);
    setVideoError(false);
    const bodyBlock = setDecodeJson(values.whatsapp_body_component);
    let headerBlock = "";
    let footerBlock = "";
    let buttonsBlock = "";

    if (values.whatsapp_header_block === 1) {
      headerBlock = setDecodeJson(values.whatsapp_header_component);
    }

    if (values.whatsapp_footer_block === 1) {
      footerBlock = setDecodeJson(values.whatsapp_footer_component);
    }

    if (values.whatsapp_buttons_block === 1) {
      buttonsBlock = setDecodeJson(values.whatsapp_buttons_component);
      buttonsBlock.buttons = buttonsBlock.buttons.map((button) => ({
        type: button.type.toLowerCase(),
        label: button.text,
        phone_number: button.phone_number,
        url: button.url,
        params: button.params,
        params_value: "",
      }));
    }

    setFormValuesEdit({
      waba_id: values.waba_id,
      section: 1,
      template_name: values.whatsapp_template_name + "_" + moment().format("YYYYMMDDHHmmss"),
      template_type: values.whatsapp_template_type,
      template_category: values.whatsapp_template_category,
      chat_instance_id: values.chat_instance_id,
      message_content: bodyBlock.text,
      message_preview: "",
      params: bodyBlock.params,
      character_count: bodyBlock.text.length,
      params_example: bodyBlock.params > 0 ? Array.from({ length: bodyBlock.params }, () => "") : [],
      params_header_example: "",
      header_block: values.whatsapp_header_block === 1,
      header_block_type: headerBlock ? headerBlock.format.toLowerCase() : "text",
      header_block_message: headerBlock ? headerBlock.text : "",
      header_block_params: "",
      header_block_params_count: headerBlock ? headerBlock.params : 0,
      footer_block: values.whatsapp_footer_block === 1,
      footer_block_message: footerBlock ? footerBlock.text : "",
      button_block: values.whatsapp_buttons_block === 1,
      button_block_arr: buttonsBlock ? buttonsBlock.buttons : [],
      button_preview: [],
      header_preview: "",
      reset: 0,
      back: Math.random(),
    });

    setIsEditTemplate(true);
  };

  // SET FUNCTION ==============================================================

  const setFormatMessages = (input) => {
    const regBold = /\*(.*?)\*/g;
    const regItalic = /_(.*?)_/g;
    const regStrikethrough = /~(.*?)~/g;
    const regCode = /```(.*?)```/g;
    // const regBreakLine = /\n/g;

    let formattedText = input
      .replace(regBold, "<strong>$1</strong>")
      .replace(regItalic, "<em>$1</em>")
      .replace(regStrikethrough, "<s>$1</s>")
      .replace(regCode, "<code>$1</code>");
    return formattedText;
  };

  const setButtonLabel = (length, button, index) => {
    if (length > 3 && index > 1) {
      return (
        <>
          <FontAwesomeIcon icon={faBars} size="lg" className="me-2" color="#007bff" />{" "}
          <b style={{ color: "#007bff", fontSize: 13 }}>See all options</b>
        </>
      );
    }

    const buttonIcons = {
      phone_number: <FontAwesomeIcon icon={faPhoneArrowRight} size="lg" className="me-2" color="#007bff" />,
      url: <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="lg" className="me-2" color="#007bff" />,
      quick_reply: <b style={{ color: "#007bff", fontSize: 13 }}>{button.label}</b>,
      copy_code: (
        <b style={{ color: "#007bff", fontSize: 13 }}>
          Copy Code{" "}
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip id="tooltip-top">
                The button label is not editable. It is provided by Meta and will be automatically translated into the template’s language.
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon={faCircleQuestion} size="lg" className="ms-1" color="#007bff" />
          </OverlayTrigger>{" "}
        </b>
      ),
    };

    return buttonIcons[button.type] ? (
      <div className="d-flex align-items-center">
        {buttonIcons[button.type]}{" "}
        {button.type !== "quick_reply" && button.type !== "copy_code" ? <b style={{ color: "#007bff", fontSize: 13 }}>{button.label}</b> : null}
      </div>
    ) : null;
  };

  const setButtonWidth = (values, index) => {
    if (values.button_block_arr.length === 1) {
      return 12;
    } else if (values.button_block_arr.length === 2) {
      return 12;
    } else if (values.button_block_arr.length > 2) {
      return [0, 1].includes(index) ? 6 : 12;
    }
    return 12;
  };

  const setButtonTemplateDisabled = (values) => {
    if (values.chat_instance_id === "") return true;
    if (values.template_name === "") return true;
    if (values.template_type === "") return true;
    if (values.template_category === "") return true;
    if (values.message_content === "") return true;
    if (values.message_content.length > 1024) return true;

    if (values.template_type === "media") {
      if (values.header_block) {
        if (values.header_block_type === "") return true;
        if (values.header_block_type === "text") {
          if (values.header_block_message === "") return true;
          if (values.header_block_message.length > 60) return true;
        }
      }

      if (values.footer_block) {
        if (values.footer_block_message === "") return true;
        if (values.footer_block_message.length > 60) return true;
      }

      if (values.button_block) {
        if (values.button_block_arr.length === 0) {
          return true;
        } else {
          for (let i = 0; i < values.button_block_arr.length; i++) {
            const button = values.button_block_arr[i];
            if (button.type === "") {
              return true;
            } else if (button.type === "phone_number") {
              if (button.label === "") return true;
              if (button.label.length > 25) return true;
              if (button.phone_number === "") return true;
            } else if (button.type === "url") {
              if (button.label === "") return true;
              if (button.label.length > 25) return true;
              if (button.url === "") return true;
            } else if (button.type === "quick_reply") {
              if (button.label === "") return true;
              if (button.label.length > 25) return true;
            }
          }
        }
      }
    }

    return false;
  };

  const setAlertShow = (values) => {
    let result = false;
    if (values.header_block) {
      if (["image", "video", "document"].includes(values.header_block_type)) {
        if (result === false) {
          result = true;
        }
      } else {
        if (values.header_block_params_count > 0) {
          if (result === false) {
            result = true;
          }
        }
      }
    }

    if (values.params > 0) {
      if (result === false) {
        result = true;
      }
    }

    if (values.button_block) {
      if (values.button_block_arr.length > 0) {
        for (let i = 0; i < values.button_block_arr.length; i++) {
          const button = values.button_block_arr[i];
          if (button.type === "url" && button.params === true) {
            if (result === false) {
              result = true;
              break;
            }
          } else if (button.type === "copy_code") {
            if (result === false) {
              result = true;
              break;
            }
          }
        }
      }
    }

    return result;
  };

  const setPreviewTemplateDisabled = (values) => {
    if (values.header_block) {
      if (["image", "video", "document"].includes(values.header_block_type.toLowerCase()) && values.params_header_example === "") return true;
      if (values.header_block_type.toLowerCase() === "text" && values.header_block_params_count > 0 && values.header_block_params === "") return true;
    }

    console.log("here");

    if (values.params > 0) {
      if (values.params_example.every((record) => record === "")) return true;
    }

    console.log("here 1");

    if (values.button_block) {
      const button = values.button_preview.find((record) => record.type === "url" && record.params === true && record.params_value === "");
      if (button) return true;
      const button2 = values.button_preview.find((record) => record.type === "copy_code" && record.params_value === "");
      if (button2) return true;
    }

    console.log("here 2");

    if (imgError) return true;
    console.log("here 3");
    if (videoError) return true;
    console.log("here 4");
    if (loadingBtn) return true;
    console.log("here 5");

    return false;
  };

  const setDecodeJson = (values) => {
    let preview;
    try {
      preview = values ? JSON.parse(values) : { text: "" };
    } catch (error) {
      preview = { text: "" };
    }
    return preview;
  };

  // USE EFFECT ================================================================

  useEffect(() => {
    const initializeData = async () => {
      if (!init.current) {
        try {
          await getChatInstance();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initializeData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // GRID SETUP ==============================
  const gridRef = useRef(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "80vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridColumn = [
    {
      headerName: "Interactive Template",
      field: "whatsapp_template_name",
      filter: true,
    },
    {
      headerName: "Category",
      field: "whatsapp_template_category",
      filter: true,
      maxWidth: 200,
      cellRenderer: (params) => {
        return params.data.whatsapp_template_category.charAt(0).toUpperCase() + params.data.whatsapp_template_category.slice(1);
      },
    },
    {
      headerName: "Sync Status (Meta)",
      field: "new_waba_status",
      cellRenderer: (params) => {
        if (params.data.new_waba_status === "approved") {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "rgba(25, 135, 84, 1)", color: "#fff", padding: "5px 10px", borderRadius: 5 }}
            >
              Approved
            </div>
          );
        } else if (params.data.new_waba_status === "submitted") {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "rgba(108, 117, 125, 0.3)", color: "#6c757d", padding: "5px 10px", borderRadius: 5 }}
            >
              Submitted
            </div>
          );
        } else if (params.data.new_waba_status === "rejected") {
          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  This template contains content that does not comply with WhatsApp's policies. We recommend you to read the Template guidelines
                </Tooltip>
              }
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "rgba(220, 53, 70, 1)", color: "#fff", padding: "5px 10px", borderRadius: 5 }}
              >
                Rejected
              </div>
            </OverlayTrigger>
          );
        } else if (params.data.new_waba_status === "created") {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "rgba(13, 109, 253, 1)", color: "#fff", padding: "5px 10px", borderRadius: 5 }}
            >
              Created
            </div>
          );
        } else {
          return (
            <div
              className="d-flex justify-content-center align-items-center border text-capitalize"
              style={{ backgroundColor: "fff", color: "#000", padding: "5px 10px", borderRadius: 5 }}
            >
              {params.data.new_waba_status ? params.data.new_waba_status.charAt(0).toUpperCase() + params.data.new_waba_status.slice(1) : "Pending"}
            </div>
          );
        }
      },
    },
    {
      headerName: "Preview",
      field: "whatsapp_body_component",
      flex: 1,
      cellRenderer: (params) => {
        if (params.data.whatsapp_body_component) {
          let preview;
          try {
            preview = params.data.whatsapp_body_component ? JSON.parse(params.data.whatsapp_body_component) : { text: "" };
          } catch (error) {
            preview = { text: "" };
          }
          const preview_text = preview.text;
          return preview_text;
        }

        return null;
      },
    },
    {
      headerName: "",
      field: "",
      pinned: "right",
      maxWidth: 150,
      cellRenderer: (params) => {
        if (params) {
          return (
            <div className="d-flex justify-content-center align-items-center w-100">
              <Button variant="light" className="border shadow-sm me-2" onClick={() => openEditTemplate(params.data)}>
                <FontAwesomeIcon icon={faCopy} />
              </Button>
              <Button variant="light" className="border shadow-sm" onClick={() => sendDeleteWsCampaignTemplate(params.data)}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          );
        }
        return null;
      },
    },
  ];

  const gridRowId = useCallback(function (params) {
    return params.data.whatsapp_template_op_id.toString();
  }, []);

  return (
    <Container fluid className="p-4 position-relative op-ws-campaign-settings" style={{ height: "calc(100vh - 56px)" }}>
      {loading ? (
        <Loader />
      ) : (
        <Row>
          <Col sm={12} className="d-flex justify-content-between align-items-center mb-3">
            <Stack direction="horizontal" gap={2} className="w-100">
              <Button
                variant=""
                className="op-primary-color text-light"
                onClick={() => {
                  setFormValues({
                    template_name: "",
                    template_type: "text",
                    template_category: "marketing",
                    message_content: "",
                    message_preview: "",
                    media: "",
                    chat_instance_id: chatInstanceData.value,
                    params: 0,
                    character_count: 0,
                    warning: false,
                    warning_message: [],
                    section: 1,
                    params_example: [],
                    params_header_example: "",
                    header_block: false,
                    header_block_type: "text",
                    header_block_message: "",
                    header_block_params: "",
                    header_block_params_count: 0,
                    footer_block: false,
                    footer_block_message: "",
                    button_block: false,
                    button_block_arr: [],
                    button_preview: [],
                    header_preview: "",
                    reset: 0,
                    back: 0,
                  });
                  setLoadingBtn(false);
                  setIsAddNewTemplate(true);
                }}
              >
                Add Interactive Template
              </Button>
              <Button
                variant="link"
                className="text-decoration-none"
                style={{ color: "#007bff" }}
                onClick={() => window.open("https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/", "_blank")}
              >
                Guidelines
              </Button>
              <div className="ms-auto" />
              <p className="m-0">{`${
                gridRecord.length > 1 ? `${gridRecord.length} records` : gridRecord.length === 1 ? `${gridRecord.length} record` : "No record"
              }`}</p>
              {chatInstanceShow ? (
                <FormSelect options={chatInstanceList} valueDefault={chatInstanceData} onChange={onchangeChatInstance} width="250px" isSearchable />
              ) : (
                <Placeholder animation="glow">
                  <Placeholder bg="secondary" style={{ width: 250, height: 30, borderRadius: 8 }} />{" "}
                </Placeholder>
              )}

              <Button
                variant="light"
                className="border shadow-sm"
                onClick={() => {
                  setLoading2(true);
                  getWsCampaignTemplateList(chatInstanceData.value);
                }}
              >
                <FontAwesomeIcon icon={faRotateRight} />
              </Button>
            </Stack>
          </Col>
          {loading2 ? (
            <Col sm={12}>
              <div className="d-flex justify-content-center align-items-center">
                <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </div>
            </Col>
          ) : (
            <Col sm={12}>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact
                    ref={gridRef}
                    rowSelection="multiple"
                    rowData={gridRecord}
                    rowHeight={70}
                    columnDefs={gridColumn}
                    getRowId={gridRowId}
                    pagination={true}
                    paginationPageSize={100}
                    paginationPageSizeSelector={false}
                    suppressRowClickSelection={true}
                    animateRows={true}
                  />
                </div>
              </div>
            </Col>
          )}
        </Row>
      )}

      <Modal show={isAddNewTemplate} onHide={setIsAddNewTemplate} size="xl" backdrop="static">
        {formValues.section === 1 && (
          <Formik onSubmit={sendValidateNewTemplate} initialValues={formValues}>
            {({ handleSubmit, setFieldValue, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Modal.Header closeButton={loadingBtn ? false : true}>
                  <Modal.Title as={"h6"}>Add Whatsapp Template</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: "75vh" }}>
                  <Row>
                    <Col sm={7} className="scrollable-modal">
                      <Form.Group className="mb-3">
                        <Form.Label>Chat Instance Number</Form.Label>
                        <FormSelect
                          valueDefault={chatInstanceList.find((record) => record.value === values.chat_instance_id)}
                          options={chatInstanceList}
                          onChange={(e) => setFieldValue("chat_instance_id", e.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Template Name</Form.Label>
                        <Form.Control
                          value={values.template_name}
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue("template_name", value.toLowerCase().replace(/\s/g, ""));
                          }}
                        />
                      </Form.Group>

                      <Row className="mb-4">
                        <Form.Group as={Col}>
                          <Form.Label>Template Type</Form.Label>
                          <FormSelect
                            valueDefault={templateTypeList.find((record) => record.value === values.template_type)}
                            options={templateTypeList}
                            onChange={(e) => {
                              setFieldValue("template_type", e.value);
                              setFieldValue("message_content", "");
                              setFieldValue("params", 0);
                              setFieldValue("params_example", []);
                              setFieldValue("params_header_example", "");
                              setFieldValue("header_block", true);
                              setFieldValue("header_block_type", "text");
                              setFieldValue("header_block_message", "");
                              setFieldValue("header_block_params", "");
                              setFieldValue("header_block_params_count", 0);
                              setFieldValue("footer_block", true);
                              setFieldValue("footer_block_message", "");
                              setFieldValue("button_block", true);
                              setFieldValue("button_block_arr", []);
                              setFieldValue("reset", Math.random());
                            }}
                          />
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>Category</Form.Label>
                          <FormSelect valueDefault={{ label: "Marketing", value: "marketing" }} options={templateCategory} placeholder="Category" />
                        </Form.Group>
                      </Row>

                      {values.template_type === "media" && (
                        <Form.Group className="mb-3">
                          <Form.Label>Select message template building blocks.</Form.Label>
                          <div className="d-flex align-items-center">
                            <div
                              className="d-flex justify-content-center align-items-center border rounded p-2 me-3 cursor-pointer"
                              style={{ width: 100 }}
                              onClick={() => setFieldValue("header_block", !values.header_block)}
                            >
                              {values.header_block && <FontAwesomeIcon icon={faCheck} className="me-2" />}
                              Header
                            </div>
                            <div
                              className="d-flex justify-content-center align-items-center border rounded p-2 me-3 cursor-pointer"
                              style={{ width: 100 }}
                            >
                              <FontAwesomeIcon icon={faCheck} className="me-2" />
                              Body
                            </div>
                            <div
                              className="d-flex justify-content-center align-items-center border rounded p-2 me-3 cursor-pointer"
                              style={{ width: 100 }}
                              onClick={() => setFieldValue("footer_block", !values.footer_block)}
                            >
                              {values.footer_block && <FontAwesomeIcon icon={faCheck} className="me-2" />}
                              Footer
                            </div>
                            <div
                              className="d-flex justify-content-center align-items-center border rounded p-2 me-3 cursor-pointer"
                              style={{ width: 100 }}
                              onClick={() => setFieldValue("button_block", !values.button_block)}
                            >
                              {values.button_block && <FontAwesomeIcon icon={faCheck} className="me-2" />}
                              Button
                            </div>
                          </div>
                        </Form.Group>
                      )}

                      {values.template_type === "media" && values.header_block && (
                        <Form.Group className="mb-3">
                          <HeaderBlock
                            type={values.header_block_type}
                            value={values.header_block_message}
                            paramsValue={values.header_block_params_count}
                            back={values.back}
                            onChangeType={(e) => setFieldValue("header_block_type", e.value)}
                            onChangeValue={(e) => setFieldValue("header_block_message", e)}
                            onChangeParams={(e) => setFieldValue("header_block_params_count", e)}
                          />
                        </Form.Group>
                      )}

                      <Form.Group className="mb-3">
                        <BodyBlock
                          reset={values.reset}
                          value={values.message_content}
                          back={values.back}
                          onChangeValue={(e) => setFieldValue("message_content", e)}
                          onChangeParams={(e) => {
                            setFieldValue("params", e);
                            setFieldValue(
                              "params_example",
                              Array.from({ length: e }, () => "")
                            );
                          }}
                        />
                      </Form.Group>

                      {values.template_type === "media" && values.footer_block && (
                        <Form.Group className="mb-3">
                          <FooterBlock
                            back={values.back}
                            value={values.footer_block_message}
                            onChangeValue={(e) => setFieldValue("footer_block_message", e)}
                          />
                        </Form.Group>
                      )}

                      {values.template_type === "media" && values.button_block && (
                        <Form.Group className="mb-3">
                          <ButtonsBlock
                            back={values.back}
                            buttons={values.button_block_arr}
                            onChangeButtons={(e) => {
                              setFieldValue("button_block_arr", e);
                            }}
                          />
                        </Form.Group>
                      )}

                      {values.warning && (
                        <Form.Group className="mb-3">
                          <Alert variant="warning" className="w-100">
                            <ul className="m-0">
                              {values.warning_message.map((message, index) => (
                                <li key={index}>{message}</li>
                              ))}
                            </ul>
                          </Alert>
                        </Form.Group>
                      )}
                    </Col>
                    <Col sm={5} className="scrollable-modal">
                      <div className="border w-100 px-4 py-4 sticky-top" style={{ top: "0", zIndex: 1030 }}>
                        <h6>Preview</h6>
                        <div
                          className="rounded position-relative p-2"
                          style={{ background: `url(${WSBackground}) left top repeat rgb(236, 229, 221)`, minHeight: 400 }}
                        >
                          <div className="ws-bubble">
                            {values.template_type === "media" && values.header_block && (
                              <>
                                {values.header_block_type === "image" ? (
                                  <div className="ws-bubble-header">
                                    <FontAwesomeIcon icon={faImageSolid} color="#fff" size="10x" />
                                  </div>
                                ) : values.header_block_type === "document" ? (
                                  <div className="ws-bubble-header">
                                    <FontAwesomeIcon icon={faFileSolid} color="#fff" size="10x" />
                                  </div>
                                ) : values.header_block_type === "video" ? (
                                  <div className="ws-bubble-header">
                                    <FontAwesomeIcon icon={faVideoSolid} color="#fff" size="10x" />
                                  </div>
                                ) : values.header_block_type === "text" && values.header_block_message !== "" ? (
                                  <div style={{ marginBottom: 10 }}>
                                    <b
                                      className="ws-bubble-message"
                                      style={{ fontSize: 13 }}
                                      dangerouslySetInnerHTML={{ __html: setFormatMessages(values.header_block_message) }}
                                    />
                                  </div>
                                ) : null}
                              </>
                            )}

                            <p
                              className="ws-bubble-message"
                              style={{ marginBottom: 0, fontSize: 12 }}
                              dangerouslySetInnerHTML={{ __html: setFormatMessages(values.message_content) }}
                            />

                            {values.template_type === "media" && values.footer_block && values.footer_block_message !== "" && (
                              <p
                                className="ws-bubble-message"
                                style={{ color: "rgba(153,153,153,1)", fontSize: 11, marginBottom: 0 }}
                                dangerouslySetInnerHTML={{ __html: setFormatMessages(values.footer_block_message) }}
                              />
                            )}

                            <div className="ws-bubble-time">{moment().format("HH:mm A")}</div>
                          </div>

                          {values.template_type === "media" && values.button_block && values.button_block_arr.length > 0 && (
                            <Row className="g-2" style={{ marginLeft: 10, marginRight: 10, marginTop: 2 }}>
                              {values.button_block_arr.map((button, index) => {
                                if (index > 2) return null;
                                return (
                                  <Col key={index} sm={setButtonWidth(values, index)}>
                                    <div className="h-100 w-100 p-2 bg-light shadow-sm rounded cursor-pointer d-flex justify-content-center align-items-center">
                                      {setButtonLabel(values.button_block_arr.length, button, index)}
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="" type="button" onClick={() => setIsAddNewTemplate(false)}>
                    Discard Changes
                  </Button>
                  <Button
                    variant=""
                    type="submit"
                    className="btn op-button op-primary-color text-light shadow"
                    disabled={setButtonTemplateDisabled(values)}
                  >
                    Preview and Submit
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}

        {formValues.section === 2 && (
          <Formik onSubmit={sendAddWsCampaignTemplate} initialValues={formValues}>
            {({ handleSubmit, setFieldValue, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Modal.Header closeButton={loadingBtn ? false : true}>
                  <Modal.Title as={"h6"}>Template Preview</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <Row className="mb-3">
                    <Col sm={6}>
                      <h6>Example values</h6>
                      {values.template_type === "media" &&
                      values.header_block &&
                      ["image", "document", "video"].includes(values.header_block_type) ? (
                        <Form.Group className={`mb-3 mt-4`}>
                          <Form.Label>
                            {values.header_block_type === "image"
                              ? "Image URL"
                              : values.header_block_type === "document"
                              ? "Document URL"
                              : "Video URL"}
                          </Form.Label>
                          <Form.Control
                            value={values.params_header_example}
                            onChange={(e) => {
                              setFieldValue("params_header_example", e.target.value);
                              if (values.header_block_type === "video") {
                                if (e.target.value.endsWith(".mp4") || e.target.value.endsWith(".3gp") || e.target.value.endsWith(".3gpp")) {
                                  setVideoError(false);
                                } else {
                                  setVideoError(true);
                                }
                              } else {
                                setVideoError(false);
                              }
                            }}
                          />
                          {values.header_block_type === "video" && (
                            <Form.Label style={{ fontSize: 10 }}>Only for MP4, 3gp, and 3gpp files.</Form.Label>
                          )}
                        </Form.Group>
                      ) : values.template_type === "media" &&
                        values.header_block &&
                        values.header_block_type === "text" &&
                        values.header_block_params_count > 0 ? (
                        <Form.Group className={`mb-3 mt-4`}>
                          <Form.Label>{"Header: {{1}}"}</Form.Label>
                          <Form.Control
                            value={values.header_block_params}
                            onChange={(e) => {
                              setFieldValue("header_block_params", e.target.value);
                              if (e.target.value) {
                                setFieldValue("header_preview", values.header_block_message.replace(/{{\d+}}/, e.target.value));
                              } else {
                                setFieldValue("header_preview", values.header_block_message);
                              }
                            }}
                          />
                        </Form.Group>
                      ) : null}

                      {values.params > 0 && (
                        <>
                          {values.params_example.map((params_body, index) => (
                            <Form.Group className={`mb-3 ${index === 0 && values.template_type !== "media" ? "mt-4" : ""}`} key={index}>
                              <Form.Label>{`Body: {{${index + 1}}}`}</Form.Label>
                              <Form.Control
                                value={params_body}
                                onChange={(e) => {
                                  const paramsValue = [...values.params_example];
                                  paramsValue[index] = e.target.value;

                                  let message_content = values.message_content;
                                  paramsValue.forEach((record, idx) => {
                                    if (record) {
                                      message_content = message_content.replace(`{{${idx + 1}}}`, record);
                                    }
                                  });

                                  setFieldValue("params_example", paramsValue);
                                  setFieldValue("message_preview", message_content);
                                }}
                              />
                            </Form.Group>
                          ))}
                        </>
                      )}

                      {values.template_type === "media" && values.button_block && (
                        <>
                          {values.button_preview.map((button, index) => (
                            <div key={index}>
                              {(button.type === "copy_code" || (button.type === "url" && button.params === true)) && (
                                <Form.Group className={`mb-3 ${index === 0 && values.template_type !== "media" ? "mt-4" : ""}`} key={index}>
                                  <Form.Label>{`${button.type === "url" ? "Button URL" : "Code"}: {{1}}`}</Form.Label>
                                  <Form.Control
                                    value={button.params_value}
                                    onChange={(e) => {
                                      setFieldValue(`button_preview[${index}].params_value`, e.target.value);
                                      if (button.type === "url") {
                                        if (e.target.value) {
                                          setFieldValue(
                                            `button_preview[${index}].url`,
                                            values.button_block_arr[index].url.replace(/{{\d+}}/, e.target.value)
                                          );
                                        } else {
                                          setFieldValue(`button_preview[${index}].url`, values.button_block_arr[index].url);
                                        }
                                      }
                                    }}
                                  />
                                  {button.type === "url" && (
                                    <Form.Label style={{ fontSize: 10 }}>Click the button in the preview to validate your URL.</Form.Label>
                                  )}
                                </Form.Group>
                              )}
                            </div>
                          ))}
                        </>
                      )}

                      {!setAlertShow(values) && (
                        <div className="h-100 mt-5">
                          {`You haven't used any variable placeholders in your text.`} <br /> <br />
                          {`In order to add a variable, go back to the editor and insert {{#}} at the given position, where # represents the variable index, which needs to start at {{1}}.`}{" "}
                          <br /> <br />
                          {`For more information please refer to our Template Manager Documentation.`}
                        </div>
                      )}
                    </Col>
                    <Col sm={6}>
                      <div className="border w-100 px-4 py-4">
                        <div
                          className="rounded position-relative p-2"
                          style={{ background: `url(${WSBackground}) left top repeat rgb(236, 229, 221)`, minHeight: 400 }}
                        >
                          <div className="ws-bubble">
                            {["image", "document", "video"].includes(values.header_block_type) ? (
                              <div>
                                {values.params_header_example && values.header_block_type === "image" ? (
                                  <img
                                    src={values.params_header_example}
                                    alt="header"
                                    className="w-100"
                                    onLoad={() => setImgError(false)}
                                    onError={() => setImgError(true)}
                                    style={{ objectFit: "cover" }}
                                  />
                                ) : (
                                  <div className="ws-bubble-header">
                                    <FontAwesomeIcon
                                      icon={
                                        values.header_block_type === "image"
                                          ? faImageSolid
                                          : values.header_block_type === "document"
                                          ? faFileSolid
                                          : faVideoSolid
                                      }
                                      color="#fff"
                                      size="10x"
                                    />
                                  </div>
                                )}
                              </div>
                            ) : values.header_block_type === "text" ? (
                              <div style={{ marginBottom: 10 }}>
                                <b
                                  className="ws-bubble-message"
                                  style={{ fontSize: 13 }}
                                  dangerouslySetInnerHTML={{ __html: setFormatMessages(values.header_preview) }}
                                />
                              </div>
                            ) : null}

                            <p
                              className="ws-bubble-message"
                              style={{ marginBottom: 0, fontSize: 12 }}
                              dangerouslySetInnerHTML={{ __html: setFormatMessages(values.message_preview) }}
                            />

                            {values.template_type === "media" && values.footer_block && values.footer_block_message !== "" && (
                              <p
                                className="ws-bubble-message"
                                style={{ color: "rgba(153,153,153,1)", fontSize: 11, marginBottom: 0 }}
                                dangerouslySetInnerHTML={{ __html: setFormatMessages(values.footer_block_message) }}
                              />
                            )}

                            <div className="ws-bubble-time">{moment().format("HH:mm A")}</div>
                          </div>

                          {values.template_type === "media" && values.button_block && values.button_block_arr.length > 0 && (
                            <Row className="g-2" style={{ marginLeft: 10, marginRight: 10, marginTop: 2 }}>
                              {values.button_preview.map((button, index) => {
                                if (index > 2) return null;
                                return (
                                  <Col key={index} sm={setButtonWidth(values, index)}>
                                    <div
                                      className="h-100 w-100 p-2 bg-light shadow-sm rounded cursor-pointer d-flex justify-content-center align-items-center"
                                      onClick={() => {
                                        if (button.type === "url") {
                                          window.open(button.url, "_blank");
                                        }
                                      }}
                                    >
                                      {setButtonLabel(values.button_block_arr.length, button, index)}
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {setAlertShow(values) && (
                    <Alert variant="warning" className="w-100">
                      The provided example values will be submitted to Meta as part of the template submission process. It is required to add example
                      values for all variables in order to submit the template.
                    </Alert>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="" type="button" disabled={loadingBtn} onClick={() => sendBackToEditor(values)}>
                    Back to editor
                  </Button>
                  <Button
                    variant=""
                    type="submit"
                    className="btn op-button op-primary-color text-light shadow"
                    disabled={setPreviewTemplateDisabled(values)}
                  >
                    {loadingBtn ? (
                      <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Save Template"
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
      </Modal>

      <Modal show={isEditTemplate} onHide={setIsEditTemplate} size="xl" backdrop="static">
        {formValuesEdit.section === 1 && (
          <Formik onSubmit={sendValidateNewTemplateEdit} initialValues={formValuesEdit}>
            {({ handleSubmit, setFieldValue, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Modal.Header closeButton={loadingBtn ? false : true}>
                  <Modal.Title as={"h6"}>Add Whatsapp Template</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: "75vh" }}>
                  <Row>
                    <Col sm={7} className="scrollable-modal">
                      <Form.Group className="mb-3">
                        <Form.Label>Chat Instance Number</Form.Label>
                        <FormSelect
                          valueDefault={chatInstanceList.find((record) => Number(record.value) === Number(values.chat_instance_id))}
                          options={chatInstanceList}
                          onChange={(e) => setFieldValue("chat_instance_id", e.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Template Name</Form.Label>
                        <Form.Control
                          value={values.template_name}
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue("template_name", value.toLowerCase().replace(/\s/g, ""));
                          }}
                        />
                      </Form.Group>

                      <Row className="mb-4">
                        <Form.Group as={Col}>
                          <Form.Label>Template Type</Form.Label>
                          <FormSelect
                            valueDefault={templateTypeList.find((record) => record.value === values.template_type)}
                            options={templateTypeList}
                            onChange={(e) => {
                              setFieldValue("template_type", e.value);
                              setFieldValue("message_content", "");
                              setFieldValue("params", 0);
                              setFieldValue("params_example", []);
                              setFieldValue("params_header_example", "");
                              setFieldValue("header_block", true);
                              setFieldValue("header_block_type", "text");
                              setFieldValue("header_block_message", "");
                              setFieldValue("header_block_params", "");
                              setFieldValue("header_block_params_count", 0);
                              setFieldValue("footer_block", true);
                              setFieldValue("footer_block_message", "");
                              setFieldValue("button_block", true);
                              setFieldValue("button_block_arr", []);
                              setFieldValue("reset", Math.random());
                            }}
                          />
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>Category</Form.Label>
                          <FormSelect valueDefault={{ label: "Marketing", value: "marketing" }} options={templateCategory} placeholder="Category" />
                        </Form.Group>
                      </Row>

                      {values.template_type === "media" && (
                        <Form.Group className="mb-3">
                          <Form.Label>Select message template building blocks.</Form.Label>
                          <div className="d-flex align-items-center">
                            <div
                              className="d-flex justify-content-center align-items-center border rounded p-2 me-3 cursor-pointer"
                              style={{ width: 100 }}
                              onClick={() => setFieldValue("header_block", !values.header_block)}
                            >
                              {values.header_block && <FontAwesomeIcon icon={faCheck} className="me-2" />}
                              Header
                            </div>
                            <div
                              className="d-flex justify-content-center align-items-center border rounded p-2 me-3 cursor-pointer"
                              style={{ width: 100 }}
                            >
                              <FontAwesomeIcon icon={faCheck} className="me-2" />
                              Body
                            </div>
                            <div
                              className="d-flex justify-content-center align-items-center border rounded p-2 me-3 cursor-pointer"
                              style={{ width: 100 }}
                              onClick={() => setFieldValue("footer_block", !values.footer_block)}
                            >
                              {values.footer_block && <FontAwesomeIcon icon={faCheck} className="me-2" />}
                              Footer
                            </div>
                            <div
                              className="d-flex justify-content-center align-items-center border rounded p-2 me-3 cursor-pointer"
                              style={{ width: 100 }}
                              onClick={() => setFieldValue("button_block", !values.button_block)}
                            >
                              {values.button_block && <FontAwesomeIcon icon={faCheck} className="me-2" />}
                              Button
                            </div>
                          </div>
                        </Form.Group>
                      )}

                      {values.template_type === "media" && values.header_block && (
                        <Form.Group className="mb-3">
                          <HeaderBlock
                            type={values.header_block_type}
                            value={values.header_block_message}
                            paramsValue={values.header_block_params_count}
                            back={values.back}
                            onChangeType={(e) => setFieldValue("header_block_type", e.value)}
                            onChangeValue={(e) => setFieldValue("header_block_message", e)}
                            onChangeParams={(e) => setFieldValue("header_block_params_count", e)}
                          />
                        </Form.Group>
                      )}

                      <Form.Group className="mb-3">
                        <BodyBlock
                          reset={values.reset}
                          value={values.message_content}
                          back={values.back}
                          onChangeValue={(e) => setFieldValue("message_content", e)}
                          onChangeParams={(e) => {
                            setFieldValue("params", e);
                            setFieldValue(
                              "params_example",
                              Array.from({ length: e }, () => "")
                            );
                          }}
                        />
                      </Form.Group>

                      {values.template_type === "media" && values.footer_block && (
                        <Form.Group className="mb-3">
                          <FooterBlock
                            back={values.back}
                            value={values.footer_block_message}
                            onChangeValue={(e) => setFieldValue("footer_block_message", e)}
                          />
                        </Form.Group>
                      )}

                      {values.template_type === "media" && values.button_block && (
                        <Form.Group className="mb-3">
                          <ButtonsBlock
                            back={values.back}
                            buttons={values.button_block_arr}
                            onChangeButtons={(e) => {
                              setFieldValue("button_block_arr", e);
                            }}
                          />
                        </Form.Group>
                      )}

                      {values.warning && (
                        <Form.Group className="mb-3">
                          <Alert variant="warning" className="w-100">
                            <ul className="m-0">
                              {values.warning_message.map((message, index) => (
                                <li key={index}>{message}</li>
                              ))}
                            </ul>
                          </Alert>
                        </Form.Group>
                      )}
                    </Col>
                    <Col sm={5} className="scrollable-modal">
                      <div className="border w-100 px-4 py-4 sticky-top" style={{ top: "0", zIndex: 1030 }}>
                        <h6>Preview</h6>
                        <div
                          className="rounded position-relative p-2"
                          style={{ background: `url(${WSBackground}) left top repeat rgb(236, 229, 221)`, minHeight: 400 }}
                        >
                          <div className="ws-bubble">
                            {values.template_type === "media" && values.header_block && (
                              <>
                                {values.header_block_type === "image" ? (
                                  <div className="ws-bubble-header">
                                    <FontAwesomeIcon icon={faImageSolid} color="#fff" size="10x" />
                                  </div>
                                ) : values.header_block_type === "document" ? (
                                  <div className="ws-bubble-header">
                                    <FontAwesomeIcon icon={faFileSolid} color="#fff" size="10x" />
                                  </div>
                                ) : values.header_block_type === "video" ? (
                                  <div className="ws-bubble-header">
                                    <FontAwesomeIcon icon={faVideoSolid} color="#fff" size="10x" />
                                  </div>
                                ) : values.header_block_type === "text" && values.header_block_message !== "" ? (
                                  <div style={{ marginBottom: 10 }}>
                                    <b
                                      className="ws-bubble-message"
                                      style={{ fontSize: 13 }}
                                      dangerouslySetInnerHTML={{ __html: setFormatMessages(values.header_block_message) }}
                                    />
                                  </div>
                                ) : null}
                              </>
                            )}

                            <p
                              className="ws-bubble-message"
                              style={{ marginBottom: 0, fontSize: 12 }}
                              dangerouslySetInnerHTML={{ __html: setFormatMessages(values.message_content) }}
                            />

                            {values.template_type === "media" && values.footer_block && values.footer_block_message !== "" && (
                              <p
                                className="ws-bubble-message"
                                style={{ color: "rgba(153,153,153,1)", fontSize: 11, marginBottom: 0 }}
                                dangerouslySetInnerHTML={{ __html: setFormatMessages(values.footer_block_message) }}
                              />
                            )}

                            <div className="ws-bubble-time">{moment().format("HH:mm A")}</div>
                          </div>

                          {values.template_type === "media" && values.button_block && values.button_block_arr.length > 0 && (
                            <Row className="g-2" style={{ marginLeft: 10, marginRight: 10, marginTop: 2 }}>
                              {values.button_block_arr.map((button, index) => {
                                if (index > 2) return null;
                                return (
                                  <Col key={index} sm={setButtonWidth(values, index)}>
                                    <div className="h-100 w-100 p-2 bg-light shadow-sm rounded cursor-pointer d-flex justify-content-center align-items-center">
                                      {setButtonLabel(values.button_block_arr.length, button, index)}
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="" type="button" onClick={() => setIsEditTemplate(false)}>
                    Discard Changes
                  </Button>
                  <Button
                    variant=""
                    type="submit"
                    className="btn op-button op-primary-color text-light shadow"
                    disabled={setButtonTemplateDisabled(values)}
                  >
                    Preview and Submit
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}

        {formValuesEdit.section === 2 && (
          <Formik onSubmit={sendUpdateWsCampaignTemplate} initialValues={formValuesEdit}>
            {({ handleSubmit, setFieldValue, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Modal.Header closeButton={loadingBtn ? false : true}>
                  <Modal.Title as={"h6"}>Template Preview</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <Row className="mb-3">
                    <Col sm={6}>
                      <h6>Example values</h6>
                      {values.template_type === "media" &&
                      values.header_block &&
                      ["image", "document", "video"].includes(values.header_block_type) ? (
                        <Form.Group className={`mb-3 mt-4`}>
                          <Form.Label>
                            {values.header_block_type === "image"
                              ? "Image URL"
                              : values.header_block_type === "document"
                              ? "Document URL"
                              : "Video URL"}
                          </Form.Label>
                          <Form.Control
                            value={values.params_header_example}
                            onChange={(e) => {
                              setFieldValue("params_header_example", e.target.value);
                              if (values.header_block_type === "video") {
                                if (e.target.value.endsWith(".mp4") || e.target.value.endsWith(".3gp") || e.target.value.endsWith(".3gpp")) {
                                  setVideoError(false);
                                } else {
                                  setVideoError(true);
                                }
                              } else {
                                setVideoError(false);
                              }
                            }}
                          />
                          {values.header_block_type === "video" && (
                            <Form.Label style={{ fontSize: 10 }}>Only for MP4, 3gp, and 3gpp files.</Form.Label>
                          )}
                        </Form.Group>
                      ) : values.template_type === "media" &&
                        values.header_block &&
                        values.header_block_type === "text" &&
                        values.header_block_params_count > 0 ? (
                        <Form.Group className={`mb-3 mt-4`}>
                          <Form.Label>{"Header: {{1}}"}</Form.Label>
                          <Form.Control
                            value={values.header_block_params}
                            onChange={(e) => {
                              setFieldValue("header_block_params", e.target.value);
                              if (e.target.value) {
                                setFieldValue("header_preview", values.header_block_message.replace(/{{\d+}}/, e.target.value));
                              } else {
                                setFieldValue("header_preview", values.header_block_message);
                              }
                            }}
                          />
                        </Form.Group>
                      ) : null}

                      {values.params > 0 && (
                        <>
                          {values.params_example.map((params_body, index) => (
                            <Form.Group className={`mb-3 ${index === 0 && values.template_type !== "media" ? "mt-4" : ""}`} key={index}>
                              <Form.Label>{`Body: {{${index + 1}}}`}</Form.Label>
                              <Form.Control
                                value={params_body}
                                onChange={(e) => {
                                  const paramsValue = [...values.params_example];
                                  paramsValue[index] = e.target.value;

                                  let message_content = values.message_content;
                                  paramsValue.forEach((record, idx) => {
                                    if (record) {
                                      message_content = message_content.replace(`{{${idx + 1}}}`, record);
                                    }
                                  });

                                  setFieldValue("params_example", paramsValue);
                                  setFieldValue("message_preview", message_content);
                                }}
                              />
                            </Form.Group>
                          ))}
                        </>
                      )}

                      {values.template_type === "media" && values.button_block && (
                        <>
                          {values.button_preview.map((button, index) => (
                            <div key={index}>
                              {(button.type === "copy_code" || (button.type === "url" && button.params === true)) && (
                                <Form.Group className={`mb-3 ${index === 0 && values.template_type !== "media" ? "mt-4" : ""}`} key={index}>
                                  <Form.Label>{`${button.type === "url" ? "Button URL" : "Code"}: {{1}}`}</Form.Label>
                                  <Form.Control
                                    value={button.params_value}
                                    onChange={(e) => {
                                      setFieldValue(`button_preview[${index}].params_value`, e.target.value);
                                      if (button.type === "url") {
                                        if (e.target.value) {
                                          setFieldValue(
                                            `button_preview[${index}].url`,
                                            values.button_block_arr[index].url.replace(/{{\d+}}/, e.target.value)
                                          );
                                        } else {
                                          setFieldValue(`button_preview[${index}].url`, values.button_block_arr[index].url);
                                        }
                                      }
                                    }}
                                  />
                                  {button.type === "url" && (
                                    <Form.Label style={{ fontSize: 10 }}>Click the button in the preview to validate your URL.</Form.Label>
                                  )}
                                </Form.Group>
                              )}
                            </div>
                          ))}
                        </>
                      )}

                      {!setAlertShow(values) && (
                        <div className="h-100 mt-5">
                          {`You haven't used any variable placeholders in your text.`} <br /> <br />
                          {`In order to add a variable, go back to the editor and insert {{#}} at the given position, where # represents the variable index, which needs to start at {{1}}.`}{" "}
                          <br /> <br />
                          {`For more information please refer to our Template Manager Documentation.`}
                        </div>
                      )}
                    </Col>
                    <Col sm={6}>
                      <div className="border w-100 px-4 py-4">
                        <div
                          className="rounded position-relative p-2"
                          style={{ background: `url(${WSBackground}) left top repeat rgb(236, 229, 221)`, minHeight: 400 }}
                        >
                          <div className="ws-bubble">
                            {["image", "document", "video"].includes(values.header_block_type) ? (
                              <div>
                                {values.params_header_example && values.header_block_type === "image" ? (
                                  <img
                                    src={values.params_header_example}
                                    alt="header"
                                    className="w-100"
                                    onLoad={() => setImgError(false)}
                                    onError={() => setImgError(true)}
                                    style={{ objectFit: "cover" }}
                                  />
                                ) : (
                                  <div className="ws-bubble-header">
                                    <FontAwesomeIcon
                                      icon={
                                        values.header_block_type === "image"
                                          ? faImageSolid
                                          : values.header_block_type === "document"
                                          ? faFileSolid
                                          : faVideoSolid
                                      }
                                      color="#fff"
                                      size="10x"
                                    />
                                  </div>
                                )}
                              </div>
                            ) : values.header_block_type === "text" ? (
                              <div style={{ marginBottom: 10 }}>
                                <b
                                  className="ws-bubble-message"
                                  style={{ fontSize: 13 }}
                                  dangerouslySetInnerHTML={{ __html: setFormatMessages(values.header_preview) }}
                                />
                              </div>
                            ) : null}

                            <p
                              className="ws-bubble-message"
                              style={{ marginBottom: 0, fontSize: 12 }}
                              dangerouslySetInnerHTML={{ __html: setFormatMessages(values.message_preview) }}
                            />

                            {values.template_type === "media" && values.footer_block && values.footer_block_message !== "" && (
                              <p
                                className="ws-bubble-message"
                                style={{ color: "rgba(153,153,153,1)", fontSize: 11, marginBottom: 0 }}
                                dangerouslySetInnerHTML={{ __html: setFormatMessages(values.footer_block_message) }}
                              />
                            )}

                            <div className="ws-bubble-time">{moment().format("HH:mm A")}</div>
                          </div>
                          {values.template_type === "media" && values.button_block && values.button_block_arr.length > 0 && (
                            <Row className="g-2" style={{ marginLeft: 10, marginRight: 10, marginTop: 2 }}>
                              {values.button_preview.map((button, index) => {
                                if (index > 2) return null;
                                return (
                                  <Col key={index} sm={setButtonWidth(values, index)}>
                                    <div
                                      className="h-100 w-100 p-2 bg-light shadow-sm rounded cursor-pointer d-flex justify-content-center align-items-center"
                                      onClick={() => {
                                        if (button.type === "url") {
                                          window.open(button.url, "_blank");
                                        }
                                      }}
                                    >
                                      {setButtonLabel(values.button_block_arr.length, button, index)}
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {setAlertShow(values) && (
                    <Alert variant="warning" className="w-100">
                      The provided example values will be submitted to Meta as part of the template submission process. It is required to add example
                      values for all variables in order to submit the template.
                    </Alert>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="" type="button" disabled={loadingBtn} onClick={() => sendBackToEditorEdit(values)}>
                    Back to editor
                  </Button>
                  <Button
                    variant=""
                    type="submit"
                    className="btn op-button op-primary-color text-light shadow"
                    disabled={setPreviewTemplateDisabled(values)}
                  >
                    {loadingBtn ? (
                      <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Save Template"
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </Container>
  );
};

export default SettingsWsCampaignTemplate360;
