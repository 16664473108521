import { useState, useMemo, useRef } from "react";
import { InputGroup, Form, ListGroup, Container, Stack } from "react-bootstrap";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

const ChooseCuration = ({ show, curations, curation, prospects, onChangeCuration = () => null, onNext = () => null }) => {
  const [search, setSearch] = useState("");

  // Handle ---------------------------------------------------

  const handleChangeCuration = (item) => {
    if (item.value === curation.value) return;
    onChangeCuration(item);
  };

  // Usememo --------------------------------------------------

  const curation_options = useMemo(() => curations.filter((item) => item.label.toLowerCase().includes(search.toLowerCase())), [search]);
  const container_style = useMemo(() => ({ width: "100%", height: "70vh" }), []);
  const grid_style = useMemo(() => ({ height: "100%", width: "100%" }), []);

  // Grid -----------------------------------------------------
  const gridRef = useRef();
  const grid_column = [
    { headerName: "Name", field: "prospect_name", flex: 1, minWidth: 200 },
    { headerName: "Contact", field: "contact_num", flex: 1, minWidth: 200 },
    { headerName: "Email", field: "email_title", flex: 1, minWidth: 200 },
  ];

  const grid_col_def = useMemo(() => ({ sortable: false, filter: false }), []);

  // Styles ---------------------------------------------------

  const styles = {
    sidebar: {
      width: 350,
    },
    content: {
      backgroundColor: "#eeeeee",
      width: "calc(100% - 350px)",
    },
    form_control_icon: {
      backgroundColor: "#fff",
      paddingRight: 2,
      border: "none",
    },
    form_control: {
      borderLeft: "none",
      outline: "none",
      paddingRight: 0,
      boxShadow: "none",
      border: "none",
    },
    list_divider: {
      margin: "0 15px",
      borderTop: ".5px solid #eee",
    },
  };

  if (!show) return null;

  return (
    <Container fluid className="d-flex h-100 p-0">
      <section className="border-end" style={styles.sidebar}>
        <div className="w-100" style={{ zIndex: 999, borderBottom: "1px solid #ddd", backgroundColor: "#f5f5f5" }}>
          <div className="px-2 d-flex align-items-center justify-content-center" style={{ height: 50 }}>
            <InputGroup>
              <InputGroup.Text style={{ ...styles.form_control_icon, backgroundColor: "#f5f5f5" }}>
                <FontAwesomeIcon icon={faMagnifyingGlass} rotation={90} />
              </InputGroup.Text>
              <Form.Control placeholder="Search curation" value={search} style={{ ...styles.form_control, backgroundColor: "#f5f5f5" }} onChange={(e) => setSearch(e.target.value)} />
            </InputGroup>
          </div>
        </div>

        <div className="op-scroll w-100 overflow-auto" style={{ height: "calc(100vh - 190px)" }}>
          <ListGroup>
            {curation_options.length ? (
              <div>
                {curation_options.map((item, index) => (
                  <div>
                    <ListGroup.Item
                      key={index}
                      action
                      active={curation.value === item.value}
                      className="cursor-pointer rounded-0 d-flex align-items-center justify-content-between"
                      style={{ padding: "15px 20px", border: "none" }}
                      onClick={() => handleChangeCuration(item)}
                    >
                      <span style={{ wordWrap: "break-word", width: 280 }}>{item.label}</span>
                      <FontAwesomeIcon icon={faChevronRight} color="#999" />
                    </ListGroup.Item>
                    <div style={styles.list_divider}></div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center mt-3">No curation found</div>
            )}
          </ListGroup>
        </div>
      </section>
      <section className="h-100 p-3" style={styles.content}>
        <div style={container_style}>
          <div className={"ag-theme-quartz ag-op"} style={{ ...grid_style }}>
            <AgGridReact ref={gridRef} columnDefs={grid_column} rowData={prospects} defaultColDef={grid_col_def} rowHeight={50} pagination={false} />
          </div>
        </div>

        <div className="offcanvas-footer" style={{ height: 60, zIndex: 100, left: 350, width: "calc(100% - 350px)" }}>
          <Stack direction="horizontal" gap={2}>
            <button className="btn op-button op-primary-color text-light ms-auto" onClick={onNext}>
              Next
            </button>
          </Stack>
        </div>
      </section>
    </Container>
  );
};

export default ChooseCuration;
