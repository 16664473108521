import React, { useState, useRef, useEffect } from "react";
import { Card } from "react-bootstrap";

const FooterBlock = ({ value = "", back = 0, onChangeValue = () => null }) => {
  const messageRef = useRef(null);
  const style = {
    border: "none",
    outline: "none",
    whiteSpace: "pre-line",
  };

  const handleKeyDown = (e) => {
    const value = messageRef.current.textContent;
    if (value.length >= 60 && e.key !== "Backspace" && e.key !== "Delete") {
      e.preventDefault();
    }

    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default action
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const customLineBreak = document.createTextNode("\n");
      const spacer = document.createTextNode("\u00A0");
      range.deleteContents();
      range.insertNode(customLineBreak);
      range.collapse(false);
      range.insertNode(spacer);
      selection.removeAllRanges();
      selection.addRange(range);
      onChangeValue(messageRef.current.textContent);
    }
  };

  const handleInput = (e) => {
    const value = e.currentTarget.textContent;
    onChangeValue(value);
  };

  useEffect(() => {
    if (messageRef.current && Number(back) > 0) {
      messageRef.current.textContent = value;
    }
  }, [back, messageRef.current]);

  return (
    <Card>
      <Card.Header className="d-flex align-items-center">
        <div className="op-primary-color text-light rounded me-3" style={{ padding: "6px 10px" }}>
          FOOTER
        </div>
      </Card.Header>
      <Card.Body className="position-relative w-100">
        <div
          ref={messageRef}
          dir="ltr"
          contentEditable="true"
          placeholder="Type your template footer text here..."
          className="editable-div footer-block w-100 mb-4"
          style={style}
          onKeyDown={handleKeyDown}
          onInput={handleInput}
        />
        {value && (
          <div style={{ position: "absolute", bottom: 0, right: 0, fontSize: 10, padding: "2px 10px" }} className="op-primary-color text-light">
            {value.length} / 60 characters
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default FooterBlock;
