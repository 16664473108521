import { useState, useRef, useEffect, useMemo } from "react";
import { Card, Row, Col, Stack, Form, Modal, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import FormSelect from "../includes/FormSelect";
import moment from "moment";
import Loader from "../includes/Loader";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2/dist/sweetalert2";
import { faDownload, faPrint } from "@fortawesome/pro-solid-svg-icons";

const CommissionDashboard = () => {
    const init = useRef(false);
    const gridRef = useRef(null);
    const { session } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(false);
    
    const [searchText, setSearchText] = useState("");
    const [yearList, setYearList] = useState([]);
    const [monthList, setMonthList] = useState([
        { label: "January", value: "January" },
        { label: "February", value: "February" },
        { label: "March", value: "March" },
        { label: "April", value: "April" },
        { label: "May", value: "May" },
        { label: "June", value: "June" },
        { label: "July", value: "July" },
        { label: "August", value: "August" },
        { label: "September", value: "September" },
        { label: "October", value: "October" },
        { label: "November", value: "November" },
        { label: "December", value: "December" }
    ]);
    const [projectList, setProjectList] = useState([
        {
            label: 'Gamuda Gardens', 
            value: 'gardens', 
            companyID: 180,
        },
        {
            label: 'Gamuda Jadehills', 
            value: 'jadehill', 
            companyID: 190,
        },
        {
            label: 'Gamuda Cove', 
            value: 'cove', 
            companyID: 200,
        },
        {
            label: 'Gamuda 257', 
            value: 'twentyfive7', 
            companyID: 203,
        },
        {
            label: 'Gamuda Horizon', 
            value: 'horizon', 
            companyID: 210,
        },
        {
            label: 'Gamuda Bantayan', 
            value: 'bantayan', 
            companyID: 212,
        },
        {
            label: 'Gamuda Highpark', 
            value: 'highpark', 
            companyID: 213,
        }
    ]);

    const [year, setYear] = useState(moment().format('YYYY'));
    const [month, setMonth] = useState(moment().format('MMMM'));
    const [project, setProject] = useState('');

    const [dashboardRowData, setDashboardRowData] = useState([]);
    const [dashboardColumn] = useState([
        {
            headerName: 'Prospect Name',
            field: 'prospect_name',
            width: 250,
            onCellClicked: (params) => {
                if (params) {
                    window.open(`${session.origin}/prospect/${params.data.prospect_id}`,'_blank');
                }
            }
        },
        {
            headerName: 'Final Score (Out of 5)',
            field: 'final_score',
            width: 160
        },
        {
            headerName: 'Sales ID',
            field: 'salesID',
            width: 100
        },
        {
            headerName: 'Unit ID',
            field: 'unit_id',
            width: 100
        },
        {
            headerName: 'Phase ID',
            field: 'phase_id',
            width: 100
        },
        {
            headerName: 'Phase Name',
            field: 'phase_name',
            width: 200
        },
        {
            headerName: 'Lot Unit',
            field: '',
            width: 200
        },
        {
            headerName: 'SPA Date',
            field: 'spaDate',
            width: 150
        },
        {
            headerName: 'RA Name',
            field: 'user_name',
            minWidth: 250,
            flex: 1
        },
        {
            headerName: 'Eligibility',
            field: 'eligibility',
            width: 120
        },
        {
            headerName: 'Status Push to RR',
            field: '',
            width: 150
        },
        {
            headerName: 'Date/time Push to RR',
            field: 'date',
            width: 180
        },
        {
            headerName: 'Repush Button',
            width: 120,
            pinned: 'right',
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="ps-3">
                            <button type="button" className="btn btn-light shadow-sm border" disabled={Number(session.company_id) === 251 ? true : false} onClick={() => {handlePushRR(params.data)}}>PUSH</button>
                        </div>
                    );
                }
            }
        }
    ]);

    const containerStyleDashboard = useMemo(() => ({ width: "100%", height: "75vh", paddingTop: 0 }), []);
    const gridStyleDashboard = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDefDashboard = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const getDashboard = () => {
        var getProj = projectList.filter(opt => Number(opt.companyID) === Number(session.company_id));

        axios.get('ext/gl_srm/status_push_rr.php', {
            params: {
                task: 'statpushlisting',
                assessMonth: month,
                assessYear: year,
                project: Number(session.company_id) !== 251 ? getProj[0].value : project ? project : '',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                setDashboardRowData(data.record);
                setIsLoading(false);
                setIsLoading2(false);
                handleSearch();
            }else{
                setDashboardRowData([]);
                setIsLoading(false);
                setIsLoading2(false);
                handleSearch();
            }
        });
    }

    const getAllYear = () => {
        var currentYear = new Date().getFullYear();
        var yearCount = Number(currentYear) - 2024;
        var yearArr = [];

        if (Number(yearCount) > 0) {
            for (let index = 0; index <= yearCount; index++) {
                yearArr.push({label: currentYear - (index), value: currentYear - (index)});
            }

            setYearList(yearArr);
        }
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getDashboard();
            getAllYear();
        }
    });

    const handlePushRR = (dataset) => {
        var getProj = projectList.filter(opt => Number(opt.companyID) === Number(session.company_id));

        axios.get("ext/gl_srm/status_push_rr.php", {
            params: {
                task: 'pushtoRR',
                assessMonth: month,
                assessYear: year,
                project: getProj[0].value,
                uid: dataset.ra_user_id,
                salesid: dataset.salesID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Successfully push to RR'
                });
                getDashboard();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed'
                });
            }
        });
    }

    const handleSearch = () => {
        setTimeout(() => {
            gridRef.current.api.setQuickFilter(searchText);
        }, 10);
        
    }

    const handleDownload = () => {
        var params = {
            fileName : `Sales-Commisssion-Dashboard`,
            processCellCallback : function(params) {

                var res = params.value;
                // if(res && res !== undefined && res !== null && res !== '' ){
                //     res = res.replace(/<br>/g, "\r\n");
                //     res = res.replace(/<li>/g, "\r\n");
                //     res = res.replace(/<[^>]+>/g, '');
                //     res = res.replace(/&nbsp;/g, '');
                // }
                
                return res;

            }
        };

        gridRef.current.api.exportDataAsCsv(params);
    }

    return(
        <div style={{height: "calc(100vh - 56px)", overflow: "auto"}}>
            {isLoading ? <Loader/> : 
                <div className="p-4">
                    <Stack direction="horizontal" gap={2} className="mb-4">
                        <Form.Group style={{maxWidth: '180px', minWidth: '100px'}}>
                            <FormSelect
                                options={yearList}
                                valueDefault={yearList.filter(opt => Number(opt.value) === Number(year))}
                                isSearchable={true}
                                onChange={(e) => {setYear(e ? e.value : '')}}
                            />
                        </Form.Group>
                        <Form.Group style={{width: '160px'}}>
                            <FormSelect
                                options={monthList}
                                valueDefault={monthList.filter(opt => opt.value === month)}
                                isSearchable={true}
                                onChange={(e) => {setMonth(e ? e.value : '')}}
                            />
                        </Form.Group>
                        {Number(session.company_id ) === 251 && <Form.Group style={{width: '180px'}}>
                            <FormSelect
                                options={projectList}
                                placeholder="All Projects"
                                isSearchable={true}
                                isClearable={true}
                                onChange={(e) => {setProject(e ? e.value : '')}}
                            />
                        </Form.Group>}
                        <Form.Group style={{width: '200px'}} className="ms-auto">
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Form.Group>
                        <button className="btn op-button op-primary-color text-light" onClick={() => {setIsLoading2(true); getDashboard();}}>Apply</button>
                        <button type="button" className="btn btn-light shadow-sm border rounded" onClick={handleDownload}>
                            <FontAwesomeIcon icon={faDownload} size="lg"/>
                        </button>
                    </Stack>
                    <div>
                        {isLoading2 ? <Loader/>:
                        <div style={containerStyleDashboard}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleDashboard }}>
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={dashboardColumn}
                                    rowData={dashboardRowData}
                                    defaultColDef={reportColDefDashboard}
                                    rowHeight={50}
                                    // pagination={false}
                                    // paginationPageSize={30}
                                    paginationPageSizeSelector={false}
                                />
                            </div>
                        </div>}
                    </div>
                </div>
            }
        </div>
    );
}

export default CommissionDashboard;