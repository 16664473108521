import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Col, Container, Row, Stack, Form, Modal, Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FormSelect, FormSelectCreatable, FormCustomField, FormSelectV2 } from "../includes/FormCustom";
import * as formik from "formik";
import * as yup from "yup";
import axios from "../api/axios";
import { faSearch } from "@fortawesome/pro-duotone-svg-icons";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { MobilePrefix } from "../lib/js/Function";
import { debounce } from "lodash";
import { faPencil, faTrash, faTrashAlt } from "@fortawesome/pro-light-svg-icons";

const Prospect = () => {
  const { Formik, useFormikContext } = formik;
  const initialized = useRef(false);
  const [user, setUser] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isAddProspect, setisAddProspect] = useState(false);
  const [isEditProspect, setIsEditProspect] = useState(false);
  const [isFindDesignation, setIsFindDesignation] = useState(false);
  const [isfindOrg, setIsFindOrg] = useState(false);
  const [isContact, setisContact] = useState(true);
  const [userSearch, setUserSearch] = useState("");
  const [contactSearch, setContactSearch] = useState("");
  const [isContactSearch, setisContactSearch] = useState(0);
  const { session } = useAuth();
  const [trigger, setTrigger] = useState(0);
  const [prosCF, setProsCF] = useState([]);
  const [prosCfInitVal, setProsCfInitVal] = useState({});
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const { t } = useTranslation();
  const [editProspectData, setEditProspectData] = useState({});
  const [gridCount, setGridCount] = useState(0);
  const [userSearchValue, setUserSearchValue] = useState({ label: "All User", value: "" });
  const [rowData, setRowData] = useState([]);
  const gridInit = useRef(false);
  const [initLoad, setInitLoad] = useState(false);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [editBtnLoading, setEditBtnLoading] = useState(false);
  const [gridInfo, setGridInfo] = useState({
    currentPage: 1,
    totalPage: 1,
    totalRecord: 0,
    pageSize: 100,
  });

  // SCHEMA ------------------------------------------------------

  const addProspectContactSchema = yup.object().shape({
    contact_prefix: yup.string().test("contact_prefix", "Prefix is required", async function (value) {
      const { contact } = this.parent || {};
      try {
        if (contact && !value) {
          return this.createError({ message: "Prefix is required" });
        }
        return true;
      } catch (error) {
        return false;
      }
    }),
    contact: yup.string().test("contact", "Contact number format already exist", async function (value) {
      const { contact_prefix, contact_prefix_region } = this.parent || {};
      try {
        if (value && !contact_prefix) {
          return this.createError({ message: "Invalid contact number" });
        }

        if (value && contact_prefix_region) {
          const phoneNumber = parsePhoneNumberFromString(value, contact_prefix_region);
          if (!phoneNumber || !phoneNumber.isValid()) {
            return this.createError({ message: "Invalid contact number" });
          }
        }

        if (value) {
          const result = await debounceDuplicateContact(value);
          if (result === false) {
            return this.createError({ message: "Contact number already exist" });
          }
        }

        return true;
      } catch (error) {
        return false;
      }
    }),
  });

  const addProspectEmailSchema = yup.object().shape({
    email: yup.string().email("Invalid email address"),
  });

  const addProspectSchema = yup.object().shape({
    prospect_name: yup.string().required(t("ProspectList_required_field")),
    contact: yup.array().of(addProspectContactSchema),
    email: yup.array().of(addProspectEmailSchema),
  });

  const editProspectContactSchema = yup.object().shape({
    contact_prefix: yup.string().test("contact_prefix", "Prefix is required", async function (value) {
      const { contact } = this.parent || {};
      try {
        if (contact && !value) {
          return this.createError({ message: "Prefix is required" });
        }
        return true;
      } catch (error) {
        return false;
      }
    }),
    contact: yup.string().test("contact", "Contact number format already exist", async function (value) {
      const { index, contact_prefix, contact_prefix_region } = this.parent || {};
      try {
        if (value && !contact_prefix) {
          return this.createError({ message: "Invalid contact number" });
        }

        if (value && value !== editProspectData[`contact${index}`] && contact_prefix_region) {
          const phoneNumber = parsePhoneNumberFromString(value, contact_prefix_region);
          if (!phoneNumber || !phoneNumber.isValid()) {
            return this.createError({ message: "Invalid contact number" });
          }
        }

        if (value && value !== editProspectData[`contact${index}`]) {
          const result = await debounceDuplicateContact2(value, index);
          if (result === false) {
            return this.createError({ message: "Contact number already exist" });
          }
        }

        return true;
      } catch (error) {
        return false;
      }
    }),
  });

  const editProspectEmailSchema = yup.object().shape({
    email: yup.string().email("Invalid email address"),
  });

  const editProspectSchema = yup.object().shape({
    prospect_name: yup.string().required(t("DealDetails_error_message")),
    owner: yup.object().shape({ value: yup.string().required(t("DealDetails_error_message")) }),
    contact: yup.array().of(editProspectContactSchema),
    email: yup.array().of(editProspectEmailSchema),
  });

  // GET FUNCTION --------------------------------------------------------------

  const getProspect = useCallback(async (page, userSearch, contactSearch, firstLoad = false) => {
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: "searchProspectsV2",
          company: session.company_id,
          uid: session.user_id,
          m: "yes",
          page: page,
          size: 100,
          q: contactSearch,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          user: userSearch,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setGridCount(Number(data.totalCount));
        setRowData(data.record);
        if (firstLoad) {
          gridInit.current = true;
          setGridInfo({
            currentPage: 1,
            totalPage: Math.ceil(Number(data.totalCount) / 100),
            totalRecord: Number(data.totalCount),
            pageSize: 100,
          });
        }
      } else {
        setGridCount(0);
        setRowData([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setisContact(true);
      setInitLoad(true);
    }
  }, []);

  const getDuplicateContact = async (contact) => {
    let isDuplicateExists;
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: "13",
          company: session.company_id,
          // people: deal.prospect_id,
          user: session.user_id,
          mobile: contact,
        },
      });

      const data = response.data;
      isDuplicateExists = Number(data.status) === 0;
    } catch (error) {
      isDuplicateExists = false;
    }

    return isDuplicateExists;
  };

  const getDuplicateContact2 = async (contact, index) => {
    let isDuplicateExists;

    const contactFields = [`contact1`, `contact2`, `contact3`, `contact4`];
    const fieldToCheck = contactFields[index - 1];

    if (index >= 1 && index <= 3 && editProspectData[fieldToCheck] === contact) {
      return true;
    }

    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: 13,
          task: "13",
          company: session.company_id,
          // people: deal.prospect_id,
          user: session.user_id,
          mobile: contact,
        },
      });

      const data = response.data;
      isDuplicateExists = Number(data.status) === 0;
    } catch (error) {
      isDuplicateExists = false;
    }

    return isDuplicateExists;
  };

  const debounceDuplicateContact = debounce(getDuplicateContact, 1000);
  const debounceDuplicateContact2 = debounce(getDuplicateContact2, 1000);

  const getUser = async () => {
    try {
      const response = await axios.get("ws/ws_user.php", {
        params: {
          task: "4a",
          ctoken: session.company_token,
          utoken: session.user_ac_token,
        },
      });
      var data = response.data;
      if (data.status === 0) {
        const userRecord = data.record.map((record) => ({ label: record.user_name, value: record.user_id }));
        const userList = data.record.map((record) => ({ label: record.user_name, value: record.user_id }));
        userList.unshift({ label: "All User", value: "" });
        setUser(userRecord);
        setUserList(userList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getExistingDesignation = (inputValue, callback) => {
    setIsFindDesignation(false);
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: 11,
          company: session.company_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var options = data.record
            .filter((record) => {
              return Number(record.designation_id) > 0;
            })
            .map((record) => ({
              label: record.designation_title,
              value: record.designation_id,
              customLabel: "",
            }));

          var newOptions = data.record.find((record) => Number(record.designation_id) === 0);
          if (newOptions) {
            options.unshift({
              label: inputValue,
              value: 0,
              customLabel: newOptions.msg,
              contact1: "",
              contact_id: "",
            });
          }

          callback(options);
          setIsFindDesignation(true);
        } else {
          options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindDesignation(true);
        }
      });
  };

  const getExistOrg = (inputValue, callback) => {
    setIsFindOrg(false);
    axios
      .get("ws/ws_organization.php", {
        params: {
          task: 4,
          company: session.company_id,
          q: inputValue,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;
        if (Number(data.status) === 0) {
          var options = data.record
            .filter((record) => {
              return Number(record.organization_id) > 0;
            })
            .map((record) => ({
              label: record.organization_title,
              value: record.organization_id,
              customLabel: "",
            }));

          var newOptions = data.record.find((record) => Number(record.organization_id) === 0);
          if (newOptions) {
            options.unshift({
              label: inputValue,
              value: 0,
              customLabel: newOptions.msg,
              contact1: "",
              contact_id: "",
            });
          }

          callback(options);
          setIsFindOrg(true);
        } else {
          options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindOrg(true);
        }
      });
  };

  const getCountry = async () => {
    try {
      const response = await axios.get("ws/ws_listplace.php", {
        params: {
          task: "listCountry",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      const options = data.record.map((record) => ({
        label: record.country_title,
        value: record.country_id,
      }));
      setCountryData(options);
    } catch (error) {
      console.error(error);
    }
  };

  const getState = async () => {
    try {
      const response = await axios.get("ws/ws_listplace.php", {
        params: {
          task: "listState",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      const options = data.record.map((record) => ({
        label: record.state_title,
        value: record.state_id,
        country: record.country_id,
      }));
      setStateData(options);
    } catch (error) {
      console.error(error);
    }
  };

  const getCity = async () => {
    try {
      const response = await axios.get("ws/ws_listplace.php", {
        params: {
          task: "listCity",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      const options = data.record.map((record) => ({
        label: record.city_title,
        value: record.city_id,
        state: record.state_id,
      }));

      setCityData(options);
    } catch (error) {
      console.error(error);
    }
  };

  const getProspectCFV2 = async (prospectEditData) => {
    setEditProspectData(prospectEditData);
    setEditBtnLoading(false);
    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: "getProspectDetails",
          prospect: prospectEditData.prospect_id,
          pipe: "",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      const findContactPrefix = (contact) => {
        contact = contact.replace(/\s+/g, "");
        contact = contact.replace("+", "");

        var prefix2 = contact.substring(0, 2);
        var prefix3 = contact.substring(0, 3);
        var prefix4 = contact.substring(0, 4);

        const findPrefix2 = MobilePrefix.find((prefix) => prefix.value === prefix2);
        const findPrefix3 = MobilePrefix.find((prefix) => prefix.value === prefix3);
        const findPrefix4 = MobilePrefix.find((prefix) => prefix.value === prefix4);

        if (findPrefix2) {
          return findPrefix2;
        } else if (findPrefix3) {
          return findPrefix3;
        } else if (findPrefix4) {
          return findPrefix4;
        }

        return { label: "", value: "", regionCode: "" };
      };

      const contacts = [];
      const emails = [];
      let customFields = [];

      if (Number(data.status) === 0) {
        const contactNumbers = [data.record.contact1, data.record.contact2, data.record.contact3];
        const contactCountryCode = [data.record.contact1_prefix, data.record.contact2_prefix, data.record.contact3_prefix];
        contactNumbers.forEach((contact, index) => {
          if (index === 0) {
            const contactPrefix = contactCountryCode[0] ? MobilePrefix.find((record) => record.value === contactCountryCode[0]) : findContactPrefix(contact);
            contacts.push({
              contact_prefix: contactPrefix.value,
              contact_prefix_label: contactPrefix.label,
              contact_prefix_region: contactPrefix.regionCode,
              contact: contact ? contact : Number(data.record.contact_count) > 0 && data.record.contacts[0]?.contact_num ? data.record.contacts[0].contact_num : "",
              main: true,
              index: 1,
            });
          } else {
            if (contact) {
              const contactPrefix = contactCountryCode[index] ? MobilePrefix.find((record) => record.value === contactCountryCode[index]) : findContactPrefix(contact);
              contacts.push({
                contact_prefix: contactPrefix,
                contact_prefix_label: contactPrefix.label,
                contact_prefix_region: contactPrefix.regionCode,
                contact: contact,
                main: false,
                index: index + 1,
              });
            }
          }
        });

        const emailAddress = [data.record.email1, data.record.email2];
        emailAddress.forEach((email, index) => {
          if (index === 0) {
            emails.push({ email: email ? email : Number(data.record.email_count) > 0 && data.record.emails[0]?.email_title ? data.record.emails[0].email_title : "" });
          } else {
            if (email) {
              emails.push({ email: email });
            }
          }
        });

        if (Number(data.record.custom_field) > 0) {
          customFields = data.record.custom_field_data.map((record) => ({
            cf_id: record.cf_id,
            name: record.cf_id,
            fd_values: record.ctf_title === "Checkbox" ? (record.fd_value ? record.fd_value.split(",") : []) : record.fd_value ? record.fd_value : "",
            cf_label: record.cf_label,
            required: record,
          }));
        }

        setProsCF(Number(data.record.custom_field) > 0 ? data.record.custom_field_data : []);
        setProsCfInitVal({
          prospect_id: data.record.prospect_id,
          prospect_name: data.record.prospect_name,
          designation: { label: data.record.designation_title, value: data.record.designation_id },
          organization: { label: data.record.organization_title, value: data.record.organization_id },
          owner: { label: prospectEditData.OWNER_NAME, value: prospectEditData.owner_id },
          contact: contacts,
          email: emails,
          address1: data.record.address_line1 === "" ? data.record.prospect_address : data.record.address_line1,
          address2: data.record.address_line2,
          address3: data.record.address_line3,
          postcode: data.record.postal_code,
          country: Number(data.record.country_id) > 0 ? countryData.find((country) => Number(country.value) === Number(data.record.country_id)) : "",
          state: Number(data.record.state_id) > 0 ? stateData.find((state) => Number(state.value) === Number(data.record.state_id)) : "",
          city: Number(data.record.city_id) > 0 ? cityData.find((city) => Number(city.value) === Number(data.record.city_id)) : "",
          cf_person: customFields,
          state_show: true,
          city_show: true,
          cf_person: customFields,
        });
      } else {
        const contactNumbers = [prospectEditData.contact1, prospectEditData.contact2, prospectEditData.contact3];
        const contactCountryCode = [prospectEditData.contact1_prefix, prospectEditData.contact2_prefix, prospectEditData.contact3_prefix];
        contactNumbers.forEach((contact, index) => {
          if (index === 0) {
            const contactPrefix = contactCountryCode[0] ? MobilePrefix.find((record) => record.value === contactCountryCode[0]) : findContactPrefix(contact);
            contacts.push({
              contact_prefix: contactPrefix.value,
              contact_prefix_label: contactPrefix.label,
              contact_prefix_region: contactPrefix.regionCode,
              contact: contact ? contact : Number(prospectEditData.contact_count) > 0 && prospectEditData.contacts[0] ? prospectEditData.contacts[0].contact_num : "",
              main: true,
              index: 1,
            });
          } else {
            if (contact) {
              const contactPrefix = contactCountryCode[index] ? MobilePrefix.find((record) => record.value === contactCountryCode[index]) : findContactPrefix(contact);
              contacts.push({
                contact_prefix: contactPrefix,
                contact_prefix_label: contactPrefix.label,
                contact_prefix_region: contactPrefix.regionCode,
                contact: contact,
                main: false,
                index: index + 1,
              });
            }
          }
        });

        const emailAddress = [prospectEditData.email1, prospectEditData.email2];
        emailAddress.forEach((email, index) => {
          if (index === 0) {
            emails.push({ email: email ? email : Number(prospectEditData.email_count) > 0 && prospectEditData.emails[0] ? prospectEditData.emails[0].email_title : "" });
          } else {
            if (email) {
              emails.push({ email: email });
            }
          }
        });

        setProsCF([]);
        setProsCfInitVal({
          prospect_id: prospectEditData.prospect_id,
          prospect_name: prospectEditData.prospect_name,
          designation: { label: prospectEditData.designation_title, value: prospectEditData.designation_id },
          organization: { label: prospectEditData.organization_title, value: prospectEditData.organization_id },
          owner: { label: prospectEditData.OWNER_NAME, value: prospectEditData.owner_id },
          contact: contacts,
          email: emails,
          address1: prospectEditData.address_line1 === "" ? prospectEditData.prospect_address : prospectEditData.address_line1,
          address2: prospectEditData.address_line2,
          address3: prospectEditData.address_line3,
          postcode: prospectEditData.postal_code,
          country: Number(prospectEditData.country_id) > 0 ? countryData.find((country) => Number(country.value) === Number(prospectEditData.country_id)) : "",
          state: Number(prospectEditData.state_id) > 0 ? stateData.find((state) => Number(state.value) === Number(prospectEditData.state_id)) : "",
          city: Number(prospectEditData.city_id) > 0 ? cityData.find((city) => Number(city.value) === Number(prospectEditData.city_id)) : "",
          state_show: true,
          city_show: true,
          cf_person: [],
        });
      }

      setTimeout(() => {
        setIsEditProspect(true);
      }, 100);
    } catch (error) {
      console.error(error);
    }
  };

  // HANDLE FUNCTION ---------------------------------------------

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setisContactSearch(Math.random());
    }
  };

  // SEND FUNCTION -----------------------------------------------

  const sendAddProspectV2 = async (values) => {
    const contacts = values.contact.map((contact) => ({ contact: contact.contact, contactPrefix: contact.contact_prefix }));
    const contact1 = contacts[0] ? contacts[0] : { contact: "", contactPrefix: "" };
    const contact2 = contacts[1] ? contacts[1] : { contact: "", contactPrefix: "" };
    const contact3 = contacts[2] ? contacts[2] : { contact: "", contactPrefix: "" };

    const emails = values.email.map((email) => email.email);
    const email1 = emails[0] ? emails[0] : "";
    const email2 = emails[1] ? emails[1] : "";

    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: "addNewProspectV2",
          name: values.prospect_name,
          des: values.designation.value,
          des_title: values.designation.label,
          contact: contact1.contact,
          contact2: contact2.contact,
          contact3: contact3.contact,
          email: email1,
          email2: email2,
          organization: Number(session.company_type_id) !== 2 ? (values.company.value ? values.company.value : "") : "",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        if (Number(data.record) > 0) {
          await sendAddProspectExtra(values, data.record, [contact1.contactPrefix, contact2.contactPrefix, contact3.contactPrefix], [contact1.contact, contact2.contact, contact3.contact], [email1, email2]);
        }
        Swal.fire({
          icon: "success",
          title: t("ProspectList_alert_success_title"),
          text: t("ProspectList_alert_success_text_add"),
          timer: "1500",
        }).then((result) => {
          setisAddProspect(false);
          setTrigger(trigger + 1);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("ProspectList_alert_failed_title"),
          text: t("ProspectList_alert_failed_text"),
          timer: "1500",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendAddProspectExtra = async (values, prospect_id, contacts_prefix, contacts, emails) => {
    try {
      const response = await axios.get("ws/ws_edit_prospect_details.php", {
        params: {
          task: "addNewProspectExtra",
          task: "updateContacts",
          prospect_id: prospect_id,
          contact1_prefix: contacts_prefix[0],
          contact1: contacts[0],
          contact2_prefix: contacts_prefix[1],
          contact2: contacts[1],
          contact3_prefix: contacts_prefix[2],
          contact3: contacts[2],
          email1: emails[0],
          email2: emails[1],
          address1: values.address1,
          address2: values.address2,
          address3: values.address3,
          state_id: values.state ? values.state.value : "",
          city_id: values.city ? values.city.value : "",
          postcode: values.postcode,
          country_id: values.country ? values.country.value : "",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditProspectV2 = async (values) => {
    var customField = values.cf_person;
    var cf_id = customField.map((record) => record.name);
    var cf_label = customField.map((record) => record.cf_label);
    var prospect_id = Array(customField.length).fill(values.prospect_id);

    var fd_values = customField
      .map((record) => {
        if (Array.isArray(record.fd_values)) {
          var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
          return filteredValues.length > 0 ? filteredValues.join("|") : null;
        } else {
          return record.fd_values;
        }
      })
      .filter((value) => value !== null);

    await sendEditProspectData(values);
    if (customField.length > 0) {
      await sendEditProspectCF(values, cf_id, cf_label, prospect_id, fd_values);
    }

    const response_prospect_extra = await sendEditProspectExtra(values);
    const status = response_prospect_extra ? 0 : 1;

    if (status === 0) {
      Swal.fire({
        icon: "success",
        text: t("DealDetails_the_prospect_has_been_updated_successfully"),
      }).then((result) => {
        setIsEditProspect(false);
        setTrigger(trigger + 1);
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "Prospect updated successfuly but we are unable to update prospect contact details, we are detecting duplicate contact details",
      }).then((result) => {
        setIsEditProspect(false);
        setTrigger(trigger + 1);
      });
    }
  };

  const sendConnectProspectOrg = async (organization) => {
    if (organization.value && Number(organization.value) > 0) {
      return organization.value;
    }

    try {
      const response = await axios.get("ws/ws_organization.php", {
        params: {
          task: "1a",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          title: organization.label,
          address: "",
          weburl: "",
          industry: "",
          country: "",
          contact: "",
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return data.record;
      } else if (Number(data.status) === 2) {
        const organization_id = data.error.replace("This name already existed in this company which belong to organization id ", "");
        return organization_id;
      } else {
        return 0;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditProspectData = async (values) => {
    try {
      const organization_id = Number(session.company_type_id) !== 2 ? await sendConnectProspectOrg(values.organization) : "";
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: "2",
          des_title: values.designation.label,
          des: values.designation.value,
          organization: Number(session.company_type_id) !== 2 ? organization_id : "",
          prospect_address: "",
          prospect: values.prospect_id,
          name: values.prospect_name,
          owner_id: values.owner.value,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditProspectExtra = async (values) => {
    const contacts = values.contact.map((contact) => ({ contact: contact.contact, contactPrefix: contact.contact_prefix }));
    const contact1 = contacts[0] ? contacts[0] : { contact: "", contactPrefix: "" };
    const contact2 = contacts[1] ? contacts[1] : { contact: "", contactPrefix: "" };
    const contact3 = contacts[2] ? contacts[2] : { contact: "", contactPrefix: "" };

    const emails = values.email.map((email) => email.email);
    const email1 = emails[0] || "";
    const email2 = emails[1] || "";

    try {
      const response = await axios.get("ws/ws_edit_prospect_details.php", {
        params: {
          task: "updateContacts",
          prospect_id: values.prospect_id,
          contact1_prefix: contact1.contactPrefix,
          contact1: contact1.contact,
          contact1_old: "",
          contact2_prefix: contact2.contactPrefix,
          contact2: contact2.contact,
          contact3_prefix: contact3.contactPrefix,
          contact3: contact3.contact,
          email1: email1,
          email2: email2,
          address1: values.address1,
          address2: values.address2,
          address3: values.address3,
          state_id: values.state && values.state?.value ? values.state.value : "",
          city_id: values.city && values.city?.value ? values.city.value : "",
          postcode: values.postcode,
          country_id: values.country && values.country?.value ? values.country.value : "",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditProspectCF = async (values, cf_id, cf_label, prospect_id, fd_values) => {
    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: "updateOrAddCF",
          record: prospect_id.toString(),
          hq_prospect_id: Number(editProspectData.hq_prospect_id) > 0 ? editProspectData.hq_prospect_id : "",
          cf_label: cf_label.toString().replaceAll(" ", "_"),
          cf: cf_id.toString(),
          value: fd_values.toString(),
          user_id: session.user_id,
          company_id: session.company_id,
          secret_key: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendDeleteProspect = (data) => {
    Swal.fire({
      icon: "warning",
      title: t("ProspectList_alert_warning_2_title"),
      text: t("ProspectList_alert_warning_2_text"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ProspectList_alert_warning_2_confirm"),
      cancelButtonText: t("ProspectList_alert_warning_cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_prospect.php", {
            params: {
              task: "10",
              prospect: data.prospect_id,
              forceDelete: "",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          })
          .then((res) => {
            let data2 = res.data;

            if (data2.status === 0) {
              Swal.fire({
                title: t("ProspectList_alert_success_title"),
                text: t("ProspectList_alert_success_text_delete"),
                icon: "success",
                timer: 1500,
              }).then((result) => {
                setTrigger(trigger + 1);
              });
            } else {
              Swal.fire({
                title: t("ProspectList_alert_warning_3_title"),
                text: `${t("ProspectList_alert_warning_3_text_1")} ${t("ProspectList_alert_warning_3_text_2")} ${data.prospect_name}, ${t("ProspectList_alert_warning_3_text_3")}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t("ProspectList_alert_warning_3_confirm"),
                cancelButtonText: t("ProspectList_alert_warning_cancel"),
              }).then((result) => {
                if (result.isConfirmed) {
                  sendForceDelete(data, "yes");
                }
              });
            }
          });
      }
    });
  };

  const sendForceDelete = (data, force) => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "10",
          prospect: data.prospect_id,
          forceDelete: force,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          Swal.fire({
            title: t("ProspectList_alert_success_title"),
            text: t("ProspectList_alert_success_text_delete2"),
            icon: "success",
            timer: 1500,
          }).then((result) => {
            setTrigger(trigger + 1);
          });
        } else {
          Swal.fire({
            title: t("ProspectList_alert_warning_4_title"),
            text: t("ProspectList_alert_warning_4_text"),
            icon: "warning",
          });
        }
      });
  };

  // SET FUNCTION -----------------------------------------------

  const setPhoneNumber = (prefix, contact) => {
    if (contact === "" || contact === null || contact === undefined || Number(contact) === 0) {
      return "-";
    } else if (prefix === "" || prefix === null || prefix === undefined || Number(prefix) === 0) {
      return contact;
    } else {
      let realPrefix = prefix.replace("+", "");
      var prefix2 = realPrefix.substring(0, 2);
      var prefix3 = realPrefix.substring(0, 3);
      var prefix4 = realPrefix.substring(0, 4);

      if (MobilePrefix.find((prefix) => prefix.value === prefix2)) {
        realPrefix = prefix2;
      } else if (MobilePrefix.find((prefix) => prefix.value === prefix3)) {
        realPrefix = prefix3;
      } else if (MobilePrefix.find((prefix) => prefix.value === prefix4)) {
        realPrefix = prefix4;
      } else {
        return `+${realPrefix}${contact.replace(/\s+/g, "")}`;
      }

      const contactNumber = `+${realPrefix}${contact.replace(/\s+/g, "")}`;
      const phoneNumber = parsePhoneNumberFromString(contactNumber);
      if (phoneNumber && phoneNumber.isValid()) {
        return phoneNumber.formatInternational();
      }

      return contactNumber;
    }
  };

  const setEditProspectContactError = (errors, touched, index) => {
    let error = "";

    if (errors.contact && errors.contact[index] && errors.contact[index].contact_prefix && touched.contact && touched.contact[index] && touched.contact[index].contact_prefix) {
      error = errors.contact[index].contact_prefix;
    }

    if (errors.contact && errors.contact[index] && errors.contact[index].contact && touched.contact && touched.contact[index] && touched.contact[index].contact) {
      if (error !== "") {
        error += " & ";
      }
      error += errors.contact[index].contact;
    }

    return error ? <div className="op-error-message mb-2">{error}</div> : null;
  };

  const setProspectAddress = (record) => {
    let address = "";
    let comma = false;

    if (record.address_line1) {
      address += record.address_line1;

      if (record.address_line2) {
        address += ", ";
        address += record.address_line2;
      }

      if (record.address_line3) {
        address += ", ";
        address += record.address_line3;
      }

      if (record.postal_code) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + record.postal_code;
      }

      if (Number(record.city_id) > 0) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + (record.city_title ? record.city_title : "");
      }

      if (Number(record.state_id) > 0) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + (record.state_title ? record.state_title : "");
      }

      if (Number(record.country_id) > 0) {
        if (comma === false) {
          address += ",";
          comma = true;
        }

        address += " " + (record.country_title ? record.country_title : "");
      }
    } else {
      address += record.prospect_address;
    }

    return address ? address.toUpperCase() : "";
  };

  const ScrollToFirstError = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
      if (isSubmitting && !isValidating) {
        let errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
          const selector = `[name="${errorKeys[0]}"]`;
          const errorElement = document.querySelector(selector);
          if (errorElement) {
            errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
    }, [errors, isSubmitting, isValidating]);

    return null;
  };

  const ScrollToFirstError2 = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
      if (isSubmitting && !isValidating) {
        const findFirstError = (obj, keyPath = "") => {
          if (obj === undefined || obj === null) {
            return "";
          }
          return Object.keys(obj).reduce((acc, key) => {
            if (acc) return acc;
            const isArrayIndex = !isNaN(key);
            const newKeyPath = keyPath ? `${keyPath}${isArrayIndex ? `[${key}]` : `.${key}`}` : key;
            if (typeof obj[key] === "string") return newKeyPath;
            return findFirstError(obj[key], newKeyPath);
          }, "");
        };
        const firstErrorKey = findFirstError(errors);
        const errorElement = document.querySelector(`[name="${firstErrorKey}"]`);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }, [errors, isSubmitting, isValidating]);

    return null;
  };

  // RENDER FUNCTION -----------------------------------------------------------

  const RenderLoadingOverlay = `
    <span class="ag-overlay-no-rows-center">
      <div class="rounded-circle border shadow d-flex justify-content-center align-items-center" style="height: 30px; width: 30px;">
        <div class="spinner-border" role="status" style="width: 1rem; height: 1rem; border-width: 2px;">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </span>
  `;

  // GRID SETUP --------------------------------------------------

  const gridRef = useRef(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: isContact && gridCount > 0 ? "75vh" : "0vh", paddingTop: 20 }), [isContact, gridCount]); // eslint-disable-line react-hooks/exhaustive-deps
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerName: t("ProspectList_headername_1"),
      field: "",
      minWidth: 300,
      cellRenderer: (params) => {
        if (params.data !== undefined) {
          var initials = params.data.prospect_name.split("")[0];
          return (
            <div className="d-flex align-items-center">
              <div className="avatar me-2">
                <div className="avatar-img" style={{ width: 35, height: 35, backgroundColor: "#eee" }}>
                  <div className="avatar-txt text-uppercase">{initials}</div>
                </div>
              </div>
              <span>{params.data.prospect_name}</span>
            </div>
          );

          // return params.data.prospect_name;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
      onCellClicked: function (params) {
        if (params.data) {
          window.open(session.origin + "/prospect/" + params.data.prospect_id, "_blank");
        }
      },
    },
    {
      headerName: t("ProspectList_headername_2"),
      field: "",
      minWidth: 200,
      cellRenderer: (params) => {
        if (params.data) {
          return (
            <div className="d-flex">
              {(params.data.contact1 || (Number(params.data.contact_count) > 0 && params.data.contacts[0])) && (
                <div className="border rounded me-1" style={{ padding: "8px 10px", lineHeight: 1 }}>
                  {setPhoneNumber(params.data.contact1_prefix ? params.data.contact1_prefix : "", params.data.contact1 ? params.data.contact1 : Number(params.data.contact_count) > 0 && params.data.contacts[0] ? params.data.contacts[0].contact_num : "")}
                </div>
              )}
              {params.data.contact2 && (
                <div className="border rounded me-1" style={{ padding: "8px 10px", lineHeight: 1 }}>
                  {setPhoneNumber(params.data.contact2_prefix ? params.data.contact2_prefix : "", params.data.contact2 ? params.data.contact2 : "")}
                </div>
              )}
              {params.data.contact3 && (
                <div className="border rounded me-1" style={{ padding: "8px 10px", lineHeight: 1 }}>
                  {setPhoneNumber(params.data.contact3_prefix ? params.data.contact3_prefix : "", params.data.contact3 ? params.data.contact3 : "")}
                </div>
              )}
              {/* {params.data.contact4 && <div className="border rounded me-1" style={{ padding: "8px 10px", lineHeight: 1 }}>
                  {params.data.contact4}
                </div>} */}
            </div>
          );
        }
      },
    },
    {
      headerName: t("ProspectList_headername_3"),
      field: "",
      minWidth: 200,
      cellRenderer: (params) => {
        // if (params.data && params.data.email_count > 0) {
        //   var emails = params.data.emails.map((record) => record.email_title);
        //   return (
        //     <div className="d-flex">
        //       {emails.map((record, index) => (
        //         <div className="border rounded me-1" key={index} style={{ padding: "8px 10px", lineHeight: 1 }}>
        //           {record}
        //         </div>
        //       ))}
        //     </div>
        //   );
        // } else if (params.data && params.data.email_count === 0) {
        //   if (params.data.email1 === "") {
        //     return "";
        //   } else {
        //     return (
        //       <div className="border rounded me-1" style={{ padding: "8px 10px", lineHeight: 1 }}>
        //         {params.data.email1}
        //       </div>
        //     );
        //   }
        // }

        if (params.data) {
          return (
            <div className="d-flex">
              {params.data.email1 && (
                <div className="border rounded me-1" style={{ padding: "8px 10px", lineHeight: 1 }}>
                  {params.data.email1}
                </div>
              )}
              {params.data.email2 && (
                <div className="border rounded me-1" style={{ padding: "8px 10px", lineHeight: 1 }}>
                  {params.data.email2}
                </div>
              )}
            </div>
          );
        }
      },
    },
    {
      headerName: t("ProspectList_headername_4"),
      field: "organization_title",
      minWidth: 200,
      hide: Number(session.company_type_id) !== 2 ? false : true,
      onCellClicked: function (params) {
        if (params.data) {
          if (params.data.organization_id && Number(params.data.organization_id) > 0) {
            window.open(`${session.origin}/organizations/${params.data.organization_id}`, "_blank");
          }
        }
      },
    },
    { headerName: t("ProspectList_headername_5"), field: "prospect_address", minWidth: 200, cellRenderer: (params) => (params.data ? setProspectAddress(params.data) : "") },
    { headerName: t("ProspectList_headername_6"), field: "DESIGNATION_NAME", minWidth: 200 },
    { headerName: t("ProspectList_headername_7"), field: "OWNER_NAME", minWidth: 250 },
    {
      headerName: "",
      field: "",
      cellClass: "center",
      maxWidth: 100,
      minWidth: 100,
      pinned: "right",
      cellRenderer: (params) => {
        if (params.data) {
          return (
            <div className="d-flex justify-content-center">
              <Button variant="light" className="me-2" onClick={() => getProspectCFV2(params.data)}>
                <FontAwesomeIcon icon={faPencil} />
              </Button>
              <Button variant="light" onClick={() => sendDeleteProspect(params.data)}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          );
        }
      },
    },
  ];

  const gridColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
      suppressRowClickSelection: false,
    };
  }, []);

  const gridRowId = useCallback(function (params) {
    return params.data.prospect_id.toString();
  }, []);

  // ON GRID FUNCTION --------------------------------------------------------

  const onGridPreviousPage = () => {
    if (Number(gridInfo.currentPage) > 1) {
      setRowData([]);
      getProspect(Number(gridInfo.currentPage) - 1, userSearch, contactSearch, false);
      setGridInfo({
        ...gridInfo,
        currentPage: Number(gridInfo.currentPage) - 1,
      });
    }
  };

  const onGridNextPage = () => {
    if (Number(gridInfo.currentPage) < Number(gridInfo.totalPage)) {
      setRowData([]);
      getProspect(Number(gridInfo.currentPage) + 1, userSearch, contactSearch, false);
      setGridInfo({
        ...gridInfo,
        currentPage: Number(gridInfo.currentPage) + 1,
      });
    }
  };

  // SET FUNCTION ------------------------------------------------------------

  const setCurrentPageInfo = () => {
    const currentPageSizeStart = gridInfo.pageSize * (gridInfo.currentPage - 1) + 1;
    const currentPageSizeEnd = gridInfo.currentPage * gridInfo.pageSize > gridInfo.totalRecord ? gridInfo.totalRecord : gridInfo.currentPage * gridInfo.pageSize;
    const totalRecord = gridInfo.totalRecord;

    return `${currentPageSizeStart} to ${currentPageSizeEnd} of ${totalRecord}`;
  };

  // USEEFFECT FUNCTION ------------------------------------------

  useEffect(() => {
    const initFunction = async () => {
      if (!initialized.current) {
        try {
          await getUser(session);
          await getCountry();
          await getState();
          await getCity();

          initialized.current = true;
          setisLoading(false);
        } catch (error) {
          setisLoading(false);
        }
      }
    };

    initFunction();
  }, []);

  useEffect(() => {
    if (!gridInit.current) return;
    setRowData([]);
    getProspect(1, userSearch, contactSearch, true);
  }, [gridInit.current, userSearch, isContactSearch, session, trigger]);

  return (
    <Container fluid className="p-4">
      {isLoading ? (
        <Loader />
      ) : (
        <Row>
          <Col xxl={12}>
            <Stack direction="horizontal" gap={3}>
              <button
                type="button"
                className="btn op-button op-primary-color text-light"
                onClick={() => {
                  setAddBtnLoading(false);
                  setisAddProspect(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                {t("ProspectList_btn_add")}
              </button>
              <h6 className="ms-auto op-text-medium mt-2 ms-auto"> {gridInfo.totalRecord === 1 ? t("ProspectList_record", { count: gridInfo.totalRecord }) : gridInfo.totalRecord > 1 ? t("ProspectList_record", { count: gridInfo.totalRecord }) : t("ProspectList_record_no")}</h6>
              {Number(session.role_id) > 0 && (
                <FormSelectV2
                  options={userList}
                  placeholder={t("ProspectList_user_dropdown_placeholder")}
                  value={userSearchValue}
                  onChange={(e) => {
                    setUserSearchValue(e);
                    setUserSearch(e.value);
                  }}
                  width="250px"
                  border={false}
                  shadow={true}
                />
              )}
              <div className="d-flex">
                <Form.Control type="text" className="border-0 me-1 shadow-sm" onChange={(e) => setContactSearch(e.target.value)} onKeyDown={handleKeyPress} placeholder={"Search contact"} style={{ width: 250 }} />
                <button type="button" className="btn btn-light shadow-sm" onClick={() => setisContactSearch(Math.random())}>
                  <FontAwesomeIcon icon={faSearch} size="lg" />
                </button>
              </div>
            </Stack>
          </Col>
          <Col xxl={12}>
            {initLoad === false && (
              <div className="d-flex justify-content-center align-items-center w-100 mt-3">
                <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </div>
            )}
            <div style={containerStyle}>
              <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={gridColumn}
                  defaultColDef={gridColDef}
                  rowSelection="multiple"
                  rowHeight={50}
                  getRowId={gridRowId}
                  suppressRowClickSelection={true}
                  animateRows={true}
                  rowStyle={{ backgroundColor: "#fff" }}
                  onGridReady={() => getProspect(1, userSearch, contactSearch, true)}
                  overlayNoRowsTemplate={RenderLoadingOverlay}
                />

                {rowData.length > 0 && (
                  <div className="ag-paging-panel ag-unselectable">
                    <span className="ag-paging-row-summary-panel">
                      <span className="ag-paging-row-summary-panel-number">{setCurrentPageInfo()}</span>
                    </span>
                    <span className="ag-paging-page-summary-panel">
                      <div className="ag-button ag-paging-button cursor-pointer" onClick={onGridPreviousPage}>
                        <span className="ag-icon ag-icon-previous"></span>
                      </div>
                      <span className="ag-paging-description">
                        <span id="ag-14-start-page-number" className="ag-paging-number">
                          Page {gridInfo.currentPage} of {gridInfo.totalPage}
                        </span>
                      </span>
                      <div className="ag-button ag-paging-button cursor-pointer" onClick={onGridNextPage}>
                        <span className="ag-icon ag-icon-next"></span>
                      </div>
                    </span>
                  </div>
                )}
              </div>
            </div>
            {(!isContact || gridCount === 0) && initLoad && (
              <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 300px)" }}>
                <svg fill="#B6BBC3" height={80} width={80} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                  <path d="M80 80a64 64 0 1 1 128 0A64 64 0 1 1 80 80zm144 0A80 80 0 1 0 64 80a80 80 0 1 0 160 0zm96 64a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 176a96 96 0 1 0 0-192 96 96 0 1 0 0 192zm-58.7 48l117.3 0C443.5 368 496 420.5 496 485.3c0 5.9-4.8 10.7-10.7 10.7l-330.7 0c-5.9 0-10.7-4.8-10.7-10.7C144 420.5 196.5 368 261.3 368zm0-16C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7l330.7 0c14.7 0 26.7-11.9 26.7-26.7C512 411.7 452.3 352 378.7 352l-117.3 0zM512 16a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm0 144A80 80 0 1 0 512 0a80 80 0 1 0 0 160zm24 48c48.6 0 88 39.4 88 88c0 4.4 3.6 8 8 8s8-3.6 8-8c0-57.4-46.6-104-104-104l-92 0c1.3 5.2 2.4 10.6 3 16l89 0zm-343 0c.7-5.4 1.7-10.8 3-16l-92 0C46.6 192 0 238.6 0 296c0 4.4 3.6 8 8 8s8-3.6 8-8c0-48.6 39.4-88 88-88l89 0z" />
                </svg>
                <div className="mt-3" style={{ fontSize: 18, fontWeight: "bold" }}>
                  No matching prospect were found.
                </div>
                <div className="mt-1 text-center" style={{ fontSize: 15, maxWidth: 1000 }}>
                  Consider resetting or making adjustments to your current filter settings.
                </div>
              </div>
            )}
          </Col>
        </Row>
      )}

      <Modal show={isAddProspect} onHide={setisAddProspect} backdrop="static">
        <Formik
          validationSchema={addProspectSchema}
          onSubmit={(values) => {
            setAddBtnLoading(true);
            sendAddProspectV2(values);
          }}
          initialValues={{
            prospect_name: "",
            designation: { label: "", value: "" },
            company: { label: "", value: "" },
            address: "",
            contact: [{ contact_prefix: "", contact_prefix_label: "", contact_prefix_region: "", contact: "" }],
            email: [{ email: "" }],
            address1: "",
            address2: "",
            address3: "",
            postcode: "",
            country: "",
            state: "",
            state_show: true,
            city: "",
            city_show: true,
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFirstError />
              <ScrollToFirstError2 />
              <Modal.Header closeButton>
                <Modal.Title className="op-text-bigger">{t("ProspectList_modalHeader_add_title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_add_title_1")} *</Form.Label>
                  <Form.Control name="prospect_name" type="text" value={values.prospect_name} onChange={handleChange("prospect_name")} />
                  {errors.prospect_name && touched.prospect_name && <div className="op-error-message">{errors.prospect_name}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_add_title_2")}</Form.Label>
                  <FormSelectCreatable loadOptions={getExistingDesignation} isLoading={isFindDesignation} onChange={(info) => setFieldValue("designation", info)} />
                </Form.Group>

                {Number(session.company_type_id) !== 2 && (
                  <Form.Group className="mb-3">
                    <Form.Label>Organization</Form.Label>
                    <FormSelectCreatable loadOptions={getExistOrg} isLoading={isfindOrg} onChange={(info) => setFieldValue("organization", info)} />
                  </Form.Group>
                )}

                <Form.Group className="mb-3">
                  <Form.Label>Contact Number</Form.Label>
                  {values.contact.map((record, index) => (
                    <div className="w-100" key={index}>
                      <Stack direction="horizontal" className="mb-2" gap={2}>
                        <FormSelect
                          name={`contact[${index}].contact_prefix`}
                          options={MobilePrefix}
                          isSearchable
                          isInvalid={errors.contact && errors.contact[index] && errors.contact[index].contact_prefix && touched.contact && touched.contact[index] && touched.contact[index].contact_prefix}
                          valueDefault={{ label: record.contact_prefix_label, value: record.contact_prefix, regionCode: record.contact_prefix_region }}
                          width="80px"
                          onChange={(e) => {
                            const updatedContact = [...values.contact];
                            updatedContact[index].contact_prefix = e.value;
                            updatedContact[index].contact_prefix_label = e.label;
                            updatedContact[index].contact_prefix_region = e.regionCode;
                            setFieldValue("contact", updatedContact);
                          }}
                        />
                        <Form.Control
                          name={`contact[${index}].contact`}
                          type="text"
                          isInvalid={errors.contact && errors.contact[index] && errors.contact[index].contact && touched.contact && touched.contact[index] && touched.contact[index].contact}
                          value={record.contact}
                          onChange={(e) => {
                            const updatedContact = [...values.contact];
                            updatedContact[index].contact = e.target.value.replace(/\D/g, "");
                            setFieldValue("contact", updatedContact);
                          }}
                        />

                        {index > 0 && (
                          <Button
                            variant="light"
                            className="border"
                            onClick={() => {
                              const updatedContact = [...values.contact];
                              updatedContact.splice(index, 1);
                              updatedContact.forEach((record, index) => (record.index = index + 1));
                              setFieldValue("contact", updatedContact);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </Button>
                        )}
                      </Stack>
                      {setEditProspectContactError(errors, touched, index)}
                    </div>
                  ))}

                  {values.contact.length < 3 && (
                    <Button
                      style={{ textDecoration: "none", padding: 0 }}
                      variant="link"
                      onClick={() => {
                        setFieldValue("contact", [
                          ...values.contact,
                          {
                            contact_prefix: "",
                            contact_prefix_label: "",
                            contact_prefix_region: "",
                            contact: "",
                          },
                        ]);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} className="me-1" /> Add contact
                    </Button>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  {values.email.map((record, index) => (
                    <div className="w-100" key={index}>
                      <Stack direction="horizontal" className="mb-2" gap={2} key={index}>
                        <Form.Control
                          name={`email[${index}].email`}
                          type="email"
                          value={record.email}
                          onChange={(e) => {
                            const updatedEmail = [...values.email];
                            updatedEmail[index].email = e.target.value;
                            setFieldValue("email", updatedEmail);
                          }}
                        />

                        {index > 0 && (
                          <Button
                            variant="light"
                            className="border"
                            onClick={() => {
                              const updatedEmail = [...values.email];
                              updatedEmail.splice(index, 1);
                              setFieldValue("email", updatedEmail);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </Button>
                        )}
                      </Stack>
                      {errors.email && errors.email[index] && errors.email[index].email && touched.email && touched.email[index] && touched.email[index].email && <div className="op-error-message">{errors.email[index].email}</div>}
                    </div>
                  ))}

                  {values.email.length < 2 && (
                    <Button
                      style={{ textDecoration: "none", padding: 0 }}
                      variant="link"
                      onClick={() => {
                        setFieldValue("email", [...values.email, { email: "" }]);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} className="me-1" /> Add email
                    </Button>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Address Line</Form.Label>
                  <Form.Control name="address1" className="mb-2" type="text" value={values.address1} onChange={handleChange("address1")} />
                  <Form.Control name="address2" className="mb-2" type="text" value={values.address2} onChange={handleChange("address2")} />
                  <Form.Control name="address3" type="text" value={values.address3} onChange={handleChange("address3")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("DealDetails_postal_code")}</Form.Label>
                  <Form.Control name="postcode" type="number" value={values.postcode} onChange={handleChange("postcode")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("DealDetails_country")}</Form.Label>
                  <FormSelectV2
                    name="country_id"
                    options={countryData}
                    value={values.country}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(e) => {
                      setFieldValue("country", e.value ? e : "");
                      setFieldValue("state", "");
                      setFieldValue("city", "");
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("DealDetails_state")}</Form.Label>
                  <FormSelectV2
                    name="state_id"
                    options={values.country ? stateData.filter((record) => record.country === values.country.value) : stateData}
                    disabled={values.country === ""}
                    value={values.state}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(e) => {
                      setFieldValue("state", e.value ? e : "");
                      setFieldValue("city", "");
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("DealDetails_city")}</Form.Label>
                  <FormSelectV2
                    name="city_id"
                    disabled={values.country === "" || values.state === ""}
                    options={values.state ? cityData.filter((record) => record.state === values.state.value) : cityData}
                    value={values.city}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(e) => setFieldValue("city", e.value ? e : "")}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={addBtnLoading}>
                  {addBtnLoading ? (
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    t("ProspectList_modalFooter_submit")
                  )}
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isEditProspect} onHide={setIsEditProspect} backdrop="static" size="lg">
        <Formik
          validationSchema={editProspectSchema}
          onSubmit={(values) => {
            setEditBtnLoading(true);
            sendEditProspectV2(values);
          }}
          initialValues={prosCfInitVal}
        >
          {({ handleSubmit, handleChange, setFieldValue, setValues, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFirstError />
              <ScrollToFirstError2 />
              <Modal.Header closeButton>
                <Modal.Title className="op-text-bigger">{t("ProspectList_modalHeader_edit_title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectList_modalBody_edit_title_1")} *</Form.Label>
                      <Form.Control type="text" name="prospect_name" isInvalid={errors.prospect_name && touched.prospect_name} value={values.prospect_name} onChange={handleChange("prospect_name")} />
                      {errors.prospect_name && touched.prospect_name && <div className="op-error-message">{errors.prospect_name}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectList_modalBody_edit_title_2")}</Form.Label>
                      <FormSelectCreatable
                        name="designation_title"
                        loadOptions={getExistingDesignation}
                        isLoading={isFindDesignation}
                        withValue={values.designation.value && Number(values.designation.value) > 0}
                        value={values.designation.label}
                        width="100%"
                        onChange={(e) => setFieldValue("designation", e)}
                      />
                    </Form.Group>

                    {Number(session.company_type_id) !== 2 && (
                      <Form.Group className="mb-3">
                        <Form.Label>Organization</Form.Label>
                        <FormSelectCreatable name="designation_title" loadOptions={getExistOrg} isLoading={isfindOrg} withValue={values.organization.value && Number(values.organization.value) > 0} value={values.organization.label} width="100%" onChange={(e) => setFieldValue("organization", e)} />
                      </Form.Group>
                    )}

                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectList_modalBody_edit_title_3")} *</Form.Label>
                      <FormSelect name="owner" options={user} isSearchable isInvalid={errors.owner && errors.owner.value && touched.owner && touched.owner.value} valueDefault={values.owner} onChange={(e) => setFieldValue("owner", e)} />
                      {errors.owner && errors.owner.value && touched.owner && touched.owner.value && <div className="op-error-message">{errors.owner.value}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Contact Number</Form.Label>
                      {values.contact.map((record, index) => (
                        <div className="w-100" key={index}>
                          <Stack direction="horizontal" className="mb-2" gap={2}>
                            <FormSelect
                              name={`contact[${index}].contact_prefix`}
                              isInvalid={errors.contact && errors.contact[index] && errors.contact[index].contact_prefix && touched.contact && touched.contact[index] && touched.contact[index].contact_prefix}
                              options={MobilePrefix}
                              isSearchable
                              valueDefault={MobilePrefix.find((record) => Number(record.value) === Number(values.contact[index].contact_prefix))}
                              width="80px"
                              onChange={(e) => {
                                const updatedContact = [...values.contact];
                                updatedContact[index].contact_prefix = e.value;
                                updatedContact[index].contact_prefix_label = e.label;
                                updatedContact[index].contact_prefix_region = e.regionCode;
                                setFieldValue("contact", updatedContact);
                              }}
                            />
                            <Form.Control
                              name={`contact[${index}].contact`}
                              type="text"
                              isInvalid={errors.contact && errors.contact[index] && errors.contact[index].contact && touched.contact && touched.contact[index] && touched.contact[index].contact}
                              value={record.contact}
                              onChange={(e) => {
                                const updatedContact = [...values.contact];
                                updatedContact[index].contact = e.target.value.replace(/\D/g, "");
                                setFieldValue("contact", updatedContact);
                              }}
                            />

                            {index > 0 && (
                              <Button
                                variant="light"
                                className="border"
                                onClick={() => {
                                  const updatedContact = [...values.contact];
                                  updatedContact.splice(index, 1);
                                  updatedContact.forEach((record, index) => (record.index = index + 1));
                                  setFieldValue("contact", updatedContact);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </Button>
                            )}
                          </Stack>
                          {setEditProspectContactError(errors, touched, index)}
                        </div>
                      ))}

                      {values.contact.length < 3 && (
                        <Button
                          style={{ textDecoration: "none", padding: 0 }}
                          variant="link"
                          onClick={() => {
                            setFieldValue("contact", [
                              ...values.contact,
                              {
                                contact_prefix: "",
                                contact_prefix_label: "",
                                contact_prefix_region: "",
                                contact: "",
                                main: false,
                                index: values.contact.length + 1,
                              },
                            ]);
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} className="me-1" /> Add contact
                        </Button>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Email Address</Form.Label>
                      {values.email.map((record, index) => (
                        <div className="w-100" key={index}>
                          <Stack direction="horizontal" className="mb-2" gap={2} key={index}>
                            <Form.Control
                              name={`email[${index}].email`}
                              type="email"
                              isInvalid={errors.email && errors.email[index] && errors.email[index].email && touched.email && touched.email[index] && touched.email[index].email}
                              value={record.email}
                              onChange={(e) => {
                                const updatedEmail = [...values.email];
                                updatedEmail[index].email = e.target.value;
                                setFieldValue("email", updatedEmail);
                              }}
                            />

                            {index > 0 && (
                              <Button
                                variant="light"
                                className="border"
                                onClick={() => {
                                  const updatedEmail = [...values.email];
                                  updatedEmail.splice(index, 1);
                                  setFieldValue("email", updatedEmail);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </Button>
                            )}
                          </Stack>
                          {errors.email && errors.email[index] && errors.email[index].email && touched.email && touched.email[index] && touched.email[index].email && <div className="op-error-message">{errors.email[index].email}</div>}
                        </div>
                      ))}

                      {values.email.length < 2 && (
                        <Button
                          style={{ textDecoration: "none", padding: 0 }}
                          variant="link"
                          onClick={() => {
                            setFieldValue("email", [...values.email, { email: "" }]);
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} className="me-1" /> Add email
                        </Button>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Address Line</Form.Label>
                      <Form.Control name="address1" className="mb-2" type="text" value={values.address1} onChange={handleChange("address1")} />
                      <Form.Control name="address2" className="mb-2" type="text" value={values.address2} onChange={handleChange("address2")} />
                      <Form.Control name="address3" type="text" value={values.address3} onChange={handleChange("address3")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectList_modalBody_edit_title_14")}</Form.Label>
                      <Form.Control name="postcode" type="number" value={values.postcode} onChange={handleChange("postcode")} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("ProspectList_modalBody_edit_title_15")}</Form.Label>
                      <FormSelectV2
                        name="country_id"
                        options={countryData}
                        value={values.country}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(e) => {
                          setFieldValue("country", e.value ? e : "");
                          setFieldValue("state", "");
                          setFieldValue("city", "");
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_state")}</Form.Label>
                      <FormSelectV2
                        name="state_id"
                        options={values.country ? stateData.filter((record) => record.country === values.country.value) : stateData}
                        disabled={values.country === ""}
                        value={values.state}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(e) => {
                          setFieldValue("state", e.value ? e : "");
                          setFieldValue("city", "");
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealDetails_city")}</Form.Label>
                      <FormSelectV2
                        name="city_id"
                        disabled={values.country === "" || values.state === ""}
                        options={values.state ? cityData.filter((record) => record.state === values.state.value) : cityData}
                        value={values.city}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(e) => setFieldValue("city", e.value ? e : "")}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <div className="op horizontal-start divider">Data Fields</div>
                    {prosCF.length > 0 ? (
                      <>
                        {prosCF.map((record, index) => (
                          <FormCustomField
                            key={record.cf_id}
                            id={record.cf_id}
                            label={record.cf_label}
                            options={record.cf_value}
                            type={record.ctf_title}
                            name={record.cf_id}
                            placeholder=""
                            firstOptions={false}
                            valueDefault={false}
                            isClearable={true}
                            value={values.cf_person[index].fd_values}
                            onChange={(info) => {
                              const updatedCustomFields = [...values.cf_person];
                              if (record.ctf_title === "Select") {
                                updatedCustomFields[index].fd_values = info.value;
                              } else if (record.ctf_title === "Radio") {
                                if (info) {
                                  const { value, checked } = info.target;
                                  updatedCustomFields[index].fd_values = checked ? value : "";
                                } else {
                                  updatedCustomFields[index].fd_values = "";
                                }
                              } else if (record.ctf_title === "Checkbox") {
                                const { value, checked } = info.target;
                                if (checked) {
                                  updatedCustomFields[index].fd_values.push(value);
                                } else {
                                  const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                  if (indexToRemove !== -1) {
                                    updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                  }
                                }
                              } else if (record.ctf_title === "Date") {
                                updatedCustomFields[index].fd_values = info;
                              } else {
                                updatedCustomFields[index].fd_values = info.target.value;
                              }

                              setFieldValue("cf_person", updatedCustomFields);
                            }}
                          />
                        ))}
                      </>
                    ) : (
                      <div className="mt-5 text-center">No data fields found</div>
                    )}
                  </Col>
                  <div className="op vertical fluid divider"></div>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light" disabled={editBtnLoading}>
                  {editBtnLoading ? (
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Submit"
                  )}
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default Prospect;
