import { useState, useEffect } from "react";
import { Offcanvas, Spinner } from "react-bootstrap";
import { useAuth } from "../../auth/AuthContext";
import axios from "../../api/axios";
import Swal from "sweetalert2/dist/sweetalert2";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import "../../lib/scss/op-step.scss";
import ChooseCuration from "./ChooseCuration";
import ChooseTemplate from "./ChooseTemplate";
import PreviewTemplate from "./PreviewTemplate";

const AddWSCampaignV2 = ({ show, onHide, onSuccess }) => {
  const { session } = useAuth();
  const [isCuration, setIsCuration] = useState(true);
  const [curations, setCurations] = useState([]);
  const [curation, setCuration] = useState({ value: "", label: "" });
  const [curationProspect, setCurationProspect] = useState([]);
  const [isTemplate, setIsTemplate] = useState(false);
  const [template, setTemplate] = useState({ label: "", value: "" });
  const [templates, setTemplates] = useState([]);
  const [headerType, setHeaderType] = useState("upload");
  const [headerFiles, setHeaderFiles] = useState([]);
  const [whatsapps, setWhatsapps] = useState([]);
  const [whatsapp, setWhatsapp] = useState({ value: "", label: "" });
  const [isPreview, setIsPreview] = useState(false);
  const [campaign, setCampaign] = useState("");
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);

  // Get Function ----------------------------------------

  const init = async () => {
    setIsCuration(true);
    setCurations([]);
    setCuration({ label: "", value: "" });
    setCurationProspect([]);

    setIsTemplate(false);
    setTemplates([]);
    setTemplate({ label: "", value: "" });
    setHeaderType("upload");
    setHeaderFiles([]);
    setWhatsapps([]);
    setWhatsapp({ label: "", value: "" });

    setIsPreview(false);
    setCampaign("");
    setNumber("");

    try {
      await getCurations();
      await getWhatsappNumber();
    } catch (error) {
      setLoading(true);
    } finally {
      setLoading(false);
    }
  };

  const getCurations = async () => {
    try {
      const response = await axios.get("ws/ws_curation.php", {
        params: {
          task: "listCuration",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        var dataList = data.record.map((data) => ({ label: data.curation_title, value: data.curation_id }));
        setCurations(dataList);
      } else {
        setCurations([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCuration = async (record) => {
    if (!record?.value) {
      setCurationProspect([]);
      return;
    }

    try {
      const response = await axios.get("ws/ws_curation.php", {
        params: {
          task: "listCurationContacts",
          curation: record.value,
          startRow: 0,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setCurationProspect(data.record);
      } else {
        setCurationProspect([]);
      }
    } catch (error) {
      console.error(error);
      Swal.fire({ icon: "error", title: error.message });
    }
  };

  const getWhatsappNumber = async () => {
    try {
      const response = await axios.get("ws/ws_ai_campaign.php", {
        params: {
          task: "getAudienceOptions",
          type: "chat_instance",
          company_id: session.company_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        var dataList = data.record.map((record) => ({
          ...record,
          label: `${record.instance_title} - ${record.user_number}`,
          value: record.chat_instance_id,
        }));

        setWhatsapps(dataList);
      } else {
        setWhatsapps([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // onChange Function --------------------------------------------

  const onChangeCuration = async (item) => {
    setCuration(item);
    await getCuration(item);
  };

  // Set Function --------------------------------------------

  const setValidateTemplate = (record) => {
    console.log("record", record);
    if (record.header === 1) {
      const { format } = record.header_record;
      const isMediaFormat = ["image", "video", "document"].includes(format.toLowerCase());
      const isTextType = format.toLowerCase() === "text";

      if (isMediaFormat && record.params_value === "") return true;
      if (isTextType && record.params_value === "") return true;
    }

    if (record.body_record.params > 0 && record.body_record.params_value.some((param) => param === "")) {
      return true;
    }

    if (record.buttons === 1) {
      const hasEmptyUrlButton = record.buttons_record.buttons.some((button) => button.type.toLowerCase() === "url" && button.params !== false &&  button.params_value === "");
      return hasEmptyUrlButton;
    }

    return false;
  };

  const setValidateTemplateHeader = (record) => {
    if (record.header === 1) {
      const { format } = record.header_record;
      const isMediaFormat = ["image", "video", "document"].includes(format.toLowerCase());

      if (isMediaFormat && record.header_record.format.toLowerCase() === "image") {
        const validImageFormats = ["png", "jpg", "jpeg"];
        const imageUrl = record.header_record.params_value;
        const imageExtension = imageUrl.split(".").pop().toLowerCase();
        if (!validImageFormats.includes(imageExtension)) {
          return "image";
        }
      }

      if (isMediaFormat && record.header_record.format.toLowerCase() === "video") {
        const validVideoFormats = ["mp4"];
        const videoUrl = record.header_record.params_value;
        const videoExtension = videoUrl.split(".").pop().toLowerCase();
        if (!validVideoFormats.includes(videoExtension)) {
          return "video";
        }
      }

      if (isMediaFormat && record.header_record.format.toLowerCase() === "document") {
        // const validDocumentFormats = ["pdf", "doc", "docx", "xls", "xlsx"];
        const validDocumentFormats = ["pdf"];
        const documentUrl = record.header_record.params_value;
        const documentExtension = documentUrl.split(".").pop().toLowerCase();
        if (!validDocumentFormats.includes(documentExtension)) {
          return "document";
        }
      }
    }

    return "";
  };

  // Send Function --------------------------------------------

  const sendCampaign = async () => {
    if (campaign.length === 0) {
      setLoadingSend(false);
      return Swal.fire({
        icon: "error",
        text: "Please provide a campaign title to start the campaign.",
      });
    }

    const prospect_id = curationProspect.map((data) => data.prospect_id);

    let header_url = "";
    let header_type = "text";

    if (template.header === 1) {
      const { params_value, format } = template.header_record;
      header_url = params_value;
      header_type = format.toLowerCase();
    }

    let index_arr = [];
    let buttons = [];

    if (template.buttons === 1) {
      const button_data = template.buttons_record.buttons.filter((button) => button.type.toLowerCase() === "url");
      if (button_data.length > 0) {
        index_arr = button_data.map((button) => button.index);
        buttons = button_data.map((button) => button.params_value);
      }
    }

    let category = "text";
    if (template.header === 1 || template.footer === 1 || template.buttons === 1) {
      category = "media";
    }

    try {
      const params = new FormData();
      params.append("task", "sendBulkWACampaignV2");
      params.append("template_name", template.label);
      params.append("template_params", template.body_record.params_value.join("|"));
      params.append("message", template.body_record.text_preview);
      params.append("curation", curation.value);
      params.append("instance", whatsapp.chat_instance_id);
      params.append("prospect", prospect_id.toString());
      params.append("campaign_title", campaign);
      params.append("whatsapp_template_header_image", header_url);
      params.append("whatsapp_template_header_type", header_type);
      params.append("whatsapp_template_category", category);
      params.append("whatsapp_template_button_index", index_arr.join("|"));
      params.append("whatsapp_template_button_params", buttons.join("|"));
      params.append("utoken", session.user_ac_token);
      params.append("ctoken", session.company_token);

      try {
        const response = await axios.post("ws/ws_whatsapp_campaign.php", params);
        let data = response.data;
        if (data) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Successfully create whatsapp campaign",
            timer: 1500,
          }).then(() => {
            onSuccess();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "Please try again or contact your IT Support",
          });
        }
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSend(false);
    }
  };

  // useEffect --------------------------------------------

  useEffect(() => {
    if (show) {
      init();
    } else {
      setLoading(true);
    }
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Offcanvas show={show} onHide={onHide} placement="bottom" style={{ left: 57, height: "100vh" }} backdrop="static">
      <Offcanvas.Header closeButton={!loading} style={{ height: 50 }}>
        <Offcanvas.Title>Create Whatsapp Campaign</Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body className="p-0" style={{ overflow: "hidden" }}>
        <div className="mt-3 px-5 border-bottom" style={{ height: 70 }}>
          <div className="op steps w-100 mb-3">
            <div className="link step active py-2" style={{ pointerEvents: "none" }}>
              <div className="content">
                <div className="title text-center">Curation</div>
                <div className="description d-flex text-center text-light" style={{ fontSize: 11 }}>
                  Choose Curation
                </div>
              </div>
            </div>
            <div className={`link step ${isTemplate || isPreview ? "active" : ""} py-2`} style={{ pointerEvents: "none" }}>
              <div className="content">
                <div className="title text-center">Whatsapp Template</div>
                <div className={`description d-flex text-center ${isTemplate || isPreview ? "text-light" : "text-muted"}`} style={{ fontSize: 11 }}>
                  Choose Whatsapp Template
                </div>
              </div>
            </div>
            <div className={`link step ${isPreview ? "active" : ""} py-2`} style={{ pointerEvents: "none" }}>
              <div className="content">
                <div className="title text-center">Confirmation</div>
                <div className={`description d-flex text-center ${isPreview ? "text-light" : "text-muted"}`} style={{ fontSize: 11 }}>
                  Summary of the info
                </div>
              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center w-100 mt-5" style={{ zIndex: 2 }}>
            <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        ) : (
          <div>
            <ChooseCuration
              show={isCuration}
              curations={curations}
              curation={curation}
              prospects={curationProspect}
              onChangeCuration={onChangeCuration}
              onNext={() => {
                if (curation.value === "") {
                  return Swal.fire({
                    icon: "error",
                    text: "Please select a curation to proceed with your campaign.",
                  });
                }

                if (curationProspect.length === 0) {
                  return Swal.fire({
                    icon: "error",
                    text: "No prospects were found in the selected curation. Please choose another curation to continue.",
                  });
                }

                setIsCuration(false);
                setIsTemplate(true);
              }}
            />

            <ChooseTemplate
              show={isTemplate}
              whatsapps={whatsapps}
              whatsapp={whatsapp}
              templateList={templates}
              templateData={template}
              headerType={headerType}
              headerFiles={headerFiles}
              onChangeWhatsapp={setWhatsapp}
              onNext={(templates, template, headerType, headerFiles) => {
                if (whatsapp.value === "") {
                  return Swal.fire({
                    icon: "error",
                    text: "Please choose a WhatsApp number to continue with your campaign.",
                  });
                }

                if (template.value === "") {
                  return Swal.fire({
                    icon: "error",
                    text: "Please choose a template to proceed with your campaign.",
                  });
                }

                const check_template = setValidateTemplate(template);
                if (check_template) {
                  return Swal.fire({
                    icon: "error",
                    text: "Please ensure all variables are filled in before proceeding.",
                  });
                }

                const checkHeader = setValidateTemplateHeader(template);
                const errorMessages = {
                  image: "Please ensure that you upload a valid image file in one of the following formats: PNG, JPG, or JPEG.",
                  video: "For video uploads, please provide a valid file in MP4 format.",
                  document: "Please upload a valid document file. Accepted formats include PDF, DOC, DOCX, XLS, or XLSX.",
                };

                if (errorMessages[checkHeader]) {
                  return Swal.fire({
                    icon: "error",
                    text: errorMessages[checkHeader],
                  });
                }

                setIsTemplate(false);
                setIsPreview(true);
                setTemplates(templates);
                setTemplate(template);
                setHeaderType(headerType);
                setHeaderFiles(headerFiles);
              }}
              onPrevious={(templates, template, headerType, headerFiles) => {
                setIsTemplate(false);
                setIsCuration(true);
                setTemplates(templates);
                setTemplate(template);
                setHeaderType(headerType);
                setHeaderFiles(headerFiles);
              }}
            />

            <PreviewTemplate
              show={isPreview}
              campaign={campaign}
              setCampaign={setCampaign}
              number={number}
              setNumber={setNumber}
              prospects={curationProspect}
              curation={curation}
              template={template}
              whatsapp={whatsapp}
              headerFiles={headerFiles}
              loadingSend={loadingSend}
              onPrevious={() => {
                setIsPreview(false);
                setIsTemplate(true);
              }}
              onSubmit={() => {
                if (loadingSend) return;
                setLoadingSend(true);
                sendCampaign();
              }}
            />
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddWSCampaignV2;
