import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Card, Row, Col, Stack, Form, Modal, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import FormSelect from "../includes/FormSelect";
import moment from "moment";
import Loader from "../includes/Loader";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2/dist/sweetalert2";
import { faPencil, faStar, faUnlockKeyhole } from "@fortawesome/pro-solid-svg-icons";
import { faUserGear, faUserUnlock } from "@fortawesome/pro-regular-svg-icons";

const SRMAssessment = () => {
    const init = useRef(false);
    const gridRef = useRef(null);
    const gridRefEdit = useRef(null);
    const { session } = useAuth();
    const [isLoading, setIsLoading] = useState(true);

    const [editData, setEditData] = useState();
    const [isEdit, setIsEdit] = useState(false);

    const [filterList, setFilterList] = useState([]);
    const [selFilter, setSelFilter] = useState(`${moment().format("MMMM")} ${moment().format("YYYY")}`);
    const [month, setMonth] = useState(moment().format("MMMM"));
    const [year, setYear] = useState(moment().format("YYYY"));

    const [rowData, setRowData] = useState([]);
    const [rowColumn] = useState([
        {
            headerName: 'RA Name',
            field: 'ra_user_name',
            minWidth: 250,
            flex: 1
        },
        {
            headerName: 'Courteousness & Friendliness',
            field: 'q1_result',
            width: '210',
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 1 <= Number(params.data.q1_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 2 <= Number(params.data.q1_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 3 <= Number(params.data.q1_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 4 <= Number(params.data.q1_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 5 <= Number(params.data.q1_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                        </div>
                    );
                }
            }
        },
        {
            headerName: 'Promptness in Following Up',
            field: 'q2_result',
            width: '210',
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 1 <= Number(params.data.q2_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 2 <= Number(params.data.q2_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 3 <= Number(params.data.q2_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 4 <= Number(params.data.q2_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 5 <= Number(params.data.q2_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                        </div>
                    );
                }
            }
        },
        {
            headerName: 'Personal Grooming and Appearence',
            field: 'q3_result',
            width: '260',
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 1 <= Number(params.data.q3_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 2 <= Number(params.data.q3_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 3 <= Number(params.data.q3_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 4 <= Number(params.data.q3_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating"/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 5 <= Number(params.data.q3_result) ? "#ffd43b" : "#ddd" }}/>
                            </label>
                        </div>
                    );
                }
            }
        },
        {
            headerName: 'SRM Score',
            width:'150',
            cellRenderer: (params) => {
                if (params.data) {
                    return `${Number(params.data.srm_average)}`;
                }
            }
        },
        {
            headerName: 'Status',
            field: '',
            width:'150',
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div style={{textAlign: 'center'}}>
                            <div>{params.data.status}</div>
                        </div>
                    );
                }
            }
        },
        {
            headerName: 'Received Date',
            field: 'date_time_submitted',
            width:'150',
        },
        {
            headerName: 'Rated By',
            field: 'srm_user_name',
            width:'150',
        },
        {
            headerName: 'Action',
            width: '120',
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <Stack direction="horizontal" gap={2}>
                            <button style={{cursor: 'pointer'}} type="button" className="btn btn-light border-0 shadow-sm" onClick={() => {handleEdit(params.data)}} disabled={Number(params.data.unlock) === 0 && params.data.unlock ? true: false}>
                                <FontAwesomeIcon icon={faPencil} size="lg"/>
                            </button>
                            {/* {(Number(params.data.inactive) === 0 && params.data.inactive) && <button style={{cursor: 'pointer'}} type="button" className="btn btn-light border-0 shadow-sm">
                                <FontAwesomeIcon icon={faUserGear} size="lg"/>
                            </button>}
                            {(Number(params.data.unlock) === 0 && params.data.unlock) && <button style={{cursor: 'pointer'}} type="button" className="btn btn-light border-0 shadow-sm">
                                 <FontAwesomeIcon icon={faUnlockKeyhole} size="lg"/>
                            </button>} */}
                        </Stack>
                    );
                }
            }
        },
        {
            headerName: 'Remark',
            field: 'srm_remark',
            width: '150'
        }
    ]);
    const [submitDate, setSubmitDate] = useState('');

    const [editRowData, setEditRowData] = useState([]);
    const gridRowId = useCallback(function (params) {
        return params.data.id.toString();
    }, []);
    const [editRowColumn] = useState([
        {
            headerName: 'Question',
            field: 'question',
            flex: 1
        },
        {
            headerName: 'Rating',
            field: 'q_result',
            flex: 1,
            cellRenderer: (params) => {

                if (params.data) {
                    return(
                        <div>
                            <label>
                                <input className="d-none" type="radio" name="rating" value={1} onClick={() => {handleClickRating(params.data, 1)}}/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 1 <= (Number(params.data.q_resultHover) || Number(params.data.q_result)) ? "#ffd43b" : "#ddd", cursor: 'pointer' }} onMouseEnter={() => {handleMouseEnter(params.data, 1)}} onMouseLeave={() => {handleMouseLeave(params.data, 0)}}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating" value={2} onClick={() => {handleClickRating(params.data, 2)}}/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 2 <= (Number(params.data.q_resultHover) || Number(params.data.q_result)) ? "#ffd43b" : "#ddd", cursor: 'pointer' }} onMouseEnter={() => {handleMouseEnter(params.data, 2)}} onMouseLeave={() => {handleMouseLeave(params.data, 0)}}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating" value={3} onClick={() => {handleClickRating(params.data, 3)}}/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 3 <= (Number(params.data.q_resultHover) || Number(params.data.q_result)) ? "#ffd43b" : "#ddd", cursor: 'pointer' }} onMouseEnter={() => {handleMouseEnter(params.data, 3)}} onMouseLeave={() => {handleMouseLeave(params.data, 0)}}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating" value={4} onClick={() => {handleClickRating(params.data, 4)}}/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 4 <= (Number(params.data.q_resultHover) || Number(params.data.q_result)) ? "#ffd43b" : "#ddd", cursor: 'pointer' }} onMouseEnter={() => {handleMouseEnter(params.data, 4)}} onMouseLeave={() => {handleMouseLeave(params.data, 0)}}/>
                            </label>
                            <label>
                                <input className="d-none" type="radio" name="rating" value={5} onClick={() => {handleClickRating(params.data, 5)}}/>
                                <FontAwesomeIcon icon={faStar} style={{ color: 5 <= (Number(params.data.q_resultHover) || Number(params.data.q_result)) ? "#ffd43b" : "#ddd", cursor: 'pointer' }} onMouseEnter={() => {handleMouseEnter(params.data, 5)}} onMouseLeave={() => {handleMouseLeave(params.data, 0)}}/>
                            </label>
                        </div>
                    );
                }
            }
        }
    ]);

    const handleClickRating = (data,val) => {
        const rowNode = gridRefEdit.current.api.getRowNode(data.id.toString());
        rowNode.setData({
            ...rowNode.data,
            ['q_result']: val,
        });

        setEditRowData(prev => prev.map(item => item.id === data.id ? {...item, q_result: val} : item));
    }

    const handleMouseEnter = (data, val) => {
        const rowNode = gridRefEdit.current.api.getRowNode(data.id.toString());
        rowNode.setData({
            ...rowNode.data,
            ['q_resultHover']: val,
        });
    }

    const handleMouseLeave = (data, val) => {
        const rowNode = gridRefEdit.current.api.getRowNode(data.id.toString());
        rowNode.setData({
            ...rowNode.data,
            ['q_resultHover']: val,
        });
    }

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const containerStyle = useMemo(() => ({ width: "100%", height: "75vh", paddingTop: 10 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDefEdit = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const containerStyleEdit = useMemo(() => ({ width: "100%", height: "27.5vh", paddingTop: 10 }), []);
    const gridStyleEdit = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const getGrid = () => {
        axios.get('ws/ws_insert_srm_assessment.php', {
            params: {
                task: 'getlistofSRMAssessment',
                assessMonth: month,
                assessYear: year,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                setRowData(data.record);
                setIsLoading(false);
            }else{
                setRowData([]);
                setIsLoading(false);
            }
        })
    }

    const getFilterList = () => {
        
        const currentDate = new Date();
        let monthList = [];
        const startDate = new Date(2024, 0, 1);

        const yearDiff = currentDate.getFullYear() - startDate.getFullYear();

        const totalMonths = yearDiff * (12 + currentDate.getMonth() + 1);
        
        for (let i = 0; i < totalMonths; i++) {
            const date = new Date(currentDate);
            date.setMonth(currentDate.getMonth() - i); 
            const monthName = date.toLocaleString('default', { month: 'long' });
            const year = date.getFullYear();
            monthList.push({label: `${monthName} ${year}`, value: `${monthName} ${year}`, month: monthName, year: year});
        }

        setFilterList(monthList);
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGrid();
            getFilterList();
        }
    }, []);

    const handleEdit = (data) => {

        var rowData = [
            {
                id: '1',
                question: 'Courteousness & Friendliness',
                q_result: data.q1_result,
                q_resultHover: 0
            },
            {
                id: '2',
                question: 'Promptness in Following Up',
                q_result: data.q2_result,
                q_resultHover: 0
            },
            {
                id: '3',
                question: 'Personal Grooming and Appearence',
                q_result: data.q3_result,
                q_resultHover: 0
            },
        ];

        data.remark = "";
        
        setSubmitDate("");
        setEditRowData(rowData);
        setEditData(data);
        setIsEdit(true);
    }

    const handleUpdate = () => {
        var allowSubmit = 0;
        var isSameDate = selFilter === moment().format('MMMM YYYY') ? true : false;

        if (isSameDate) {
            allowSubmit = 1;
        }else{
            if (submitDate) {
                allowSubmit = 1;
            }else{
                allowSubmit = 0;
            }
        }

        if (allowSubmit === 1) {
            axios.get("ws/ws_insert_srm_assessment.php", {
                params: {
                    task: 'insertSRMAssessment',
                    userid: editData.user_id,
                    q1result: editRowData[0].q_result,
                    q2result: editRowData[1].q_result,
                    q3result: editRowData[2].q_result,
                    srmremark: editData.remark,
                    date: isSameDate ? moment().format('YYYY-MM-DD hh:mm:ss') : `${submitDate} 00:00:00`,
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            .then(res => {
                let data = res.data;
    
                if (Number(data.status) === 0) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success'
                    })
                    .then(() => {
                        getGrid();
                        setIsEdit(false);
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed'
                    });
                }
            });   
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Please insert valid date'
            });
        }
    }

    return(
        <div>
            {
                isLoading ? <Loader/> : 
                <div className="p-4">
                    <Stack direction="horizontal" gap={2}>
                        <Form.Group style={{width: '180px'}}>
                            <FormSelect
                                options={filterList}
                                valueDefault={filterList.filter((opt) => opt.value === selFilter)}
                                isSearchable={true}
                                onChange={(e) => {
                                    setMonth(e.month);
                                    setYear(e.year);
                                    setSelFilter(e.value);
                                }}
                            />
                        </Form.Group>
                        <button type="button" className="btn op-button op-primary-color text-light" onClick={() => {getGrid()}}>
                            Apply
                        </button>
                    </Stack>
                    <div style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={rowColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={60}
                                // pagination={false}
                                // paginationPageSize={30}
                                paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>

                    <Modal show={isEdit} onHide={setIsEdit} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title as={'h6'}>Assessment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(selFilter !== moment().format('MMMM YYYY')) && <Form.Group className="mb-3">
                                <Form.Label>Date submission</Form.Label>
                                <Form.Control
                                    type="date"
                                    min={moment(`${year} ${month}`).startOf('month').format('YYYY-MM-DD')}
                                    max={moment(`${year} ${month}`).endOf('month').format('YYYY-MM-DD')}
                                    value={submitDate}
                                    onChange={(e) => {setSubmitDate(e.target.value)}}
                                />
                            </Form.Group>}

                            <div style={containerStyleEdit}>
                                <div className={"ag-theme-quartz ag-op ag-op-secondary border shadow-sm rounded"} style={{ ...gridStyleEdit }}>
                                    <AgGridReact
                                        ref={gridRefEdit}
                                        getRowId={gridRowId}
                                        columnDefs={editRowColumn}
                                        rowData={editRowData}
                                        defaultColDef={reportColDefEdit}
                                        rowHeight={60}
                                        // pagination={false}
                                        // paginationPageSize={30}
                                        paginationPageSizeSelector={false}
                                    />
                                </div>
                            </div>

                            <Form.Group className="my-3">
                                <Form.Label>Other Remarks (if any):</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(e) => {
                                        setEditData((prev) => ({
                                            ...prev,
                                            remark: e.target.value
                                        }))
                                    }}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <button 
                                type="button" 
                                className="btn op-button op-primary-color text-light" 
                                onClick={() => {handleUpdate()}}
                            >
                                Submit
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </div>
    );
}   

export default SRMAssessment;