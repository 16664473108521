import React, { useState, useRef, useEffect, forwardRef } from "react";
import { Card, Button, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBold, faItalic, faStrikethrough, faCode, faSmile } from "@fortawesome/pro-light-svg-icons";
import EmojiPicker from "emoji-picker-react";

const BodyBlock = ({ reset = 0, back = 0, value = "", onChangeValue = () => null, onChangeParams = () => null }) => {
  const messageContentRef = useRef(null);
  const emojiPickerRef = useRef();
  const [params, setParams] = useState(0);

  useEffect(() => {
    if (messageContentRef.current && Number(back) > 0) {
      messageContentRef.current.textContent = value;
    }
  }, [back, messageContentRef.current]);

  useEffect(() => {
    if (Number(reset) > 0) {
      messageContentRef.current.textContent = "";
    }
  }, [reset]);

  const style = {
    border: "none",
    outline: "none",
    whiteSpace: "pre-line",
  };

  const applyFormatting = (format) => {
    messageContentRef.current.focus();
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const selectedText = selection.toString();

    let formattedText = selectedText;

    switch (format) {
      case "bold":
        formattedText = `*${selectedText}*`;
        break;
      case "italic":
        formattedText = `_${selectedText}_`;
        break;
      case "strikethrough":
        formattedText = `~${selectedText}~`;
        break;
      case "code":
        formattedText = `\`\`\`${selectedText}\`\`\``;
        break;
    }

    // Replace selected text
    range.deleteContents();
    const textNode = document.createTextNode(formattedText);
    range.insertNode(textNode);

    // Move cursor after the inserted text
    range.setStartAfter(textNode);
    range.setEndAfter(textNode);
    selection.removeAllRanges();
    selection.addRange(range);

    // Update state
    onChangeValue(messageContentRef.current.textContent);
  };

  const handleVariable = () => {
    const newVariable = value.length > 0 ? ` {{${params + 1}}}` : `{{${params + 1}}}`;
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(newVariable));
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    onChangeValue(messageContentRef.current.textContent);
    onChangeParams(params + 1);
    setParams(params + 1);
  };

  const handleEmoji = (event) => {
    const newEmoji = event.emoji;
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(newEmoji));
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    onChangeValue(messageContentRef.current.textContent);
    emojiPickerRef.current.click();
  };

  const handleKeyDown = (e) => {
    const value = messageContentRef.current.textContent;
    if (value.length >= 1024 && e.key !== "Backspace" && e.key !== "Delete") {
      e.preventDefault();
    }

    if (e.key === "Enter") {
      e.preventDefault();
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const customLineBreak = document.createTextNode("\n");
      const spacer = document.createTextNode("\u00A0");
      range.deleteContents();
      range.insertNode(customLineBreak);
      range.collapse(false);
      range.insertNode(spacer);
      selection.removeAllRanges();
      selection.addRange(range);
      onChangeValue(messageContentRef.current.textContent);
    }

    if (e.ctrlKey && e.key === "b") {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const selectedText = selection.toString();
        const starText = `*${selectedText}*`;
        range.deleteContents();
        range.insertNode(document.createTextNode(starText));
        onChangeValue(messageContentRef.current.textContent);
      }
    }

    if (e.ctrlKey && e.key === "i") {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const selectedText = selection.toString();
        const starText = `*${selectedText}*`;
        range.deleteContents();
        range.insertNode(document.createTextNode(starText));
        onChangeValue(messageContentRef.current.textContent);
      }
    }

    if (e.ctrlKey && e.shiftKey && e.key === "x") {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const selectedText = selection.toString();
        const strikethroughText = `~${selectedText}~`;
        range.deleteContents();
        range.insertNode(document.createTextNode(strikethroughText));
        onChangeValue(messageContentRef.current.textContent);
      }
    }

    if (e.ctrlKey && e.shiftKey && e.key === "c") {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const selectedText = selection.toString();
        const codeText = `\`\`\`${selectedText}\`\`\``;
        range.deleteContents();
        range.insertNode(document.createTextNode(codeText));
        onChangeValue(messageContentRef.current.textContent);
      }
    }
  };

  const handleInput = (e) => {
    const currentContent = e.currentTarget.innerHTML.trim();
    if (currentContent === "<br>") e.currentTarget.innerHTML = "";

    const formContent = e.currentTarget.textContent;
    if (formContent.length > 1024) return;
    onChangeValue(formContent);
    const variableCount = (formContent.match(/{{\d+}}/g) || []).length;
    onChangeParams(variableCount);
    setParams(variableCount);
  };

  const RenderToggle = forwardRef(({ onClick }, ref) => (
    <Button
      ref={ref}
      variant="light"
      className="border shadow-sm me-2"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <FontAwesomeIcon icon={faSmile} />
    </Button>
  ));

  const RenderMenu = React.forwardRef(({ style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div ref={ref} style={{ ...style, padding: 0 }} className={className} aria-labelledby={labeledBy}>
        <EmojiPicker onEmojiClick={handleEmoji} />
      </div>
    );
  });

  return (
    <Card>
      <Card.Header className="d-flex align-items-center">
        <div className="op-primary-color text-light rounded me-3" style={{ padding: "6px 10px" }}>
          BODY
        </div>

        <Dropdown>
          <Dropdown.Toggle ref={emojiPickerRef} as={RenderToggle} />
          <Dropdown.Menu as={RenderMenu} />
        </Dropdown>

        <div className="position-absolute" style={{ bottom: -458, right: -302, zIndex: 9999 }}></div>

        <Button variant="light" className="border shadow-sm me-2" onClick={() => applyFormatting("bold")}>
          <FontAwesomeIcon icon={faBold} />
        </Button>

        <Button variant="light" className="border shadow-sm me-2" onClick={() => applyFormatting("italic")}>
          <FontAwesomeIcon icon={faItalic} />
        </Button>

        <Button variant="light" className="border shadow-sm me-2" onClick={() => applyFormatting("strikethrough")}>
          <FontAwesomeIcon icon={faStrikethrough} />
        </Button>

        <Button variant="light" className="border shadow-sm me-2" onClick={() => applyFormatting("code")}>
          <FontAwesomeIcon icon={faCode} />
        </Button>

        <Button variant="light" className="border shadow-sm" onClick={handleVariable}>
          Add Variable
        </Button>
      </Card.Header>
      <Card.Body className="d-flex justify-content-center align-items-center position-relative">
        <div
          ref={messageContentRef}
          dir="ltr"
          contentEditable="true"
          className="editable-div body-block w-100 mb-4"
          style={style}
          onKeyDown={handleKeyDown}
          onInput={handleInput}
        />

        {value && (
          <div className="op-primary-color text-light" style={{ position: "absolute", bottom: 0, right: 0, padding: "2px 10px", fontSize: 10 }}>
            {value.length} / 1024 characters
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default BodyBlock;
