import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Form, Stack, InputGroup, Modal, Button, Spinner } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { PieChart, Pie, Cell, Label } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-duotone-svg-icons";
import WabaTemplate from "./whatsapp-campaign/WabaTemplate";
import { faRotateRight } from "@fortawesome/pro-light-svg-icons";

const WhatsappCampaign = () => {
  const init = useRef(false);
  const { session } = useAuth();
  const gridRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [isGrid, setIsGrid] = useState(false);
  const [recordFound, setRecordFound] = useState(0);
  const [rowData, setRowData] = useState([]);

  const [isAdd, setIsAdd] = useState(false);

  const [ModalWSData, setModalWSData] = useState([]);
  const [isModalWSData, setIsModalWSData] = useState(false);
  const [ModalWSType, setModalWSType] = useState("");

  const WSModalgridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "78vh", paddingTop: 5 }), [isGrid]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [loading2, setLoading2] = useState(false);

  const reportColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  const CustomLabel = ({ viewBox, value, onClick }) => {
    const { cx, cy } = viewBox;
    return (
      <svg className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central" onClick={onClick}>
        <text x={cx} y={cy} fill="#3d405c">
          <tspan x={cx} dy="0em" alignmentBaseline="middle" fontSize="13" cursor="pointer">
            {value}
          </tspan>
        </text>
      </svg>
    );
  };

  const reportColumn = [
    {
      headerName: "Date Campaign Create",
      field: "date_time_create",
      width: 200,
    },
    {
      headerName: "Campaign Title",
      field: "campaign_title",
      filter: true,
      flex: 1,
      minWidth: 250,
    },
    {
      headerName: "Curation",
      field: "curation_title",
      flex: 1,
      minWidth: 250,
    },
    {
      headerName: "Template Name",
      field: "template_name",
      flex: 1,
      minWidth: 400,
    },
    {
      headerName: "Sent",
      field: "TOTAL_TRIGGER_SUCCESSFUL",
      width: 120,
      headerClass: "center",
      cellClass: "center",
      autoHeight: true,
      pinned: "right",
      cellRenderer: (params) => {
        if (params.data) {
          var chartData = [
            { name: "Total Delivered", value: Number(params.data.TOTAL_TRIGGER_SUCCESSFUL) },
            { name: "Total Contacted", value: Number(params.data.TOTAL_CONTACT) - Number(params.data.TOTAL_TRIGGER_SUCCESSFUL) },
          ];

          return (
            <PieChart width={100} height={100}>
              <Pie dataKey="value" isAnimationActive={false} data={chartData} cx={"50%"} cy={"50%"} innerRadius={20} outerRadius={30}>
                <Label
                  width={30}
                  position="center"
                  content={
                    <CustomLabel
                      value={params.data.TOTAL_TRIGGER_SUCCESSFUL}
                      onClick={() => {
                        handleWSModal(params.data.whatsapp_campaign_id, "contact");
                        setModalWSType("sent");
                      }}
                    />
                  }
                ></Label>
                <Cell fill={"#000"} />
                <Cell fill={"#ddd"} />
              </Pie>
            </PieChart>
          );
        }
      },
    },
    {
      headerName: "Delivered",
      field: "TOTAL_DELIVERED",
      width: 120,
      headerClass: "center",
      cellClass: "center",
      autoHeight: true,
      pinned: "right",
      cellRenderer: (params) => {
        if (params.data) {
          var chartData = [
            { name: "Total Delivered", value: Number(params.data.TOTAL_DELIVERED) },
            { name: "Total Contacted", value: Number(params.data.TOTAL_CONTACT) - Number(params.data.TOTAL_DELIVERED) },
          ];

          return (
            <PieChart width={100} height={100}>
              <Pie dataKey="value" isAnimationActive={false} data={chartData} cx={"50%"} cy={"50%"} innerRadius={20} outerRadius={30}>
                <Label
                  width={30}
                  position="center"
                  content={
                    <CustomLabel
                      value={params.data.TOTAL_DELIVERED}
                      onClick={() => {
                        handleWSModal(params.data.whatsapp_campaign_id, "delivered");
                        setModalWSType("delivered");
                      }}
                    />
                  }
                ></Label>
                <Cell fill={"#000"} />
                <Cell fill={"#ddd"} />
              </Pie>
            </PieChart>
          );
        }
      },
    },
    {
      headerName: "Read",
      field: "TOTAL_WAREAD",
      width: 120,
      pinned: "right",
      headerClass: "center",
      cellClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          var chartData = [
            { name: "Total Delivered", value: Number(params.data.TOTAL_WAREAD) },
            { name: "Total Contacted", value: Number(params.data.TOTAL_CONTACT) - Number(params.data.TOTAL_WAREAD) },
          ];

          return (
            <PieChart width={100} height={100}>
              <Pie dataKey="value" isAnimationActive={false} data={chartData} cx={"50%"} cy={"50%"} innerRadius={20} outerRadius={30}>
                <Label
                  width={30}
                  position="center"
                  content={
                    <CustomLabel
                      value={params.data.TOTAL_WAREAD}
                      onClick={() => {
                        handleWSModal(params.data.whatsapp_campaign_id, "waread");
                        setModalWSType("waread");
                      }}
                    />
                  }
                ></Label>
                <Cell fill={"#000"} />
                <Cell fill={"#ddd"} />
              </Pie>
            </PieChart>
          );
        }
      },
    },
    {
      headerName: "Responded",
      field: "TOTAL_RESPONDED",
      width: 120,
      pinned: "right",
      headerClass: "center",
      cellClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          var chartData = [
            { name: "Total Delivered", value: Number(params.data.TOTAL_RESPONDED) },
            { name: "Total Leads", value: Number(params.data.TOTAL_CONTACT) - Number(params.data.TOTAL_RESPONDED) },
          ];

          return (
            <PieChart width={100} height={100}>
              <Pie dataKey="value" isAnimationActive={false} data={chartData} cx={"50%"} cy={"50%"} innerRadius={20} outerRadius={30}>
                <Label
                  width={30}
                  position="center"
                  content={
                    <CustomLabel
                      value={params.data.TOTAL_RESPONDED}
                      onClick={() => {
                        handleWSModal(params.data.whatsapp_campaign_id, "responded");
                        setModalWSType("responded");
                      }}
                    />
                  }
                ></Label>
                <Cell fill={"#000"} />
                <Cell fill={"#ddd"} />
              </Pie>
            </PieChart>
          );
        }
      },
    },
    {
      headerName: "Not Sent",
      width: 120,
      pinned: "right",
      headerClass: "center",
      cellClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          return Number(params.data.TOTAL_CONTACT) - Number(params.data.TOTAL_TRIGGER_SUCCESSFUL);
        }
      },
    },
    {
      headerName: "Total Leads",
      field: "TOTAL_CONTACT",
      width: 120,
      pinned: "right",
      headerClass: "center",
      cellClass: "center",
    },
  ];

  const getGridData = async () => {
    try {
      const res = await axios.get("ws/ws_whatsapp_campaign.php", {
        params: {
          task: "getWACampaignListing",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });
      let data = res.data;

      if (Number(data.status) === 0) {
        data.record.sort(function (a, b) {
          return new Date(b.date_time_create) - new Date(a.date_time_create);
        });
        setRowData(data.record);
        setRecordFound(data.totalRecord);
        setIsGrid(true);
        setIsLoading(false);
      } else {
        setRowData([]);
        setRecordFound(0);
        setIsGrid(false);
        setIsLoading(false);

        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "No record",
        });
      }
    } catch (error) {
      setRowData([]);
      setRecordFound(0);
      setIsGrid(false);
      setIsLoading(false);

      Swal.fire({
        icon: "error",
        title: error.message,
      });
    } finally {
      setLoading2(false);
    }
  };

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      getGridData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (text) => {
    gridRef.current.api.setQuickFilter(text);
    setRecordFound(gridRef.current.api.getModel().getRowCount());
  };

  const containerStyleModalWS = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "78vh", paddingTop: 5 }), [isModalWSData]);
  const gridStyleModalWS = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [ModalWSColumn, setModalWSColumn] = useState([]);

  const handleWSModal = (id, type) => {
    axios
      .get("ws/ws_whatsapp_campaign.php", {
        params: {
          task: "getStatusCampaignChatSendListing",
          chat_send_status: type,
          whatsapp_campaign_id: id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.status) === 0) {
          if (type === "contact") {
            data.record = data.record.filter((item) => Number(item.trigger_successful) === 1);
          }

          setModalWSData(data.record);
          setIsModalWSData(true);

          var column = [
            {
              headerName:
                type === "contact"
                  ? "Date Time Sent"
                  : type === "delivered"
                  ? "Date Time Delivered"
                  : type === "waread"
                  ? "Date Time Read"
                  : type === "responded"
                  ? "Date Time Responded"
                  : "",
              field:
                type === "contact"
                  ? "trigger_date_time"
                  : type === "delivered"
                  ? "delivered_date_time"
                  : type === "waread"
                  ? "read_date_time"
                  : type === "responded"
                  ? "responded_date_time"
                  : "",
              resizable: true,
            },
            {
              headerName: "Prospect Name",
              field: "prospect_name",
              resizable: true,
            },
            {
              headerName: "Prospect Number",
              field: "receiver_number",
              resizable: true,
            },
            {
              headerName: "Message",
              field: "message",
              resizable: true,
              flex: 1,
            },
          ];

          setModalWSColumn(column);
        } else {
          setModalWSData([]);
          setIsModalWSData(false);

          Swal.fire({
            icon: "error",
            title: "No data",
            text: "No data found",
          });
        }
      });
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <Container fluid className="p-4">
          <Stack direction="horizontal" gap={2} className="mb-2">
            <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>
              Add Campaign
            </button>
            <Form.Group style={{ width: "250px" }}>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="search by campaign title"
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                />
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" className="fontAwesomeIcon" />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <div className="ms-auto">
              {Number(recordFound) === 1
                ? `${recordFound} record found.`
                : Number(recordFound) > 1
                ? `${recordFound} records found.`
                : `No record found.`}
            </div>
            <Button
              variant="light"
              className="border shadow-sm"
              onClick={() => {
                setLoading2(true);
                getGridData();
              }}
            >
              <FontAwesomeIcon icon={faRotateRight} />
            </Button>
          </Stack>
          {loading2 ? (
            <div className="d-flex justify-content-center align-items-center mt-3" style={{ zIndex: 2 }}>
              <div className="rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: 30, width: 30 }}>
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </div>
          ) : (
            <div className="mb-3" style={containerStyle}>
              <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                <AgGridReact
                  ref={gridRef}
                  columnDefs={reportColumn}
                  rowData={rowData}
                  defaultColDef={reportColDef}
                  rowHeight={80}
                  pagination={false}
                />
              </div>
            </div>
          )}

          <WabaTemplate
            show={isAdd}
            onHide={setIsAdd}
            onSuccess={() => {
              getGridData();
              setIsAdd(false);
            }}
          />

          <Modal show={isModalWSData} onHide={setIsModalWSData} size="xl">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>
                {ModalWSType === "sent"
                  ? "Sent"
                  : ModalWSType === "delivered"
                  ? "Delivered"
                  : ModalWSType === "waread"
                  ? "Read"
                  : ModalWSType === "responded"
                  ? "Responded"
                  : ""}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3" style={containerStyleModalWS}>
                <div className={"ag-theme-quartz ag-op ag-op-secondary border shadow-sm rounded"} style={{ ...gridStyleModalWS }}>
                  <AgGridReact
                    ref={WSModalgridRef}
                    columnDefs={ModalWSColumn}
                    rowData={ModalWSData}
                    defaultColDef={reportColDef}
                    rowHeight={80}
                    pagination={false}
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      )}
    </div>
  );
};

export default WhatsappCampaign;
