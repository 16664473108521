import React, { useState, useRef, useEffect } from "react";
import { Card } from "react-bootstrap";
import { FormSelectV2 } from "../../includes/FormCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faImage, faVideo } from "@fortawesome/pro-light-svg-icons";

const HeaderBlock = ({ type = "text", back = 0, value = "", paramsValue = 0, onChangeType = () => null, onChangeValue = () => null, onChangeParams = () => null }) => {
  const messageRef = useRef(null);
  const [params, setParams] = useState(paramsValue > 0);
  const [headerType] = useState([
    { label: "Text", value: "text" },
    { label: "Image", value: "image" },
    { label: "Document", value: "document" },
    { label: "Video", value: "video" },
  ]);

  const style = {
    border: "none",
    outline: "none",
    whiteSpace: "pre-line",
  };

  const handleKeyDown = (e) => {
    const value = messageRef.current.textContent;
    const variableCount = (value.match(/{{\d+}}/g) || []).length;

    if ((value.length >= 60 && e.key !== "Backspace" && e.key !== "Delete") || (variableCount === 1 && (e.key === "{" || e.key === "}"))) {
      e.preventDefault();
    }

    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default action
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const customLineBreak = document.createTextNode("\n");
      const spacer = document.createTextNode("\u00A0");
      range.deleteContents();
      range.insertNode(customLineBreak);
      range.collapse(false);
      range.insertNode(spacer);
      selection.removeAllRanges();
      selection.addRange(range);
      onChangeValue(messageRef.current.textContent);
    }
  };

  const handleInput = (e) => {
    const currentContent = e.currentTarget.innerHTML.trim();
    if (currentContent === "<br>") e.currentTarget.innerHTML = "";
    const formContent = e.currentTarget.textContent;
    if (formContent.length > 60) return;
    const variableCount = (formContent.match(/{{\d+}}/g) || []).length;
    if (variableCount > 1) return;

    setParams(variableCount);
    onChangeParams(variableCount);
    onChangeValue(formContent);
  };

  useEffect(() => {
    if (messageRef.current && Number(back) > 0) {
      messageRef.current.textContent = value;
    }
  }, [back, messageRef.current]);

  return (
    <Card>
      <Card.Header className="d-flex align-items-center">
        <div className="op-primary-color text-light rounded me-3" style={{ padding: "6px 10px" }}>
          HEADER
        </div>
        <FormSelectV2 value={headerType.find((record) => record.value === type)} options={headerType} onChange={onChangeType} width="200px" />
      </Card.Header>
      <Card.Body className="d-flex justify-content-center align-items-center">
        {type === "image" ? (
          <FontAwesomeIcon icon={faImage} size="10x" color="#ddd" />
        ) : type === "document" ? (
          <FontAwesomeIcon icon={faFile} size="10x" color="#ddd" />
        ) : type === "video" ? (
          <FontAwesomeIcon icon={faVideo} size="10x" color="#ddd" />
        ) : (
          <>
            <div
              ref={messageRef}
              dir="ltr"
              contentEditable
              className="editable-div header-block w-100 mb-4"
              style={style}
              onKeyDown={handleKeyDown}
              onInput={handleInput}
            />

            {value && (
              <div className="d-flex align-items-center" style={{ position: "absolute", bottom: 0, right: 0, fontSize: 10 }}>
                <div style={{ padding: "2px 10px" }} className="op-primary-color text-light me-2">
                  {value.length} / 60 characters
                </div>
                <div className="op-primary-color text-light" style={{ padding: "2px 10px" }}>
                  {params ? 1 : 0} / 1 variables
                </div>
              </div>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default HeaderBlock;
