import { useMemo, useRef, useState } from "react";
import { Row, Col, Card, Form, Button, Container, Stack, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardListCheck, faUser, faMessage, faPhone } from "@fortawesome/pro-light-svg-icons";
import { AgGridReact } from "ag-grid-react";
import WSBackground from "../../assets/ws_background.png";
import { faArrowUpRightFromSquare, faBars, faPhoneArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { faImage as faImageSolid, faFile as faFileSolid, faVideo as faVideoSolid, faPaperPlaneTop } from "@fortawesome/pro-solid-svg-icons";
import { faImage, faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import axios from "../../api/axios";
import Swal from "sweetalert2/dist/sweetalert2";
import { useAuth } from "../../auth/AuthContext";
import { faFilePdf } from "@fortawesome/pro-duotone-svg-icons";
const PreviewTemplate = ({
  show,
  campaign,
  setCampaign,
  number,
  setNumber,
  curation,
  prospects,
  template,
  whatsapp,
  headerFiles,
  loadingSend,
  onPrevious = () => null,
  onSubmit = () => null,
}) => {
  const { session } = useAuth();
  const [loading, setLoading] = useState(false);

  // Set function ---------------------------------------------

  const setFormatMessages = (input) => {
    const regBold = /\*(.*?)\*/g;
    const regItalic = /_(.*?)_/g;
    const regStrikethrough = /~(.*?)~/g;
    const regCode = /```(.*?)```/g;
    // const regBreakLine = /\n/g;

    input = input.replace(/</g, "&lt;").replace(/>/g, "&gt;");

    let formattedText = input
      .replace(regBold, "<strong>$1</strong>")
      .replace(regItalic, "<em>$1</em>")
      .replace(regStrikethrough, "<s>$1</s>")
      .replace(regCode, "<code>$1</code>");
    return formattedText;
  };

  const setButtonLabel = (length, button, index) => {
    if (length > 3 && index > 1) {
      return (
        <>
          <FontAwesomeIcon icon={faBars} size="lg" className="me-2" color="#007bff" />{" "}
          <b style={{ color: "#007bff", fontSize: 13 }}>See all options</b>
        </>
      );
    }

    const buttonIcons = {
      phone_number: <FontAwesomeIcon icon={faPhoneArrowRight} size="lg" className="me-2" color="#007bff" />,
      url: <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="lg" className="me-2" color="#007bff" />,
      quick_reply: <b style={{ color: "#007bff", fontSize: 13 }}>{button.text}</b>,
    };

    return buttonIcons[button.type.toLowerCase()] ? (
      <div className="d-flex align-items-center">
        {buttonIcons[button.type.toLowerCase()]}{" "}
        {button.type.toLowerCase() !== "quick_reply" ? <b style={{ color: "#007bff", fontSize: 13 }}>{button.text}</b> : null}
      </div>
    ) : null;
  };

  const setButtonWidth = (values, index) => {
    if (values.buttons.length === 1) {
      return 12;
    } else if (values.buttons.length === 2) {
      return 12;
    } else if (values.buttons.length > 2) {
      return [0, 1].includes(index) ? 6 : 12;
    }
    return 12;
  };

  const setFileSize = (size) => {
    const fileSizeInKB = size / 1024;
    const fileSizeInMB = size / (1024 * 1024);
    return fileSizeInKB < 1024 ? `${fileSizeInKB.toFixed(2)} KB` : `${fileSizeInMB.toFixed(2)} MB`;
  };

  // send function --------------------------------------------

  const sendTestCampaign = async () => {
    if (number === "") {
      return Swal.fire({
        icon: "error",
        text: "Please provide a contact number to proceed with sending the test message.",
      });
    }

    if (loading) return;
    setLoading(true);

    let header_url = "";
    let header_type = "text";

    if (template.header === 1) {
      const { params_value, format } = template.header_record;
      header_url = params_value;
      header_type = format.toLowerCase();
    }

    let index_arr = [];
    let buttons = [];

    if (template.buttons === 1) {
      const button_data = template.buttons_record.buttons.filter((button) => button.type.toLowerCase() === "url");
      if (button_data.length > 0) {
        index_arr = button_data.map((button) => button.index);
        buttons = button_data.map((button) => button.params_value);
      }
    }

    let category = "text";
    if (template.header === 1 || template.footer === 1 || template.buttons === 1) {
      category = "media";
    }

    try {
      const response = await axios.get("ws/ws_whatsapp_campaign.php", {
        params: {
          task: "testWACampaignV2",
          chat_instance_id: whatsapp.chat_instance_id,
          whatsapp_template_name: template.label,
          whatsapp_template_category: category,
          whatsapp_template_params: template.body_record.params_value.join("|"),
          whatsapp_template_header_image: header_url,
          whatsapp_template_header_type: header_type,
          whatsapp_template_button_index: index_arr.join("|"),
          whatsapp_template_button_params: buttons.join("|"),
          contact_num: number,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        Swal.fire({
          icon: "success",
          text: "Test is send Successfully",
        });
      } else if (Number(data.status) === 401) {
        Swal.fire({
          icon: "error",
          text: data.error,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Please try again or contact your IT Support",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Styles ---------------------------------------------------

  const styles = useMemo(
    () => ({
      sidebar: {
        width: 350,
      },
      sidebar_header: {
        height: 50,
        borderBottom: "1px solid #ddd",
      },
      content: {
        backgroundColor: "#eeeeee",
        width: "calc(100% - 350px)",
      },
      form_control_icon: {
        backgroundColor: "#fff",
        paddingRight: 2,
        border: "none",
      },
      form_control: {
        borderLeft: "none",
        outline: "none",
        paddingRight: 0,
        boxShadow: "none",
        border: "none",
      },
      list_divider: {
        margin: "0 15px",
        borderTop: ".5px solid #eee",
      },
    }),
    []
  );

  const container_style = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const grid_style = useMemo(() => ({ height: "100%", width: "100%" }), []);

  // Grid -----------------------------------------------------
  const gridRef = useRef();
  const grid_column = [
    { headerName: "Name", field: "prospect_name", flex: 1, minWidth: 200 },
    { headerName: "Contact", field: "contact_num", flex: 1, minWidth: 200 },
    { headerName: "Email", field: "email_title", flex: 1, minWidth: 200 },
  ];

  const grid_col_def = useMemo(() => ({ sortable: false, filter: false }), []);

  if (!show) return null;

  return (
    <Container fluid className="d-flex p-0" style={{ height: "calc(100vh - 190px)" }}>
      <section className="border-end" style={styles.sidebar}>
        <div
          className="px-2 d-flex align-items-center justify-content-center mt-3"
          style={{ ...styles.sidebar_header, backgroundColor: "#f5f5f5", borderTop: "1px solid #ddd" }}
        >
          <div className="w-100" style={{ pointerEvents: "none" }}>
            <Form.Control placeholder="Campaign title" value="Campaign Title" style={{ ...styles.form_control, backgroundColor: "#f5f5f5" }} />
          </div>
        </div>

        <div className="px-2 d-flex align-items-center justify-content-center" style={styles.sidebar_header}>
          <div className="w-100">
            <Form.Control
              placeholder="State your campaign title"
              value={campaign}
              onChange={(e) => setCampaign(e.target.value)}
              style={styles.form_control}
            />
          </div>
        </div>

        <div
          className="px-2 d-flex align-items-center justify-content-center mt-3"
          style={{ ...styles.sidebar_header, backgroundColor: "#f5f5f5", borderTop: "1px solid #ddd" }}
        >
          <div className="w-100" style={{ pointerEvents: "none" }}>
            <Form.Control placeholder="Campaign title" value="Test this campaign" style={{ ...styles.form_control, backgroundColor: "#f5f5f5" }} />
          </div>
        </div>

        <div className="px-2 d-flex align-items-center justify-content-center" style={styles.sidebar_header}>
          <div className="w-100 d-flex align-items-center justify-content-center">
            <Form.Control
              placeholder="Contact number exp: 012556221"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              style={styles.form_control}
            />
            {number.length > 0 && (
              <Button variant="" disabled={loading} className="op-primary-color text-light" onClick={sendTestCampaign}>
                {loading ? (
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <FontAwesomeIcon icon={faPaperPlaneTop} />
                )}
              </Button>
            )}
          </div>
        </div>
      </section>

      <section className="h-100 p-3 overflow-y-auto" style={{ ...styles.content, height: "calc(100vh - 180px)" }}>
        <Row className="g-2">
          <Col sm={3}>
            <Card className="border mb-2 h-100">
              <Card.Body className="d-flex justify-content-between align-items-center p-3">
                <div>
                  <Form.Label className="fw-bold w-100">Curation Title</Form.Label>
                  <div className="text-muted">{curation.label}</div>
                </div>
                <FontAwesomeIcon icon={faClipboardListCheck} size="2x" />
              </Card.Body>
            </Card>
          </Col>

          <Col sm={3}>
            <Card className="border mb-2 h-100">
              <Card.Body className="d-flex justify-content-between align-items-center p-3">
                <div>
                  <Form.Label className="fw-bold w-100">Total Contact</Form.Label>
                  <div className="text-muted">{prospects.length} records</div>
                </div>
                <FontAwesomeIcon icon={faUser} size="2x" />
              </Card.Body>
            </Card>
          </Col>

          <Col sm={3}>
            <Card className="border mb-2 h-100">
              <Card.Body className="d-flex justify-content-between align-items-center p-3">
                <div>
                  <Form.Label className="fw-bold w-100">Whatsapp Template Name</Form.Label>
                  <div className="text-muted"> {template.label}</div>
                </div>
                <FontAwesomeIcon icon={faMessage} size="2x" />
              </Card.Body>
            </Card>
          </Col>

          <Col sm={3}>
            <Card className="border mb-2 h-100">
              <Card.Body className="d-flex justify-content-between align-items-center p-3">
                <div>
                  <Form.Label className="fw-bold w-100">Whatsapp Number</Form.Label>
                  <div className="text-muted"> {whatsapp.label}</div>
                </div>
                <FontAwesomeIcon icon={faPhone} size="2x" />
              </Card.Body>
            </Card>
          </Col>

          <Col sm={7}>
            <Card className="border mb-2 h-100">
              <Card.Body className="p-3">
                <div style={container_style}>
                  <div className={"ag-theme-quartz ag-op ag-op-secondary"} style={{ ...grid_style }}>
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={grid_column}
                      rowData={prospects}
                      defaultColDef={grid_col_def}
                      rowHeight={50}
                      pagination={false}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col sm={5}>
            <Card className="border mb-2 h-100">
              <Card.Body className="p-3">
                <div className="border w-100 px-4 py-4">
                  <div
                    className="rounded position-relative p-2"
                    style={{ background: `url(${WSBackground}) left top repeat rgb(236, 229, 221)`, minHeight: 400 }}
                  >
                    <div className="ws-bubble">
                      {template.header === 1 && (
                        <div className="w-100">
                          {["image", "document", "video"].includes(template.header_record.format.toLowerCase()) ? (
                            <div className="w-100">
                              {template.header_record.params_value ? (
                                <div className="w-100">
                                  {template.header_record.format.toLowerCase() === "image" ? (
                                    <img src={template.header_record.params_value} alt="header" className="w-100" />
                                  ) : template.header_record.format.toLowerCase() === "document" ? (
                                    <div className="my-2">
                                      <Card className="w-100" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                                        <Card.Body className="p-0">
                                          <div className="w-100" style={{ height: 150, overflowY: "clip" }}>
                                            <iframe
                                              className="w-100"
                                              style={{ height: 9999 }}
                                              src={template.header_record.params_value + "#toolbar=0&navpanes=0"}
                                            ></iframe>
                                          </div>
                                        </Card.Body>
                                        <Card.Header className="d-flex" style={{ backgroundColor: "#fff" }}>
                                          <div className="d-flex align-items-center justify-content-center me-2" style={{ width: 30 }}>
                                            <FontAwesomeIcon icon={faFilePdf} size="xl" />
                                          </div>
                                          <div>
                                            <div>{template.header_record.params_value.split("/").pop()}</div>
                                            <div className="text-muted" style={{ fontSize: 10 }}>
                                              {setFileSize(headerFiles[0].size)}, Chrome PDF Document
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <Card.Body className="d-flex">
                                          <div className="rounded border p-2 w-50 me-2 text-center" style={{ backgroundColor: "#f5f5f5" }}>
                                            Open
                                          </div>
                                          <div className="rounded border p-2 w-50 text-center" style={{ backgroundColor: "#f5f5f5" }}>
                                            Save as...
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>
                                  ) : template.header_record.format.toLowerCase() === "video" ? (
                                    <video width="100%" controls>
                                      <source src={template.header_record.params_value} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                  ) : null}
                                </div>
                              ) : (
                                <div className="ws-bubble-header">
                                  <FontAwesomeIcon
                                    icon={
                                      template.header_record.format.toLowerCase() === "image"
                                        ? faImageSolid
                                        : template.header_record.format.toLowerCase() === "video"
                                        ? faVideoSolid
                                        : faFileSolid
                                    }
                                    color="#fff"
                                    size="10x"
                                  />
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>
                      )}

                      <p
                        className="ws-bubble-message"
                        style={{ marginBottom: 0, fontSize: 12 }}
                        dangerouslySetInnerHTML={{ __html: setFormatMessages(template.body_record.text_preview) }}
                      />

                      {template.footer === 1 && (
                        <p
                          className="ws-bubble-message"
                          style={{ color: "rgba(153,153,153,1)", fontSize: 11, marginBottom: 0 }}
                          dangerouslySetInnerHTML={{ __html: setFormatMessages(template.footer_record.text) }}
                        />
                      )}
                      <div className="ws-bubble-time">{moment().format("HH:mm A")}</div>
                    </div>
                    {template.buttons === 1 && (
                      <Row className="g-2" style={{ marginLeft: 10, marginRight: 10, marginTop: 2 }}>
                        {template.buttons_record.buttons.map((button, index) => {
                          if (index > 2) return null;
                          return (
                            <Col key={index} sm={setButtonWidth(template.buttons_record, index)}>
                              <div
                                className="h-100 w-100 p-2 bg-light shadow-sm rounded cursor-pointer d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  if (button.type.toLowerCase() === "url") {
                                    window.open(button.url_preview, "_blank");
                                  }
                                }}
                              >
                                {setButtonLabel(template.buttons_record.buttons.length, button, index)}
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className="offcanvas-footer" style={{ height: 60, zIndex: 100, left: 350, width: "calc(100% - 350px)" }}>
          <Stack direction="horizontal" gap={2}>
            <button className="btn btn-dark" onClick={onPrevious}>
              Previous
            </button>
            <button disabled={loadingSend} className="btn op-button op-primary-color text-light ms-auto" onClick={onSubmit}>
              {loadingSend ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Start Campaign"
              )}
            </button>
          </Stack>
        </div>
      </section>
    </Container>
  );
};

export default PreviewTemplate;
